import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FacebookService, InitParams, LoginResponse, LoginOptions  } from 'ngx-facebook';
import { Register } from '../../../../objects/register';
import { RequestProcess } from '../../../../objects/request';
import { ProcesswebService } from '../../../../services/processweb.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Consult } from '../../../../objects/consult';

@Component({
  selector: 'app-mi-cuenta',
  templateUrl: './mi-cuenta.component.html',
  styleUrls: ['./mi-cuenta.component.css']
})
export class MiCuentaComponent implements OnInit {

  constructor(private fb: FacebookService, private accessService: ProcesswebService, public ngxSmartModalService: NgxSmartModalService, public fmb : FormBuilder) { }

  InfoBaseForm:  FormGroup;
  dataUser: any;
  public nombre: string = '';
  public apellido: string = '';
  public documento: string = '';
  public telefono: string = '';
  public email: string = '';
  public direccion: string = '';
  idUsuario: any;
  id: any;
  message?: string = '';
  

  ngOnInit() {
    if(sessionStorage.getItem('User') !== null){
      this.dataUser = JSON.parse(sessionStorage.getItem('User'))
      this.idUsuario= this.dataUser.data.idusuario
    /*   this.nombre = this.dataUser.data.nombre;
      this.apellido = this.dataUser.data.apellido;
      this.documento = this.dataUser.data.documento;
      this.telefono = this.dataUser.data.telefono;
      this.email = this.dataUser.data.correo; */
      this.id = this.dataUser.data.idpersona;   
      this.consultData(this.id);
      this.editFormulario();
    }

   /*  if (this.fb.getLoginStatus()){
      this.getProfile()
    }  */  
  }


  get nombreNoValido() {
    return this.InfoBaseForm.get('name').invalid && this.InfoBaseForm.get('name').touched;
  }
  get apellidoNoValido() {
      return this.InfoBaseForm.get('lastName').invalid && this.InfoBaseForm.get('lastName').touched;
  }
  get documentoNoValido() {
      return this.InfoBaseForm.get('document').invalid && this.InfoBaseForm.get('document').touched;
  }
  get telefonoNoValido() {
      return this.InfoBaseForm.get('phone').invalid && this.InfoBaseForm.get('phone').touched;
  }
  get correoNoValido() {
      return this.InfoBaseForm.get('email').invalid && this.InfoBaseForm.get('email').touched;
  }
  get direccionNoValido() {
    return this.InfoBaseForm.get('direccion').invalid && this.InfoBaseForm.get('direccion').touched;
  }

  editFormulario(){
    this.InfoBaseForm =  this.fmb.group({
        name:['', [Validators.required]],
        lastName:['', [Validators.required]],
        document: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(20)]],             
        email: ['', [Validators.required, Validators.email, Validators.maxLength(45)]],
        phone: ['', [Validators.required]],
        direccion:['', [Validators.required, Validators.minLength(7) ]],  
        acceptTerms: [false, [Validators.required]],  // this.fb.array([])//     
        acceptData: [false, [Validators.required]]  // this.fb.array([])//     
    })
  }


  getProfile() {
    this.fb.api('/me')
      .then((res: any) => {
      })
      .catch();
  }

  consultUserData(){

  }
  
  updateUserData(){
    if (this.InfoBaseForm.valid ) {
      let consulta: Register = 
      {
        idusuario: this.idUsuario,
        ip: null,
        id: this.id,
        nombre: this.nombre,
        apellido: this.apellido,
        documento: this.documento,
        correo: this.email,
        telefono: +this.telefono,
        estado: 1,
        idestadousuario: 1,
        direccion: this.direccion,
        semilla: null,
      }

      let peticion = new RequestProcess()
        peticion.proceso = '15';
        peticion.consulta = JSON.stringify(consulta)
        this.accessService.execProcess(peticion)
        .subscribe( res => {
              if (res.mensaje  === 'OK'  ) {
              /*  let data = JSON.parse(res.dataresponse) */
              this.consultData(this.id);
                //this.message = res.mensaje
                this.message = 'Datos actualizados con éxito';
                /* sessionStorage.getItem('User'); */
                /* console.log(res.mensaje); */
              }
              else if (res.mensaje  !== 'Consulta sin resultados') {
                console.log('error, ', res.mensaje )
                this.message = res.mensaje;
              } 
        })
  }else{
    this.message = 'Por favor, asegúrese de completar todos los campos del formulario y verificar que cada uno sea válido antes de continuar.';
    this.ngxSmartModalService.getModal('myModal').open();
  }
}

consultData(idp){
  const consulta: Consult = {
    schema: 'admin',
    tabla: 'personas',
    campo: 'nombre, apellido, idpersona, documento, telefono, correo, direccion',
    condicion: 'idpersona=' + idp
  };
  const peticionUser = new RequestProcess();
  peticionUser.proceso = '1';
  peticionUser.consulta = JSON.stringify(consulta);
  this.accessService.execProcess(peticionUser)
      .subscribe( (res: any) => {
       let resp = JSON.parse(res.dataresponse); 
       sessionStorage.setItem('User.data.nombre', resp[0].nombre);
        /* console.log(resp[0].apellido); */
        this.nombre = resp[0].nombre;
        this.apellido = resp[0].apellido;
        this.documento = resp[0].documento;
        this.telefono = resp[0].telefono;
        this.email = resp[0].correo;
        this.direccion = resp[0].direccion;
 
  });
}
}
