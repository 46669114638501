import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterComponent } from './register.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { HeaderModule } from '../../header/header.module';
import { FormsModule } from '@angular/forms';
import { NgxSmartModalModule,  NgxSmartModalService } from 'ngx-smart-modal';
import {  ReactiveFormsModule } from '@angular/forms';
import { PoliticaComponent } from './politca-datos/politica.component';
import { RouterModule } from '@angular/router'; 
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [RegisterComponent, PoliticaComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    HeaderModule,
    FormsModule,
    NgxSmartModalModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    RouterModule,
    NgxMaskModule.forRoot()
  ],
  providers: [ NgxSmartModalService ],/* 
  exports: [NgxSmartModalModule], */
})
export class RegisterModule { }
