import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
    selector: 'app-no-product',
    templateUrl: './noproduct.component.html',
    styleUrls: ['./noproduct.component.css']
  })

  
export class NoproductComponent   {
  
    // tslint:disable-next-line:variable-name
    constructor(
      private location: Location,
      private router: Router) { }

      goBack() {
       // this.location.back();
       this.router.navigate(['/home']);
      }
    
}