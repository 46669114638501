import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PayComponent } from './pay.component';
import { SharedModule } from '../shared/shared.module';
import { NgxSmartModalModule,  NgxSmartModalService } from 'ngx-smart-modal';

@NgModule({
  declarations:
  [
    PayComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgxSmartModalModule
  ],

  exports: [
    PayComponent,
  ],

})
export class PayModule { }
