import { Component, OnInit, ViewChild,  Input, Output, EventEmitter} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
/* import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter'; */
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
/* import {MatDatepickerInputEvent} from '@angular/material/datepicker'; */
import { MatDatepicker} from '@angular/material';


@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.css']
})


export class PaymentHistoryComponent implements OnInit {

  @ViewChild(MatDatepicker) datepicker: MatDatepicker<Date>;
  dateCtrl: FormControl;

  @Input() id: string;
  @Input() maxSize: number;
  @Output() pageChange: EventEmitter<number>;
  @Output() pageBoundsCorrection: EventEmitter<number>;
  
  
  constructor() { }
  
  public picker: any;
  public pickerFinal: any;
  dateIni: Date;
  dateFin: Date;
  
  sizeProductos: number = 0;
  page: number = 1;
  itemsPerPage = 10;
  currentPage = 1;
  p: number = 1;
  
  
  ngOnInit() {
  }

  datePayment(){
  }
}
