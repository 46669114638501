import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
import {Router} from '@angular/router';
import { Consult } from '../../objects/consult';
import { RequestProcess } from '../../objects/request';
import { ProcesswebService } from '../../services/processweb.service';


@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {

  constructor(private _route: ActivatedRoute, private router: Router, private accessService: ProcesswebService ) {
    router.events.subscribe((val) => {
      (val instanceof NavigationEnd) && this.processInitView();
    });
  }

  public nombreProducto:string = "";
  _dataProdut: any;
  claseTitular: string = "footer-business";

  ngOnInit() {}

  processInitView() {
    window.scrollTo(0,0);
    this.nombreProducto = this._route.snapshot.paramMap.get('productonombre') //.toUpperCase()
    this.listarGrupos();
    this._dataProdut = [{contenido: ''}]
  }

  navigate(){
    this.router.navigate(['#/questions/this.nombreProducto']);
  }

  listarGrupos(){
    try {
       let consulta: Consult =
      {
        schema: 'producto',
        tabla: 'productos_v',
        campo: 'nombre, descripcion, idproducto, duracion',
        condicion: 'nombre= \'\'' + this.nombreProducto + '\'\' '
      }

      let peticion = new RequestProcess()
      peticion.proceso = '1'
      peticion.consulta = JSON.stringify(consulta)
      this.accessService.execProcess(peticion)
        .subscribe((res: any) => {
          if (res.codigo === '00') {
            let dataProduct = JSON.parse(res.dataresponse)
            this._dataProdut = dataProduct;
          }
          else {
          }
        });
  }catch(error){
    console.log(error)
  }

}

}
