import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeTempComponent } from './home-temp.component';
import { HeaderModule } from '../header/header.module';
import { MatCardModule } from '@angular/material/card';
import { SlideshowModule } from 'ng-simple-slideshow';
import { FooterModule } from '../footer/footer.module';
import { NgFallimgModule } from  'ng-fallimg';
import { NgxSmartModalModule,  NgxSmartModalService } from 'ngx-smart-modal';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [HomeTempComponent],
  imports: [
    CommonModule,
    HeaderModule,
    MatCardModule,
    SlideshowModule,
    FooterModule,
    NgFallimgModule,
    NgxSmartModalModule,
    FormsModule
  ]
})
export class HomeTempModule { }
