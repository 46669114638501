import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {
  MatMenuModule,
  MatIconModule,
  MatTabsModule,
  MatSidenavModule,
  MatToolbarModule,
  MatButtonModule,
  MatInputModule,
  MatFormFieldModule
} from '@angular/material';
/* import { BrowserModule } from '@angular/platform-browser'; */
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MatTabsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    FormsModule,
    AutocompleteLibModule,
    ReactiveFormsModule,
    MatAutocompleteModule ,
    MatInputModule,
    MatFormFieldModule,
    RouterModule
  ],
  exports: [HeaderComponent, ]
})
export class HeaderModule { }
