import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoriesComponent } from './categories.component';
import { HeaderModule } from '../header/header.module';
import { MatCardModule } from '@angular/material/card';
import { FooterModule } from '../footer/footer.module';
import { NgxSmartModalModule,  NgxSmartModalService } from 'ngx-smart-modal';

/* ng-starrting para el rankingx */
import { RatingModule } from 'ng-starrating';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [CategoriesComponent],
  imports: [
    CommonModule,
    HeaderModule,
    MatCardModule,
    FooterModule,
    RatingModule,
    NgxSmartModalModule,
    SharedModule,
    RouterModule
  ],
  providers: [ NgxSmartModalService ],
})
export class CategoriesModule { }
