import { Component, OnInit } from '@angular/core';
import { FacebookService, InitParams, LoginResponse, LoginOptions  } from 'ngx-facebook';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css']
})
export class PerfilComponent implements OnInit {

  title: string = "Mi Cuenta";
  dataUser: any;
  cuenta: boolean=  true;
  pass: boolean = false
  historial: boolean = false
  pqr: boolean = false;
  documents: boolean = false;
  tips= false;
  userOption = '';
  opcion: string;
  rutas = ["Cuenta","Perfil","Configuracion", "Historial", "PQRS", "Documents", "tips" ]

  constructor(private _route: ActivatedRoute, private router: Router, private auth: AuthService) { }

  ngOnInit() {
    this.userOption = this._route.snapshot.paramMap.get('group');

    if(sessionStorage.getItem('User') !== null){
      this.dataUser = JSON.parse(sessionStorage.getItem('User'))
      if (this.dataUser.data.idestadousuario === 2){
        this.opcion = 'Configuracion';
      }

    }

     this.auth._opcionMenu$()
      .subscribe( option => {
        this.opcion = (option == '' ) ? this._route.snapshot.params.useroption : option;
        let result = this.rutas.find(elem => elem == this.opcion);
        
        if(result ==  undefined) {
          this.router.navigate(['/404']);
        }
      }) 

  }

}

