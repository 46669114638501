import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestProcess } from '../objects/request';
import { ProcesswebService } from './processweb.service';

@Injectable({
  providedIn: 'root'
})
export class SmsService {

  private token = '';
  constructor(private http: HttpClient, private accessService: ProcesswebService,) { }


  guardarTokenBD() {
    const data: any = {
      idusuario: '10001',
      ip: '192.168.1.2',
      idparametro: 3,
      token: ''
    };
    let peticion = new RequestProcess();
    peticion.proceso = '23';
    peticion.consulta = JSON.stringify(data);
    this.accessService.execProcess(peticion).subscribe((res: any) => {
      if (res.codigo == '00') {
        const dataresponse = JSON.parse(res.dataresponse);
        if (dataresponse.valida == '1') {
          const headers = new HttpHeaders({
            'Content-Type': 'application/json'
          });
          return this.accessService.tokenSMS('token').subscribe(
            (response: any) => {
              this.token = '';
              this.token = response.token;
              const data_dos: any = {
                idusuario: '10001',
                ip: '192.168.1.2',
                idparametro: 3,
                token: this.token
              };
              let peticion_dos = new RequestProcess();
              peticion_dos.proceso = '23';
              peticion_dos.consulta = JSON.stringify(data_dos);
              this.accessService.execProcess(peticion_dos).subscribe((res_dos: any) => {
                if (res_dos.codigo == '00') {
                  const dataresponse_aux = JSON.parse(res_dos.dataresponse);
                }
              });
            },
            error => {
              console.error("Error al generar token:", error);
            }
          );
        } else {
          this.setToken(dataresponse.token);
        }
      }
    }
    )
  }
  sendSmsCelular(phone: string, otp: string) {
    const sms: any = {
      phone: phone,
      otp: otp,
      token: this.token
    }
    let peticion = new RequestProcess()
    peticion.proceso = '1'
    peticion.consulta = JSON.stringify(sms)

    this.accessService.sendSms(sms, 'sendSms')
      .subscribe(res => {
      });



  }
  setToken(token: string): void {
    this.token = token;
  }
  getToken(): string {
    return this.token;
  }

}
