import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { RequestProcess } from '../objects/request';
import { ProcesswebService } from './processweb.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { data } from 'jquery';
import { TreeService } from './tree.service';
import { element } from 'protractor';
import { Consult } from '../objects/consult';

@Injectable({
  providedIn: 'root'
})
export class NequiService {

  token: string = '';
  private attributeSource = new BehaviorSubject<string>(this.token);
  currentAttribute = this.attributeSource.asObservable();
  private tokenSubscription: Subscription | undefined;
  fecha = new Date();
  fechaISO = this.fecha.toISOString();
  private intervalId: any;
  message = '';
  transaction_id = '';
  nombre_product = '';
  valor_product = '';
  intentos = 0;


 

  public buttonSiguiente$ = new BehaviorSubject<boolean>(false);
  public buttonVerifyPay$ = new BehaviorSubject<boolean>(false);
  public panelPagoExitoso$ = new BehaviorSubject<boolean>(false);
  public panelPagoFallido$ = new BehaviorSubject<boolean>(false);
  public closePanelPago$ = new BehaviorSubject<boolean>(false);
  private intentos$ = new BehaviorSubject<number>(0);
  public intentosObs$ = () => this.intentos$.asObservable();



  public botonVerifyPay$ = () => this.buttonVerifyPay$.asObservable();
  public pagoExitoso$ = () => this.panelPagoExitoso$.asObservable();
  public pagoFallido$ = () => this.panelPagoFallido$.asObservable();
  public closePanelPagoOBS$ = () => this.closePanelPago$.asObservable();

  public errorMessage$ = new BehaviorSubject<string>('');
  public ErrorMessageObserva$ = () => this.errorMessage$.asObservable();

  public phone$ = new BehaviorSubject<string>('');
  public numPhone$ = () => this.phone$.asObservable();

  public idTransaction$ = new BehaviorSubject<string>('');
  public idTransactionObserva$ = () => this.idTransaction$.asObservable();

  private dateTransaction$ = new BehaviorSubject<string>('');
  public dateTransactionObserva$ = () => this.dateTransaction$.asObservable();




private ErrorMessageVerific$ = new BehaviorSubject<string>('');
  public ErrorMessageVerificObserva$ = () => this.ErrorMessageVerific$.asObservable();

  constructor(private http: HttpClient, private accessService: ProcesswebService, public ngxSmartModalService: NgxSmartModalService, public treeService: TreeService) {
    this.guardarTokenBD();
    this.subscription();
  }


  subscription() {
    this.treeService.nameProd$().subscribe(elem => {
      this.nombre_product = elem;
    })
    this.treeService.valorPago$().subscribe(elem => {
      this.valor_product = elem;
    })
  }
  public getIpAddress() {
    return this.http.get("http://api.ipify.org/?format=json");

  }
  guardarTokenBD() {
    const data: any = {
      idusuario: '10001',
      ip: '192.168.1.2',
      idparametro: 2,
      token: ''
    };
    let peticion = new RequestProcess();
    peticion.proceso = '23';
    peticion.consulta = JSON.stringify(data);
    this.accessService.execProcess(peticion).subscribe((res: any) => {
      if (res.codigo == '00') {
        const dataresponse = JSON.parse(res.dataresponse);
        if (dataresponse.valida == '1') {
          const headers = new HttpHeaders({
            'Content-Type': 'application/json'
          });
          return this.accessService.tokenNequi('token').subscribe(
            (response: any) => {
              this.token = '';
              this.token = response.token;
              const data_dos: any = {
                idusuario: '10001',
                ip: '192.168.1.2',
                idparametro: 2,
                token: this.token
              };
              let peticion_dos = new RequestProcess();
              peticion_dos.proceso = '23';
              peticion_dos.consulta = JSON.stringify(data_dos);
              this.accessService.execProcess(peticion_dos).subscribe((res_dos: any) => {
                if (res_dos.codigo == '00') {
                  const dataresponse_aux = JSON.parse(res_dos.dataresponse);
                }
              });
            },
            error => {
              console.error("Error al generar token:", error);
            }
          );
        } else {
          this.setToken(dataresponse.token);
        }
      }
    }
    )
  }



  sendNotificationPush(phone: string, value: number) {
    const nequi: any =
    {
      phone: phone,
      value: value,
      token: this.token
    }
    let peticion = new RequestProcess()
    peticion.proceso = '1'
    peticion.consulta = JSON.stringify(nequi)
    this.accessService.sendNotifiPush(nequi, 'sendPayment')
      .subscribe(res => { 
        this.transaction_id = res.ResponseMessage.ResponseBody.any.unregisteredPaymentRS.transactionId;
        this.idTransaction$.next(this.transaction_id);
        this.buttonVerifyPay$.next(true);
        this.saveRespNequi(res);
      }, error => {
        if (error.status === 404) {
          const statusDesc = error.error.ResponseMessage.ResponseHeader.Status.StatusDesc;
          this.errorMessage$.next(statusDesc);
          return statusDesc;
        } else {
        }
      });
  }
  verifyPayment() {
    this.idTransactionObserva$().subscribe(res=>{
      this.transaction_id = res;
    });
    this.subscription();
    const nequi: any =
    {
      transactionId: this.transaction_id,
      token: this.token
    }
    let peticion = new RequestProcess()
    peticion.proceso = '1'
    peticion.consulta = JSON.stringify(nequi)
    this.accessService.sendNotifiPush(nequi, 'verifyPayment')
      .subscribe(res => {
        const statusPay = res.ResponseMessage.ResponseBody.any.getStatusPaymentRS.status;
        this.saveRespNequi(res);
        if (statusPay === '35') {
          this.dateTransaction$.next(res.ResponseMessage.ResponseBody.any.getStatusPaymentRS.date);
          this.panelPagoExitoso$.next(true);
          this.panelPagoFallido$.next(false);
          this.treeService.btnStatusPay$.next(true);
          this.closePanelPago$.next(true);
          
        } else {
          this.ErrorMessageVerific$.next('Transacción Pendiente');
          this.dateTransaction$.next(res.ResponseMessage.ResponseBody.any.getStatusPaymentRS.date)
          this.panelPagoExitoso$.next(false);
          this.panelPagoFallido$.next(true);
          this.treeService.btnStatusPay$.next(false);
          const consultaContenido: Consult = {
            schema: 'transaccional',
            tabla: 'intentos_v',
            campo: 'transactionid, cantidad',
            condicion: 'transactionid  = \'\'' + this.transaction_id + '\'\''
          };
          const peticionContenido = new RequestProcess();
          peticionContenido.proceso = '1';
          peticionContenido.consulta = JSON.stringify(consultaContenido);
          this.accessService.execProcess(peticionContenido)
            .subscribe((resp: any) => {
              const data = JSON.parse(resp.dataresponse);
              if (resp.codigo === '00') {
                this.intentos$.next(data[0].cantidad);
              }
            }
            )
        }
      }, error => {
        if (error.status === 404) {
          console.error('Error 404: Recurso no encontrado');
          const statusDesc = error.error.ResponseMessage.ResponseHeader.Status.StatusDesc;
          this.ErrorMessageVerific$.next(statusDesc);
          this.dateTransaction$.next(error.error.ResponseMessage.ResponseHeader.ResponseDate);
          this.panelPagoExitoso$.next(false);
          this.panelPagoFallido$.next(true);
          this.treeService.btnStatusPay$.next(false);
        } else {
          console.error('Otro error ocurrió');
        }
      });

  }



  saveRespNequi(res: any) {
    const usuario = JSON.parse((sessionStorage.getItem('User')));
    let idestadotrasaccion = 100;
    if (
      res &&
      res.ResponseMessage &&
      res.ResponseMessage.ResponseBody &&
      res.ResponseMessage.ResponseBody.any &&
      res.ResponseMessage.ResponseBody.any.getStatusPaymentRS &&
      res.ResponseMessage.ResponseBody.any.getStatusPaymentRS.status !== undefined
    ) {
      idestadotrasaccion = res.ResponseMessage.ResponseBody.any.getStatusPaymentRS.status;
    }
    const data: any = {};
    data.idusuario = usuario.data.idusuario
    data.ip = '192.168.1.2'
    data.idproducto = this.treeService.getIdProd
    data.idseccion = this.treeService.getIdSection
    data.idnodo = this.treeService.getIdNodo
    data.idestadotrasaccion = idestadotrasaccion
    data.idtipotransaccion = 1
    data.celular = this.phone$.value
    data.valor = this.valor_product
    data.nombreproducto = this.nombre_product
    data.codigoestado = res.ResponseMessage.ResponseHeader.Status.StatusCode
    data.descripcionestado = res.ResponseMessage.ResponseHeader.Status.StatusDesc
    data.idmensaje = res.ResponseMessage.ResponseHeader.MessageID
    data.responsedate = res.ResponseMessage.ResponseHeader.ResponseDate
    if (idestadotrasaccion === 100) {
      data.transactionid = res.ResponseMessage.ResponseBody.any.unregisteredPaymentRS.transactionId
    } else {
      data.transactionid = this.transaction_id

    }
    let peticion = new RequestProcess();
    peticion.proceso = '25';
    peticion.consulta = JSON.stringify(data);
    this.accessService.execProcess(peticion).subscribe((res: any) => {
      if (res.codigo == '00') {
      }
    });
  }
  setToken(token: string): void {
    this.token = token;
  }
  getToken(): string {
    return this.token;
  }
}

