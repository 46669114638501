import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterModule } from '../footer/footer.module';
import { SharedModule } from '../shared/shared.module';

import { Page404Component } from './page404.component';


@NgModule({
    declarations: [Page404Component],
    imports: [
        CommonModule,
        FooterModule,
        SharedModule,
      ]
})
export class Page404Module {}