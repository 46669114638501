import { ChangeDetectionStrategy, Component, OnInit, Input, Output,  EventEmitter } from '@angular/core';
import { FacebookService, InitParams, LoginResponse, LoginOptions  } from 'ngx-facebook';
import { Router } from '@angular/router';
import { RequestProcess } from '../../../../objects/request';
import { Consult } from '../../../../objects/consult';


import { ProcesswebService } from '../../../../services/processweb.service';
import { TreeService } from '../../../../services/tree.service';


@Component({
  selector: 'app-documents-user',
  templateUrl: './documentsUser.component.html',
  styleUrls: ['./documentsUser.component.css']
})

export class DocumentsUserComponent implements OnInit {

  @Input() id: string;
  @Input() maxSize: number;
  @Output() pageChange: EventEmitter<number>;
  @Output() pageBoundsCorrection: EventEmitter<number>;


  constructor(private accessService: ProcesswebService, private router: Router, private treeService: TreeService) {
  }

  //listaProductos: any;
  dataUser: any;
  idUsuario: any;
  dataSource: any;
  //pageEvent: PageEvent;
  displayedColumns: string[] = ['productName', 'productCategorie', 'estate'];
  lowValue = 0;
  highValue = 10;
  public array: any;
/*   public pageSize = 10;
  public currentPage = 1;
  public totalSize = 0; */

  documentUser= false;
  listaProductos: string[] = [];
  sizeProductos: number = 0;
  page: number = 1;
  itemsPerPage = 10;
  currentPage = 1;
  p: number = 1;
  actions= -1;
  filteredSearch: any;
  busqueda= false;
  showOptionDownload = false;

  ngOnInit(){

    if (sessionStorage.getItem('User') !== null) {
      this.dataUser = JSON.parse(sessionStorage.getItem('User'));
      this.idUsuario = this.dataUser.data.idusuario;
      this.listarDocs();
    }
    else {
      this.router.navigate(['home'])
    }

  }


  absoluteIndex(indexOnPage: number): number {
    return this.itemsPerPage * (this.currentPage - 1) + indexOnPage;
  }


  listarDocs() {
    try {
      this.listaProductos = new Array<any>();
      const consulta: Consult = {
        schema: 'producto',
        tabla: 'reportproductosusuarios as reportdocs right join arbol.usuarioproductos as userproduct on reportdocs.codigomongo = userproduct.codigomongo',
        campo: 'distinct reportdocs.idusuario, reportdocs.categoria, reportdocs.grupo, reportdocs.idproducto, reportdocs.producto, userproduct.nombre_producto,reportdocs.codigomongo, reportdocs.estado, reportdocs.idcapitulo ',
        condicion: 'userproduct.idusuario = '+ this.idUsuario  + ' order by userproduct.nombre_producto'
        // schema: 'producto',
        // tabla: 'reportproductosusuarios',
        // campo: 'idusuario, producto, estado, codigomongo, idcapitulo', //'idusuario, producto, categoria, estado, idnodoactual, codigomongo, idcapitulo',
        // condicion: 'idusuario = ' + this.idUsuario + 'order by producto'
      };
      const peticion = new RequestProcess();
      peticion.proceso = '1';
      peticion.consulta = JSON.stringify(consulta);
      console.log(peticion.consulta)
      this.accessService.execProcess(peticion)
        .subscribe((res: any) => {
          if (res.codigo === '00') {
            const listproduct = JSON.parse(res.dataresponse);
            this.listaProductos = listproduct;
            this.dataSource =  this.listaProductos;
            this.sizeProductos = this.listaProductos.length;

            /* for(let i=0; i> this.sizeProductos; i++){
            } */


              setTimeout (() => {
                this.documentUser = true;
              }, 300);

            //this.dataSource = new MatTableDataSource<Products>(this.listaProductos);
            //this.dataSource.paginator = this.paginator;

            //this.totalSize = this.listaProductos.length;
            //this.iterator();

          } else {
            setTimeout (() => {
              this.documentUser = false;
            }, 100);
          }
        });
    } catch (error) {

    }
  }


  download() {
    this.showOptionDownload = !this.showOptionDownload;
  }

  downloadPDF(estado, codigomongo) {
    const accion = (estado === 'TERMINADO') ? 'Crear' : 'VistaPrevia';
    this.treeService.setCodigoMongo(codigomongo);
    this.treeService.setPDFMongo(accion, 'pdf');
    this.hideSubmenu();
  }

  downloadDocx(estado, codigomongo) {
    const accion = (estado === 'TERMINADO') ? 'Crear' : 'VistaPrevia';
    this.treeService.setCodigoMongo(codigomongo);
    this.treeService.setPDFMongo(accion, 'docx');
    this.hideSubmenu();
  }

  editProduct(producto) {
    this.treeService.getIdProducto(producto).then(() => {
      this.router.navigate(['/questions/' + producto]).then(() => {
        this.treeService.consultWalk(JSON.parse(sessionStorage.getItem('User')).data.idusuario);
      });
    });
  }

  showOptions(ind) {
    this.actions = (ind == this.actions ) ? -1 : ind ;
  }

  hideSubmenu() {
    this.showOptionDownload = false;
    this.actions = -1;
  }

  closeOptionMenu(event: any) {
    !this.showOptionDownload &&  this.hideSubmenu();
  }

  searchDocument(event: any){

//    console.log(event.target.value.length)
    if(event.target.value.length > 2){
      this.busqueda = true;
      
      this.filteredSearch = this.dataSource.filter( option => {
        if(option.nombre_producto != null){
          return option.nombre_producto.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1
        }
      
      } );
    } else {
      this.busqueda = false;
    }

    
  
   /*  if (!$('.cont-result-search').is(":visible")) {
      $('.cont-result-search').slideToggle('slow');
    }
    if( event.target.value == "") {
      $('.cont-result-search').attr("style", "display:none");
    } */

/*     filterAutocomplete = function (str) {
      return $scope.autocompleteAddressFil.filter(function(el) {
          return el.toLowerCase().indexOf(str.toLowerCase()) > -1;
      })
} */

    
  }

  closeSearch(){
    /* if ($('.cont-result-search').is(":visible"))
        $('.cont-result-search').slideToggle('slow');
    } */
    this.busqueda = false;
  }

}


export interface Products {
  name: string;
  description: string;
  estate: string;
  actions: any;
}
