import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgFallimgModule } from  'ng-fallimg';

import { SharedModule } from '../shared/shared.module';
import { FeedbackComponent } from './feedback.component';

/* ng-starrting para el rankingx */
import { RatingModule } from 'ng-starrating';



@NgModule({
  declarations: [FeedbackComponent],
  imports: [
    CommonModule,
    NgFallimgModule,
    RatingModule,
    SharedModule
  ],
  exports: [
    FeedbackComponent
  ],
})
export class FeedbacksModule { }
