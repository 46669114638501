import { Component, OnInit } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormArray, FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';


@Component({
  selector: 'app-politica',
  templateUrl: './politica.component.html',
})

export class PoliticaComponent implements OnInit {

  constructor(
      private router: Router)
      {
      }


  ngOnInit() {

  }



}
