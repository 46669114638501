import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
    selector: 'app-404',
    templateUrl: './page404.component.html',
    styleUrls: ['./page404.component.css']
  })

  
export class Page404Component   {
  
    // tslint:disable-next-line:variable-name
    constructor(
      private location: Location,
      private router: Router) { }

      goBack() {
       // this.location.back();
       this.router.navigate(['/home']);
      }
    
}