import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
/* import { RequestProcess } from '../objects/request';
import { ProcesswebService } from './processweb.service';*/

@Injectable({
    providedIn: 'root'
  })

  export class HeaderStyleService{
      
    public headerStyle: number = 0;
    nombreCategoria : string ="";
    nombreGrupo : string ="";
    nombreProducto : string ="";
    header:number = 0;

    styleHeader: number = 0;

    constructor( private _route: ActivatedRoute){}
  
    setStyle(){
        this.nombreCategoria = this._route.snapshot.paramMap.get('category').toUpperCase();
        this.nombreGrupo = this._route.snapshot.paramMap.get('group').toUpperCase();
        this.nombreProducto = this._route.snapshot.paramMap.get('product').toUpperCase();   
    }

    consultCategoryGroup(){

    }

}

