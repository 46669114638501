import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FacebookService } from 'ngx-facebook';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Consult } from 'src/app/objects/consult';
import { Register_aux } from 'src/app/objects/register_aux';
import { RequestProcess } from 'src/app/objects/request';
import { NequiService } from 'src/app/services/nequi.service';
import { ProcesswebService } from 'src/app/services/processweb.service';
import { SmsService } from 'src/app/services/sms.service';


@Component({
  selector: 'app-actualizacion',
  templateUrl: './actualizacion.component.html',
  styleUrls: ['./actualizacion.component.css'],
})

export class ActualizacionComponent implements OnInit {
  otpForm: FormGroup;
  formUpdate: FormGroup;
  public name: string = '';
  public lastName: string = '';
  public document: string = '';
  public phone: string = '';
  public email: string = '';
  public direccion: string = '';
  idUsuario: any;
  id: any;
  message?: string = '';
  dataUser: any;
  listaTipoDoc: any[] = [];
  public tipoDocumento: number = 0;
  documentMask: string = '';
  isOtpValid: boolean = true;
  mensajeSuccess: string = '';
  tiempoRestante: number = 0; // 30 segundos
  intervalo: any;
  botonDeshabilitado: boolean = false;
  my_otp?: string = '';
  otpSend?: string = '';
  sizeMax: string = '';
  sizeMin: string ='';
  expresionReg:RegExp;






  constructor(public nequi: NequiService, private fb: FacebookService, private accessService: ProcesswebService, public ngxSmartModalService: NgxSmartModalService, public fmb: FormBuilder, private router: Router,private sms:SmsService) { 
    
  }

  ngOnInit() {
    this.sms.guardarTokenBD();
    this.OTPFormulario();
    this.consultTipoDoc();
    this.editFormulario();
    if (sessionStorage.getItem('User') !== null) {

      this.dataUser = JSON.parse(sessionStorage.getItem('User'))
      this.idUsuario = this.dataUser.data.idusuario
      this.id = this.dataUser.data.idpersona;
      this.consultData(this.id);
     
    }
  }

  OTPFormulario() {
    this.otpForm = this.fmb.group({ my_otp: ['', [Validators.required]] });
  }
  editFormulario() {
    this.formUpdate = this.fmb.group({
      tipoDocumento: ['', [Validators.required]],
      name: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      document: [''],
      phone: ['', [Validators.required]],
      direccion: [''],
    })
  }


  getProfile() {
    this.fb.api('/me')
      .then((res: any) => {
      })
      .catch();
  }
  consultTipoDoc() {
    const consulta: Consult = {
      schema: 'admin',
      tabla: 'tipodocumentos',
      campo: 'idtipodocumento ,tipocorto'
    };
    const peticionUser = new RequestProcess();
    peticionUser.proceso = '1';
    peticionUser.consulta = JSON.stringify(consulta);
    this.accessService.execProcess(peticionUser)
      .subscribe((res: any) => {
        let dataResponse = JSON.parse(res.dataresponse);
        for (let tipIdentificacion of dataResponse) {
          this.listaTipoDoc.push(tipIdentificacion);
        }
        this.listaTipoDoc.length
      });
  }

  consultUserData() {

  }

  updateUserData() {
    if (this.formUpdate.valid) {
      let consulta: Register_aux =
      {
        idusuario: this.idUsuario,
        ip: null,
        id: this.id,
        nombre: this.name,
        apellido: this.lastName,
        documento: this.document,
        correo: this.email,
        telefono: +this.phone,
        estado: 1,
        idestadousuario: 1,
        direccion: this.direccion,
        semilla: null,
        idtipodocumento: this.tipoDocumento,
        otp: ""
      }
      let peticion = new RequestProcess()
      peticion.proceso = '15';
      peticion.consulta = JSON.stringify(consulta)
      this.accessService.execProcess(peticion)
        .subscribe(res => {
          if (res.mensaje === 'OK') {
            if(JSON.parse(res.dataresponse).idusuario != undefined){
              this.consultData(this.id);
              this.message = 'Datos actualizados con éxito';
              this.ngxSmartModalService.getModal('myModal').open();
            }else{
              this.otpSend='';
              this.otpSend = JSON.parse(res.dataresponse).otp; 
              this.sms.sendSmsCelular(this.phone,this.otpSend);
              this.mensajeSuccess="";
              this.my_otp="";
              this.ngxSmartModalService.getModal('myModalOTP').open();
            }
          }
          else{
            console.log('error, ', res.mensaje)
            this.message = res.mensaje;
            this.ngxSmartModalService.getModal('myModal').open();
          }
        })
    } else {
      this.message = 'Por favor, asegúrese de completar todos los campos del formulario y verificar que cada uno sea válido antes de continuar.';
      this.ngxSmartModalService.getModal('myModal').open();
    }
  }
  regresar() {
    this.router.navigate(['/home']);
  }

  consultData(idp) {
    const consulta: Consult = {
      schema: 'admin',
      tabla: 'personas',
      campo: 'idtipodocumento ,nombre, apellido, idpersona, documento, telefono, correo, direccion',
      condicion: 'idpersona=' + idp
    };
    const peticionUser = new RequestProcess();
    peticionUser.proceso = '1';
    peticionUser.consulta = JSON.stringify(consulta);
    this.accessService.execProcess(peticionUser)
      .subscribe((res: any) => {
        let resp = JSON.parse(res.dataresponse);
        this.onTipoDocumentoChange(String(resp[0].idtipodocumento));
        sessionStorage.setItem('User.data.nombre', resp[0].nombre);
        this.name = resp[0].nombre;
        this.lastName = resp[0].apellido;
        this.tipoDocumento = resp[0].idtipodocumento;
        this.formUpdate.get('document').setValue(resp[0].documento); 
        this.phone = resp[0].telefono;
        this.direccion = resp[0].direccion;
        this.email = resp[0].correo;
      });
  }


  onTipoDocumentoChange(tipo: string) {
    this.tipoDocumento = Number(tipo);
    const documentControl = this.formUpdate.get('document');

    // Verificar que documentControl exista
    if (!documentControl) {
      console.error('El control document no está definido en el formulario');
      return;
    }
    if (tipo === '1') {
      //C.C
      this.documentMask = "0*";
      this.sizeMin= '7';
      this.sizeMax= '12';
      this.expresionReg = null
    } else if (tipo === '4') {
      this.documentMask = "0*-0";
      this.sizeMin= '';
      this.sizeMax= '';
      this.expresionReg = new RegExp('^\\d+-\\d+$');
    } else if (tipo === '8') {
      //PASAPORTE
      this.documentMask = "A*";
      this.sizeMin= '5';
      this.sizeMax= '20';
      this.expresionReg = null
    } else if (tipo === '3') {
      //NIT
      this.documentMask = "0*-0";
      this.sizeMin= '';
      this.sizeMax= '';
      this.expresionReg = new RegExp('^\\d+-\\d+$');
    } else if (tipo === '2') {
      //CEDULA EXTRANGERIA
      this.documentMask = "E0*";
      this.sizeMin= '7';
      this.sizeMax= '12';
      this.expresionReg = null
    } else {
      this.documentMask = "0";
      this.expresionReg = null
    }
    this.formUpdate.get('document').reset();
    documentControl.updateValueAndValidity();
    if (tipo === '2') {
      documentControl.get('document').setValue('E');
      documentControl.updateValueAndValidity();
    }
  }



  iniciarCuentaRegresiva() {
    this.botonDeshabilitado = true;
    this.tiempoRestante = 31;

    // Iniciar la cuenta regresiva
    this.intervalo = setInterval(() => {
      if (this.tiempoRestante > 0) {
        this.tiempoRestante--; // Reducir un segundo
        this.mensajeSuccess = `Código incorrecto. Por favor, inténtalo de nuevo en  <span class="tiempo-azul"> ${this.tiempoRestante}  </snap> segundos.`;
      } else {
        // Cuando llegue a 0, detener el intervalo
        clearInterval(this.intervalo);
        this.intervalo = null;
        this.isOtpValid = true;
        this.mensajeSuccess = '';
        this.botonDeshabilitado = false;
      }
    }, 1000); // Ejecutar cada segundo (1000 ms)
  }



  validOTP() {
    if (this.otpForm.valid) {
      if (this.otpSend === this.my_otp) {
        let consulta: Register_aux =
        {
          idusuario: this.idUsuario,
          ip: null,
          id: this.id,
          nombre: this.name,
          apellido: this.lastName,
          documento: this.formUpdate.get('document').value,
          correo: this.email,
          telefono: +this.phone,
          direccion: this.direccion,
          estado: 1,
          idestadousuario: 2,
          semilla: null,
          otp: this.my_otp,
          idtipodocumento: this.tipoDocumento,
        }
        let peticion = new RequestProcess()
        peticion.proceso = '15';
        peticion.consulta = JSON.stringify(consulta);
        this.accessService.execProcess(peticion)
          .subscribe(res => {
            if (res.mensaje === 'OK' && res.dataresponse) {
              let data = JSON.parse(res.dataresponse);
              if(JSON.parse(res.dataresponse).idusuario != undefined){
                this.isOtpValid = true ;
                this.mensajeSuccess = "Código correcto.";
                sessionStorage.setItem('User', JSON.stringify({ data }));
                this.mensajeSuccess="";
                this.my_otp="";
                this.ngxSmartModalService.getModal('myModalOTP').close();
                this.message = res.mensaje
                this.ngxSmartModalService.getModal('myModal').open();
               
              } else if (data.otp != undefined) {
                this.otpSend = '';
                this.otpSend = JSON.parse(res.dataresponse).otp;
                this.mensajeSuccess = "Le hemos enviado otro código. El código actual ha vencido";
                this.sms.sendSmsCelular(this.phone, data.otp)
              }else{
                this.otpSend='';
                this.otpSend = JSON.parse(res.dataresponse).otp 
               
              }
            }else{
              this.message = res.mensaje
              this.ngxSmartModalService.getModal('myModal').open();
            }
          })
      } else {
        this.isOtpValid = false;
        this.iniciarCuentaRegresiva();
      }
    } else {
    }

  }

  maskValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      const isValid = this.validateMask(value); // Implementa tu lógica de validación de máscara

      return isValid ? null : { documentMask: true }; // Retorna un error si no es válido
    };
  }
  validateMask(value: string): boolean {
    // Aquí implementa la lógica para validar el formato de tu documento
    // Por ejemplo, utilizando una expresión regular o simplemente verificando el formato que desees
    const regex = /^[0-9]{1,2}-[0-9]{1,2}$/; // Ajusta esto según tu formato
    return regex.test(value);
  }


  
}





