import { Component, OnInit, TemplateRef, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ProcesswebService } from 'src/app/services/processweb.service';

import { AuthService } from 'src/app/services/auth.service';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
})

export class UserMenuComponent implements OnInit {

  constructor(private authService: AuthService, private router: Router, private auth: AuthService, public ngxSmartModalService: NgxSmartModalService) { }

  public isLogged: boolean = false;
  userName: string = "";

  ngOnInit(): void {
    this.getCurrentUser();
    //throw new Error('Method not implemented.');
  }

  getCurrentUser() {
    let logged = this.authService.isAuth()
    if (logged) {
      this.isLogged = true;
      const usuario = JSON.parse((sessionStorage.getItem('User')));
      this.userName = usuario.data.nombre;
    } else {
      this.isLogged = false;
      this.userName = "Anónimo"

    }
  }

  navigateToPerfil(userOption){
    this.router.navigate([ 'perfil/', userOption])
    this.auth.changedOptionUserMenu(userOption)
  }

  openModal(){
    this.ngxSmartModalService.getModal('myModalRegistro').open();
  }

  LogOut() {
    sessionStorage.removeItem('User');
    /* this.fb.logout().then(() => {
      this.isLogged = false;
      this.getCurrentUser();
    }); */
    this.isLogged = false;
    //this.router.navigate(['home']);
    this.getCurrentUser();
    this.router.navigate([''])
  }

  triggerMenu(){
    if ($('.cont-menu').is(":visible"))
        $('.cont-menu').fadeOut();
    else
        $('.cont-menu').fadeIn();
  }

}
