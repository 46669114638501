import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { Consult } from '../../objects/consult';
import { RequestProcess } from '../../objects/request';
import { ProcesswebService } from '../../services/processweb.service';
import { getPluralCategory } from '@angular/common/src/i18n/localization';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { appendFile } from 'fs';
import { Router } from '@angular/router';
import { Constants } from '../../objects/constans';
import { Location } from '@angular/common';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {
  
  public isLogged = false;
  public _dataCategoria: any;
  categorieImage: any;
  nombreCategoria = '';
  listaGrupos: any;
  logoImage: any;
  logoUrl: any = 'assets/images/categoria-1.png'; //'assets/img/logolegamio.png';
  message = '';

  /*to modal content */
  _dataContent: any;
  productText: any;
  productImage: any;
  productVideo: any;
  productAudio: any;
  areVideo = false;
  areSound = false;
  areImage = false;
  areText = false;
  viewVideo = false;
  viewSound = false;
  viewImage = false;
  viewText = false;
  audio: any = document.getElementById('mp3Source');

  openHelp = false;
  openVideo = false;
  openSound = false;

  /* to ranking products */
  listaProductos: any;
  public idcat: any;
  isranking: boolean;
  rankin1: boolean;
  rankin2: boolean;
  rankin3: boolean;
  nombreCapitulo: string = "";

  constantes = new Constants();

  constructor(
    private authService: AuthService,
    private _route: ActivatedRoute,
    private accessService: ProcesswebService,
    public ngxSmartModalService: NgxSmartModalService,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private router: Router,
    private _location: Location ) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getCurrentUser();
    this.nombreCategoria = this._route.snapshot.paramMap.get('category');
    sessionStorage.setItem('Category', this.nombreCategoria);
    this.nombreCapitulo = sessionStorage.getItem('Chapter');
    this.listarGrupos();
    //this.rankinproduct();
    this._dataCategoria = [{ contenido: '' }];

    // Proceso para cargar el gif
    var getGif = function() {
      var gif = [];
      $('img').each(function() {
      var data = $(this).data('alt');
      gif.push(data);
      });
      return gif;
    }
    var gif = getGif(); 

    // Preload all the GIF.
    var image = [];

    $.each(gif, function(index) {
      image[index] = new Image();
      image[index].src = gif[index];
    });
  }

  animatedGif() {
    // código JS para activar gif del pollo
    var $this = $(this),
    $index = $this.index(),

    $img = $('#imagenGif'),
    $imgSrc = "./assets/images/Bird.gif",
    $imgAlt = "./assets/images/Bird.gif",
    $imgExt = $imgAlt.split('.');

    $img.attr('src', $imgAlt).attr('data-alt', $imgSrc);
    /////////end cod. JS
  }
  

  getCurrentUser() {
    this.isLogged  = this.authService.isAuth();
  }

  listarGrupos() {
    try {
      this.listaGrupos = new Array<any>();
      const consulta: Consult = {
        schema: 'producto',
        tabla: 'categorias_v',
        campo: 'nombrecategoria, idcategoria, contenido, descripcion',
        condicion: 'nombrecategoria=\'\'' + this.nombreCategoria + '\'\' '// and  nombrecontenido =  \'\'IMAGEN\'\' '
      };
      const peticion = new RequestProcess();
      peticion.proceso = '1';
      peticion.consulta = JSON.stringify(consulta);
      this.accessService.execProcess(peticion)
        .subscribe(async (res: any) => {
          if (res.codigo === '00') {
            const dataCategoria = JSON.parse(res.dataresponse);
            this._dataCategoria = await dataCategoria;

            //this.categorieImage = this._dataCategoria[0].contenido;
            this.idcat = this._dataCategoria[0].idcategoria
            // if (this.categorieImage === null || this.categorieImage === undefined || this.categorieImage === '') {
            //   this.categorieImage = this.logoUrl;
            // }

            // LISTADO GRUPOS POR CATEGORIA ------------>
            if (this._dataCategoria != null || this._dataCategoria !== '') {
              const consultaGrupo: Consult = {
                schema: 'producto',
                tabla: 'grupos ',
                campo: 'nombre, idestadogrupo, idcategoria',
                condicion: 'idestadogrupo = 1 and idcategoria ='  + this._dataCategoria[0].idcategoria
              };
              const peticionGrupo = new RequestProcess();
              peticionGrupo.proceso = '1';
              peticionGrupo.consulta = JSON.stringify(consultaGrupo);
              this.accessService.execProcess(peticionGrupo)
                .subscribe((resp: any) => {
                  if (resp.codigo === '00') {
                    const listGroup = JSON.parse(resp.dataresponse);
                    this.listaGrupos = listGroup;
                    for (let i = 0; i < this.listaGrupos.length; i++) {
                      if (this.listaGrupos[i].contenido === null || this.listaGrupos[i].contenido === '' || this.listaGrupos[i].contenido === undefined) {
                        this.listaGrupos[i].contenido = this.logoUrl;
                      }
                    }
                  } else if (resp.codigo === '90' && resp.mensaje == 'Consulta sin resultados' ) {
                    this.message = 'No hay grupos asociados a la categoria';
                    this.ngxSmartModalService.getModal('myModal').open();                    
                  } else {
                    this.message = resp.mensaje;
                    this.ngxSmartModalService.getModal('myModal').open();
                  }
                });
            }
          } else {
            this.message = res.mensaje;
            this.ngxSmartModalService.getModal('myModal').open();
            if(res.mensaje == "Consulta sin resultados") {
              this.router.navigate(['/404']);
            }
          }
        });

    } catch (error) {
      console.log(error)
    }
  }  

  rankinproduct(){
    try{
        this.listaProductos = new Array<any>();
        const consulta: Consult= {
          schema: 'producto',
          tabla: 'ranking3productos',
          campo:'*',
          condicion: 'categoria=\'\'' + this.nombreCategoria + '\'\' limit 3'
        }
        const peticion = new RequestProcess();
        peticion.proceso = '1';
        peticion.consulta = JSON.stringify(consulta);
        this.accessService.execProcess(peticion)
            .subscribe((res: any) => {
              if(res.codigo === '00'){
                const listproduct = JSON.parse(res.dataresponse);
                this.listaProductos = listproduct;
                this.isranking = true;
                if( this.listaProductos[0] != undefined ){
                  this.rankin1 = true;
                 }
                    if( this.listaProductos[1] != undefined ){
                      this.rankin2 = true;
                    }
                        if( this.listaProductos[2] != undefined ){
                          this.rankin3 = true;
                        }
              }else {
                this.isranking = false;
              }
            });
    }
    catch(error){
      console.log(error);
    }
  }
  
  navigateToProduto(producto){
    this.router.navigate(['/producto/'+producto]);
  }

  goBack(){
    this._location.back();
  }
}
