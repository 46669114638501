import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TreeService } from '../../../services/tree.service';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../../objects/constans';
import { Consult } from 'src/app/objects/consult';
import { RequestProcess } from 'src/app/objects/request';
import { ProcesswebService } from 'src/app/services/processweb.service';
import { take } from 'rxjs/operators';


@Component({
  selector: 'app-visual-aids',
  templateUrl: './visual-aids.component.html',
  styleUrls: ['./visual-aids.component.css']
})

export class VisualAidsComponent implements OnInit {

  private constantes = new Constants();


  /** Variable para identificar el tipo de contenido*/
  @Input('typeContent') content: string | any;

  /** Variable para identificar si existe video*/
  @Input('search') conditionSearch: string | any;

  @Output() animatedEvent: EventEmitter<any> = new EventEmitter<any>();

  /** Variable para saber cantodad de contenidos disponibles */
  private countContent: number = 0;

  // tslint:disable-next-line:variable-name
  constructor(
    private route: ActivatedRoute,
    private treeService: TreeService,
    public ngxSmartModalService: NgxSmartModalService,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private accessService: ProcesswebService) { }

  
  areVideo = false;
  areSound = false;
  areImage = false;
  areText = false;
 
  viewVideo = false;
  viewSound = false;
  viewImage = false;
  viewText = false;
 
  contentText: any;
  contentImage: any;
  contentVideo: any;
  contentAudio: any;
 
  buttonSelected: string = "";
  subscribe: any;

  nodeText: any;
  nodeVideo: any;
  nodeAudio: any;
  nodeImage: any;
  idNodo: any;

  ngOnInit() {
    this.getContent();
  }

  changeContent(content: number) {

    this.animatedEvent.emit();
    const audio: any = document.getElementById('mp3Source');
    const audioNode: any = document.getElementById('mp3Nodo');
    audio && audio.pause();
    audioNode && audioNode.pause();

    switch (content) {
      case 1: // audio ?????
        this.viewVideo = false;
        this.viewSound = true;
        this.viewImage = false;
        this.viewText = false;
        
        this.buttonSelected = "audio";
        break;
      case 2: //  imagen
        this.viewVideo = false;
        this.viewSound = false;
        this.viewImage = true;
        this.viewText = false;

        this.buttonSelected = "imagen";
        break;
      case 3: // text
        this.viewVideo = false;
        this.viewSound = false;
        this.viewImage = false;
        this.viewText = true;
        this.buttonSelected = "texto";
        break;
      case 4: // video
        this.viewVideo = true;
        this.viewSound = false;
        this.viewImage = false;
        this.viewText = false;
    
        this.buttonSelected = "video";
        break;
    }
  }

  getContent() {
    let table = "", schema = "", campos = "", condicion = "";
    this.countContent = 0;
    switch (this.content) {
      case 'category': 
        schema = "producto";
        table = "categorias_v";
        campos = "nombrecategoria, descripcion, idcategoria, contenido, nombrecontenido";
        condicion = "estadocategoria = \'\'ACTIVO\'\' and nombrecategoria= \'\'" + this.conditionSearch + "\'\'";
        break;
      case 'group':
        schema = 'producto';
        table = 'grupos_v';
        campos = 'nombregrupo, descripcion, idgrupo, contenido, nombrecontenido';
        condicion = 'nombregrupo= \'\'' + this.conditionSearch + '\'\' ';
        break;
      case 'product':
        this.contentProduct(); 
        break;
      case 'node':
        this.consultHelpNode();
        break;

      default:
        break;
    }try{
      const consult: Consult = {
        schema: schema,
        tabla: table,
        campo: campos,
        condicion: condicion
      };
      const peticion = new RequestProcess();
      peticion.proceso = '1';
      peticion.consulta = JSON.stringify(consult);
      this.accessService.execProcess(peticion)
        .subscribe((res: any) => {
          if (res.codigo === '00') {
            const dataContent = JSON.parse(res.dataresponse);

            let audiofilter = dataContent.filter(element => element.nombrecontenido == 'AUDIO')[0]

            /* Audio */
            if (audiofilter === undefined) {
              this.areSound = false;
            } else {
              if (audiofilter.contenido !== null) {
                this.contentAudio = audiofilter.contenido;
                this.http.get(this.constantes.getConectNode() + 'uploadaudio/categoria_' + audiofilter.idcategoria + '_' + this.contentAudio, {
                  responseType: 'blob'
                }).subscribe(
                  (val) => {
                    const url = URL.createObjectURL(val);
                    const audio: any = document.getElementById('mp3Source');
                    audio.src = url;
                    audio.load();
                    this.areSound = true;
                    this.countContent++;
                  },
                  response => {
                    console.log('POST call in error', response);
                  },
                  () => {
                    console.log('The POST observable is now completed.');
                  }
                );
              } else {
                console.error('No hay contenido de audio')
              }

            }

            /* imagen */
            let imagenfilter = dataContent.filter(element => element.nombrecontenido == 'IMAGEN')[0]
            if (imagenfilter === undefined) {
              this.areImage = false;
            } else {
              this.contentImage = imagenfilter.contenido;
              this.areImage = true;
              this.countContent++;
              this.changeContent(2);
            }

            /* Texto */
            let textofilter = dataContent.filter(element => element.nombrecontenido == 'TEXTO')[0]
            if (textofilter === undefined) {
              this.areText = false;
            } else {
              this.areText = true;
              this.countContent++;
              this.contentText = this.sanitizer.bypassSecurityTrustHtml(textofilter.contenido.replace(/\+\+\+/g, "\""));
              
              if(this.areImage == false) {
                this.changeContent(3);
              }
            }

            /* video */
            let videofilter = dataContent.filter(element => element.nombrecontenido == 'VIDEO')[0]
            if (videofilter === undefined) {
              this.areVideo = false;
            } else {
              if (videofilter.contenido !== null) {
                this.areVideo = true;
                this.countContent++;
                this.contentVideo = this.sanitizer.bypassSecurityTrustResourceUrl(videofilter.contenido.replace('watch?v=', 'embed/'));
              }
            }
          }
        });

    } catch (error) {
      console.log(error);
    }
  }

  consultHelpNode() {
    this.subscribe = this.treeService._contenidoNodo$()
    .subscribe( contenidoNodo => {
      //console.log(contenidoNodo);

      const dataAudio: any = contenidoNodo ? contenidoNodo.filter( element => element.idtipocontenido == 2)[0] : null;
      const dataImage: any = contenidoNodo ? contenidoNodo.filter( element => element.idtipocontenido == 3)[0] : null;
      const dataText: any = contenidoNodo ? contenidoNodo.filter( element => element.idtipocontenido == 4)[0] : null;
      const dataVideo: any = contenidoNodo ? contenidoNodo.filter( element => element.idtipocontenido == 1)[0] : null;

      if(dataText != undefined) {
        this.contentText = dataText.contenido ? this.sanitizer.bypassSecurityTrustHtml(dataText.contenido.replace(/\+\+\+/g,"\"")) : '';
        this.contentVideo = dataVideo.contenido ? this.sanitizer.bypassSecurityTrustResourceUrl(dataVideo.contenido.replace('watch?v=', 'embed/')) : '';
        this.contentAudio = dataAudio.contenido ? dataAudio.contenido : '';
        this.contentImage = dataImage.contenido ? dataImage.contenido : '';
        this.idNodo = contenidoNodo[0].idnodo;
        
        this.areText = dataText.contenido ? true : false;
        this.areVideo = dataVideo.contenido ? true : false;
        this.areSound = dataAudio.contenido ? true : false;
        this.areImage = dataImage.contenido ? true : false;

        const countTrue = (this.areText ? 1 : 0) + 
                          (this.areVideo ? 1 : 0) + 
                          (this.areSound ? 1 : 0) + 
                          (this.areImage ? 1 : 0);

        this.countContent = countTrue; 

        if(this.areImage)
          this.changeContent(2);
        else if(this.areText)
          this.changeContent(3);
        

        this.areSound && this.http.get(this.constantes.getConectNode() + 'uploadaudio/nodo_' + this.idNodo + '_' + this.contentAudio, {
          responseType: 'blob'
        }).subscribe(
          (val) => {
              this.areSound = true
              const url = URL.createObjectURL(val);
              const audio: any = document.getElementById('mp3Source');
              audio.src = url;
              audio.load();
            
          },
          response => {
            console.log('POST call in error', response);
          },
          () => {
            console.log('The POST observable is now completed.');
          }
        );
      }

    });
  }

  contentProduct() {
    try {
      const consult: Consult = {
        schema: 'producto',
        tabla: 'productos_v',
        campo: 'nombre, descripcion, idproducto, contenido',
        condicion: 'nombre= \'\'' + this.conditionSearch + '\'\' '
      };
      const peticion = new RequestProcess();
      peticion.proceso = '1';
      peticion.consulta = JSON.stringify(consult);
      this.accessService.execProcess(peticion)
        .subscribe((res: any) => {
          if (res.codigo === '00') {
            const dataProduct = JSON.parse(res.dataresponse)[0];
            if(dataProduct.contenido)
            {
                  /* texto */
                  let textofilter = dataProduct.contenido.filter(elemento => elemento.tipocontenido === 'TEXTO')[0]
                  if (textofilter.contenido === null || textofilter.contenido == '') {
                    this.areText = false;
                  } else {
                    this.areText = true;                    
                    this.countContent++;
                    this.contentText = this.sanitizer.bypassSecurityTrustHtml(textofilter.contenido.replace(/\+\+\+/g, "\""));

                    if(this.areImage == false) {
                      this.changeContent(3);
                    }
                  }
                  /* imagen */
                  let imagenfilter = dataProduct.contenido.filter(elemento => elemento.tipocontenido === 'IMAGEN')[0]

                  if (imagenfilter.contenido === null || imagenfilter.contenido === '') {
                    this.areImage = false;
                  } else {
                    this.areImage = true;
                    this.countContent++;
                    this.contentImage = imagenfilter.contenido;
                    this.changeContent(2);
                  }
                  /* video */
                  let videofilter = dataProduct.contenido.filter(elemento => elemento.tipocontenido === 'VIDEO')[0]
                  if (videofilter.contenido === null || videofilter.contenido == '') {
                    this.areVideo = false;
                  } else {
                    this.areVideo = true;
                    this.countContent++;
                    this.contentVideo = this.sanitizer.bypassSecurityTrustResourceUrl(videofilter.contenido.replace('watch?v=', 'embed/'));                  }

                  /* Audio */
                  let audiofilter = dataProduct.contenido.filter(elemento => elemento.tipocontenido === 'AUDIO')[0]
                  if (audiofilter.contenido === null || audiofilter.contenido == '') {
                    this.areSound = false;
                  } else {
                    this.areSound = true;
                    this.countContent++;
                    this.contentAudio = audiofilter.contenido;
                    this.http.get(this.constantes.getConectNode() + 'uploadaudio/producto_' + dataProduct.idproducto + '_' + this.contentAudio, {
                      responseType: 'blob'
                    }).subscribe(
                      (val) => {
                        const url = URL.createObjectURL(val);
                        const audio: any = document.getElementById('mp3Source');
                        audio.src = url;
                        audio.load();
                      },
                      response => {
                        console.log('POST call in error', response);
                      },
                      () => {
                        console.log('The POST observable is now completed.');
                      }
                    );
                  }

            }

          }
        });

    } catch (error) {
      console.error(error)
    }
  }
}
