import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IImage } from 'ng-simple-slideshow/src/app/modules/slideshow/IImage';
import { Consult } from '../../objects/consult';
import { RequestProcess } from '../../objects/request';
import { ProcesswebService } from '../../services/processweb.service';
import { url } from 'inspector';
import { sha512 } from 'js-sha512';
import { Router } from '@angular/router';
import { StarRatingComponent } from 'ng-starrating';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from 'src/app/services/auth.service';

import { NgxSmartModalService } from 'ngx-smart-modal';
import { SearchService } from 'src/app/services/search.service';
import { NequiService } from 'src/app/services/nequi.service';
import { SmsService } from 'src/app/services/sms.service';


@Component({
  selector: 'app-products',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  listaCapitulos: any;
  listaCategorias: any;
  logoImage: any;
  logoUrl: any = 'assets/img/logolegamio.png';

  imageUrls: (string | IImage)[] = [
    { url: '../../../assets/img/slider_1.jpg', href: '' },
    { url: '../../../assets/img/slider_2.png', href: '' },
    // tslint:disable-next-line:max-line-length
    { url: '../../../assets/img/slider_3.png', },
  ];
  height = '100%';
  minHeight: '350px';
  showArrows = false;
  autoPlay = true;
  autoPlayInterval = 4000;
  stopAutoPlayOnSlide = true;
  categoryName: any = '';
  video: string | any;
  isLogged: boolean = false;

  constructor(
    private authService: AuthService,
    private accessService: ProcesswebService,
    private router: Router,
    private sanitizer: DomSanitizer,
    public ngxSmartModalService: NgxSmartModalService,
    public search: SearchService,
    public nequi: NequiService,
    public sms:SmsService,
  ) {
    this.listarCapitulos();
  }
  ngAfterContentInit(): void {
/*     throw new Error('Method not implemented.');
 */  
  }

  ngOnInit() {
    this.nequi.currentAttribute.subscribe(token => {
    });
    
    sessionStorage.removeItem('Category');
    sessionStorage.removeItem('Capitulo')
    /*
    FB.getLoginStatus(function(response) {
      statusChangeCallback(response);
  });
   */
    window.scrollTo(0, 0);

    //this.listarCategorias();
    this.initVideo();

    let logged = this.authService.isAuth();
    if (logged) {
      this.isLogged = true;
    } else {
      this.isLogged = false;
      setTimeout(() => {
        this.ngxSmartModalService.getModal('myModal').open();
      }, 600);
    }
  }

  listarCapitulos() {
    try {
      this.listaCapitulos = new Array<any>();
      const consulta: Consult = {
        schema: 'producto',
        tabla: 'capitulos',
        campo: '*',
        //condicion: 'estadocategoria = \'\'ACTIVO\'\' ' // and nombrecontenido =  \'\'IMAGEN\'\' '
      };
      const peticion = new RequestProcess();
      peticion.proceso = '1';
      peticion.consulta = JSON.stringify(consulta);
      this.accessService.execProcess(peticion)
        .subscribe((res: any) => {
          if (res.codigo === '00') {
            this.listaCapitulos = JSON.parse(res.dataresponse);
            // tslint:disable-next-line:prefer-for-of
          }
        })
    }
    catch (error) {

    }
  }

  navigateToProduto(producto) {
    this.router.navigate(['/producto/' + producto]);
  }

  initVideo() {
    try {
      this.listaCategorias = new Array<any>();
      const consulta: Consult = {
        schema: 'admin',
        tabla: 'parametros',
        campo: '*'
      };
      const peticion = new RequestProcess();
      peticion.proceso = '1';
      peticion.consulta = JSON.stringify(consulta);
      this.accessService.execProcess(peticion)
        .subscribe((res: any) => {
          if (res.codigo === '00') {
            const resp = JSON.parse(res.dataresponse)[0];
            const urlVideo = resp.valor;
            this.video = this.sanitizer.bypassSecurityTrustResourceUrl(urlVideo.replace('watch?v=', 'embed/'));

          } else {
           
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  redirect() {
    this.router.navigate(['/login']);
  }
  /*   receiveData(data: string): void {
      
      console.log("Entre aca "+data);
      this.search.setSearch=data;
      
      this.search.showTipsLegales();
    } */

}
