import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, Validators } from '@angular/forms';
import { sha512 } from 'js-sha512';
import { ProcesswebService } from '../../../../services/processweb.service';
import { RequestProcess } from '../../../../objects/request';
import { NgxSmartModalService } from 'ngx-smart-modal';

import * as appjs from 'src/assets/js/app.js';
import { Router } from '@angular/router';


declare var jsWorkDesign: any;
@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html',
  styleUrls: ['./configuracion.component.css']
})
export class ConfiguracionComponent implements OnInit {

  constructor(private accessService: ProcesswebService, public ngxSmartModalService: NgxSmartModalService, private router: Router,public fb : FormBuilder) { }

  /* public presentPass: string = ''; */
  actPass:string = ''
  newPass: string  = '';
  newPass2: string  = '';
  message?: string="";
 // eye: boolean = false;
  eyeActual: boolean = false;
  eyeNew: boolean = false;
  eyeRepeat: boolean = false;
  formRecovery : FormGroup;
  email:string = '';
  informacion:string = '';
  estadoUsuario:string = '';
/*   emailRegistrado:string = ''; */


  public dataUser;
  ngOnInit() {
    this.estadoUsuario=JSON.parse(sessionStorage.getItem('User')).data.idestadousuario;
    this.email=JSON.parse(sessionStorage.getItem('User')).data.cuenta;
    this.formRecovery = this.fb.group({
      actPass:['',Validators.required],
      newPass:['', [
        Validators.required,
        Validators.pattern(/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{9,}$/)
      ]],
      newPass2:['', [
        Validators.required,
        Validators.pattern(/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{9,}$/)
      ]]
      /* ,emailRegistrado:['',[Validators.required]] */
    })
      if(sessionStorage.getItem('User') !== null){
        this.dataUser = JSON.parse(sessionStorage.getItem('User'))
        console.log(this.dataUser.data.idusuario)
      }
  }

  changePass(){
      if(this.formRecovery.valid){
            if(this.newPass2 === this.newPass){
            let consulta: any =
            {
              ip: null,
              idusuario: this.dataUser.data.idusuario,
              clave: sha512(this.newPass),
              claveconfirma: sha512(this.newPass2),
              claveactual: sha512(this.actPass)
            }

            let peticion = new RequestProcess()
                peticion.proceso = '8'
                peticion.consulta = JSON.stringify(consulta)
                console.log(peticion.consulta)
                this.accessService.execProcess(peticion)
                  .subscribe( res => {
                    console.log(res, res.mensaje)
                    if (res.mensaje  === 'OK') {
                      this.message = 'Clave cambiada con éxito'
                      this.ngxSmartModalService.getModal('myModal').open();
                      console.log(res.mensaje, 'cambio clave');
                      this.router.navigate(['/home']);
                      this.newPass = this.newPass2 = '';
                      let user = JSON.parse(sessionStorage.getItem('User'));
                      user.data.idestadousuario = 1;
                      sessionStorage.setItem('User', JSON.stringify(user));
                    }else {
                    this.message = res.mensaje
                    this.ngxSmartModalService.getModal('myModal').open();
                    this.newPass = this.newPass2 = '';
                  }
                });
          }else{
          this.message = 'Las contraseñas no coinciden. Asegúrate de que sean iguales';
          this.ngxSmartModalService.getModal('myModal').open();
          }
      }else{
          this.message = 'Por favor, asegúrese de completar todos los campos del formulario y verificar que cada uno sea válido antes de continuar.';
          this.ngxSmartModalService.getModal('myModal').open();
      }

  }

  regresar() {
    this.router.navigate(['/home']);
  }
  /* showPassword() {
    let pass: any = document.getElementById("actPass");

    if (pass.type == "text") {
      pass.type = "password";
      this.eye = false;
    } else {
      pass.type = "text";
      this.eye = true;
    }

  } */
 

  showPassword(field: string) : void{
    if (field === 'actual') {
      this.eyeActual = !this.eyeActual;
    } else if (field === 'new') {
      this.eyeNew = !this.eyeNew;
    } else if (field === 'repeat') {
      this.eyeRepeat = !this.eyeRepeat;
    }
  }
  passwordRecovery(event: any) {
    event.preventDefault();
    let consulta: any =
    {
      ip: '192.168.0.10',
      cuenta: this.email
    }
    let peticion = new RequestProcess()
    peticion.proceso = '10'
    peticion.consulta = JSON.stringify(consulta)
    this.accessService.execProcess(peticion)
      .subscribe(res => {
        if (res.mensaje === 'OK') {
          let data = JSON.parse(res.dataresponse);
          const mail: any = {
            user: data.nombre,
            documento: this.email,
            email: this.email,
            clave: data.clave,
            url: 'http://legamio.co:8080/#/login'
          };
          let peticion = new RequestProcess()
          peticion.proceso = '1'
          peticion.consulta = JSON.stringify(mail)
          this.accessService.sendEmail(mail, 'recoveryPasswordLegamio')
            .subscribe(res => {
              if (res.success) {
                // PARTE DEL MODAL
                this.message = 'Se ha enviado un enlace al correo registrado para continuar con el proceso de recuperación de contraseña. Por favor, revisa tu bandeja de entrada o la carpeta de SPAM.'
                this.ngxSmartModalService.getModal('myModal').open();
              } else {
                this.message = 'Error durante el proceso de recuperación de clave, por favor intente de nuevo más tarde'
                this.ngxSmartModalService.getModal('myModal').open();
              }
            },
              error => console.log(error)
            )
          //
        } else if (res.mensaje === 'Consulta sin resultados') {
          this.informacion = 'El usuario no existe';
          this.message = 'El usuario no existe';
          this.ngxSmartModalService.getModal('myModal').open();
        } else {
          this.message = res.mensaje
          this.ngxSmartModalService.getModal('myModal').open();
        }
      }
      )

  }

  ///////////
  /**
   * Functions for integration design netbangers
   */

 /*  triggerPass(event)
  {
     
      var $item = $(event.target);//$(this);
      var cambio_test = $(event.target).closest("div");
      var cambio = cambio_test.find(".password-input");

      if ($(cambio).attr('type') === "password") {
          $(cambio).attr('type', 'text');
          $item.removeClass('hidden-password');
      } else {
          $(cambio).attr('type', 'password');
          $item.addClass('hidden-password');
      }

  } */

}
