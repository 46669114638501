import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor( private _route: ActivatedRoute) { }
  
  nombreCategoria : string ="";
  defaultStyle: boolean = true;
  categoryName: string = '';
  footer: number = 0;
  styleFooter: number = 0;


  ano = new Date().getFullYear();
 
  ngOnInit() {
    this.categoryName = sessionStorage.getItem('Category'); 
    if(this.categoryName === 'CATEGORIA ARRA IN' || this.categoryName === '' || this.categoryName === null || this.categoryName === undefined )
    {
      this.footer = 0;
    }
      else if( this.categoryName === 'EMPRESA' ){
        this.footer = 1;   
      }
        else if( this.categoryName === 'LABORAL'){
          this.footer = 2;
        }
          else if( this.categoryName === 'FAMILIA')
          { 
            this.footer = 3;
          }

    this.setStyleHeader();
  }

  setStyleHeader(){
    if(this.nombreCategoria !== '' )
    {
      if(this.nombreCategoria != 'CATEGORIA ARRA IN'){
      this.defaultStyle = false;
      }
      else{     
        this.defaultStyle = true;        
      }
    }
  }
}
