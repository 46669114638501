import { Component, OnInit } from '@angular/core';
import { IImage } from 'ng-simple-slideshow/src/app/modules/slideshow/IImage';
import { Consult } from '../../objects/consult';
import { RequestProcess } from '../../objects/request';
import { ProcesswebService } from '../../services/processweb.service';
import { url } from 'inspector';
import { sha512 } from 'js-sha512';
import { Router } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';


@Component({
  selector: 'app-products-home-temp',
  templateUrl: './home-temp.component.html',
  styleUrls: ['./home-temp.component.css']
})
export class HomeTempComponent implements OnInit {
  
 
  constructor(private accessService: ProcesswebService, private router: Router,  public ngxSmartModalService: NgxSmartModalService) { 
    
  }

  public key:string = "";

  ngOnInit() {

  }
    
  keyLegamio(){
    if( this.key === 'Arrain-Legamio' ){
      this.router.navigate(['login']);
    }
    else
      if(this.key != 'Arrain-Legamio'){
        this.ngxSmartModalService.getModal('myModal').open();
      }
  }


}
