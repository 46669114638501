import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { AppPasswordDirective } from '../../directives/app-password.directive';
import { SearchComponent } from './search/search.component';
import { VisualAidsComponent } from './visual-aids/visual-aids.component'
import { RouterModule } from '@angular/router';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { MatIconModule } from '@angular/material';



@NgModule({
  declarations:
  [
    UserMenuComponent,
    SearchComponent,
    VisualAidsComponent,
    AppPasswordDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgxSmartModalModule.forRoot(),
    MatIconModule,
    
  ],

  exports: [
    UserMenuComponent,
    SearchComponent,
    VisualAidsComponent,
    AppPasswordDirective
  ],

})
export class SharedModule { }
