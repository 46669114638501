import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FolderComponent } from './folder.component';
import { HeaderModule } from '../header/header.module';
import { MyFolderComponent } from './my-folder/my-folder.component';
import { FooterModule } from '../footer/footer.module';
import { ProcesingComponent } from './procesing/procesing.component';
import { MatTableModule,  MatListModule } from '@angular/material';
import { MatPaginatorModule } from '@angular/material';

@NgModule({
  declarations: [FolderComponent, MyFolderComponent, ProcesingComponent],
  imports: [
    CommonModule,
    HeaderModule,
    FooterModule, 
    MatTableModule, 
    MatPaginatorModule,
    MatListModule
  ],
  exports: [
    MatPaginatorModule
  ]
})
export class FolderModule { }
