import { Component, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TreeService } from '../../services/tree.service';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Location } from '@angular/common';

@Component({
  selector: 'app-pay',
  templateUrl: './pay.component.html',
  //styleUrls: ['./sequencetree.component.css']
})
export class PayComponent implements OnInit {
  nombreProducto: string;
  nombreCategoria: any;
  nombreGrupo: any;
  message: string;

  constructor(
    private routeActive: ActivatedRoute,
    private treeService: TreeService,
    private sanitizer: DomSanitizer,
    public ngxSmartModalService: NgxSmartModalService,
    private _location: Location
    ) { }

  ngOnInit(){

      window.scrollTo(0, 0);
      this.nombreProducto = this.routeActive.snapshot.paramMap.get('productonombre')

      // Información del producto
      this.treeService.infoProduct$()
      .subscribe( (dataProduct: any) => {
        this.nombreCategoria = dataProduct.categoria
        this.nombreGrupo = dataProduct.grupo
      })

  }

  goBack(){
    this._location.back();
  }

}
