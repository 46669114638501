import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'one-modal',
  templateUrl: './one-modal.component.html',
  styleUrls: ['./one-modal.component.css']
})
export class OneModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}