import { Component, OnInit } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormArray, FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Register } from '../../../objects/register'
import { RequestProcess } from '../../../objects/request';
import { ProcesswebService } from '../../../services/processweb.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
//import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

// services
import { AuthService } from '../../../services/auth.service';
import { Register_aux } from 'src/app/objects/register_aux';
import { Consult } from 'src/app/objects/consult';
import { NequiService } from 'src/app/services/nequi.service';
import { SmsService } from 'src/app/services/sms.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
})

export class RegisterComponent implements OnInit {

  constructor(
    private router: Router,
    private accessService: ProcesswebService,
    private authService: AuthService,
    public ngxSmartModalService: NgxSmartModalService,
    public fb: FormBuilder,
    public nequi: NequiService,
    public sms: SmsService,
  ) {
    this.registroFormulario();
    this.OTPFormulario();
    this.consultTipoDoc();
  }
  documentMask: string = '';
  registerForm: FormGroup;
  otpForm: FormGroup;
  public checked = false;
  public name: string = '';
  public lastName: string = '';
  public document: string = '';
  public email: string = '';
  public phone: string = '';
  public direccion: string = '';
  public checkbox: boolean = false;
  public checkboxData: boolean = false;
  my_otp?: string = '';
  public tipoDocumento: number = 0;
  message?: string = '';
  titulo?: string = '';
  verificacionForm: boolean = false;
  otpSend?: string = '';
  listaTipoDoc: any[] = [];
  isOtpValid: boolean = true;
  mensajeSuccess: string = '';
  tiempoRestante: number = 0; // 30 segundos
  intervalo: any;
  botonDeshabilitado: boolean = false;

  sizeMax: string = '';
  sizeMin: string = '';
  expresionReg: RegExp;



  //borrar //
  rateUser: any;
  endProduct: boolean;
  title = '';
  preguntas = '';
  pdfForm: FormGroup;
  nombreProducto = '';
  dataUser: any;
  dataProduct;



  ngOnInit() {
    this.sms.guardarTokenBD();
  }

  consultTipoDoc() {
    const consulta: Consult = {
      schema: 'admin',
      tabla: 'tipodocumentos',
      campo: 'idtipodocumento ,tipocorto'
    };
    const peticionUser = new RequestProcess();
    peticionUser.proceso = '1';
    peticionUser.consulta = JSON.stringify(consulta);
    this.accessService.execProcess(peticionUser)
      .subscribe((res: any) => {
        let dataResponse = JSON.parse(res.dataresponse);
        for (let tipIdentificacion of dataResponse) {
          this.listaTipoDoc.push(tipIdentificacion);
        }
        this.listaTipoDoc.length
      });
  }

  regresar() {
    this.router.navigate(['/home']);
  }


  registroFormulario() {
    this.registerForm = this.fb.group({
      name: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      document: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(45)]],
      phone: ['', [Validators.required]],
      direccion: [''],
      acceptTerms: [false, [Validators.required]],
      acceptData: [false, [Validators.required]],
      tipoDocumento: ['', [Validators.required]],
    })
  }
  OTPFormulario() {
    this.otpForm = this.fb.group({ my_otp: ['', [Validators.required]] });
  }
  termsCheck() {
    this.checkbox = !this.checkbox;
  }

  dataCheck() {
    this.checkboxData = !this.checkboxData;
  }

  validateChecbox() {
    if (this.checkbox === true && this.checkboxData === true) {
      return true
    } else return false
  }



  registerUser() {
    if (this.registerForm.valid) {
      if (this.validateChecbox()) {
        let consulta: Register_aux =
        {
          idusuario: 10001,
          ip: null,
          id: 0,
          nombre: this.name,
          apellido: this.lastName,
          documento: this.registerForm.get('document').value,
          correo: this.email,
          telefono: +this.phone,
          direccion: this.direccion,
          estado: 1,
          idestadousuario: 2,
          semilla: null,
          otp: "",
          idtipodocumento: this.tipoDocumento,
        }
        let peticion = new RequestProcess()
        peticion.proceso = '15'
        peticion.consulta = JSON.stringify(consulta)
        this.accessService.execProcess(peticion)
          .subscribe(res => {
            if (res.mensaje === "OK" && res.dataresponse) {
              this.otpSend = '';
              this.otpSend = JSON.parse(res.dataresponse).otp;
              this.sms.sendSmsCelular(this.phone, this.otpSend)
              this.message = 'Ingrese Un Otp Valido';
              this.titulo = 'OTP';
              this.ngxSmartModalService.getModal('myModalOTP').open();
            } else if (res.mensaje !== 'Consulta sin resultados') {
              console.log('error, ', res.mensaje)
              this.message = res.mensaje
              /* this.ngxSmartModalService.getModal('myModal').open(); */
              this.ngxSmartModalService.getModal('myModal').open();
            }
          });

      }
      else if (this.validateChecbox() === false && this.verificacionForm === true) {
        this.message = 'Debe aceptar los terminos de privacidad y uso de datos';
        this.ngxSmartModalService.getModal('myModal').open();
      } else if (this.checkbox === false && this.verificacionForm === false) {
        this.message = 'Debe completar todos los campos';
        this.ngxSmartModalService.getModal('myModal').open();
      }
    } else {
      this.message = 'Por favor, asegúrese de completar todos los campos del formulario y verificar que cada uno sea válido antes de continuar.';
      this.ngxSmartModalService.getModal('myModal').open();
    }
  }
  validOTP() {
    if (this.otpForm.valid) {
      if (this.otpSend === this.my_otp) {
        let consulta: Register_aux =
        {
          idusuario: 10001,
          ip: null,
          id: 0,
          nombre: this.name,
          apellido: this.lastName,
          documento: this.registerForm.get('document').value,
          correo: this.email,
          telefono: +this.phone,
          direccion: this.direccion,
          estado: 1,
          idestadousuario: 2,
          semilla: null,
          otp: this.my_otp,
          idtipodocumento: this.tipoDocumento,
        }

        let peticion = new RequestProcess()
        peticion.proceso = '15';
        peticion.consulta = JSON.stringify(consulta);
        this.accessService.execProcess(peticion)
          .subscribe(res => {
            if (res.mensaje === 'OK' && res.dataresponse) {
              let data = JSON.parse(res.dataresponse);
              if (data.idusuario != undefined) {
                this.isOtpValid = true
                this.mensajeSuccess = "Código correcto. Bienvenid@ a Legamio!";
                sessionStorage.setItem('User', JSON.stringify({ data }));
                setTimeout(() => {
                  this.router.navigate(['home']);
                  this.authService.isAuth();
                }, 3000);
              } else if (data.otp != undefined) {
                this.otpSend = '';
                this.otpSend = JSON.parse(res.dataresponse).otp;
                this.mensajeSuccess = "Le hemos enviado otro código. El código actual ha vencido";
                this.sms.sendSmsCelular(this.phone, data.otp)
              } else {
                this.otpSend = '';
                this.otpSend = JSON.parse(res.dataresponse).otp
              }
            } else if (res.mensaje !== 'Consulta sin resultados') {
              console.log('error, ', res.mensaje)
              this.message = res.mensaje
              this.ngxSmartModalService.getModal('myModal').open();
            }
          })
      } else {
        this.isOtpValid = false;
        this.iniciarCuentaRegresiva();
      }
    } else {
    }

  }
  sendEmail() {
    const mail: any = {
      user: this.name.trim() + " " + this.lastName,
      documento: this.document,
      email: this.email.trim(),
      url: 'http://legamio.co:8080/#/login'
    }
    let peticion = new RequestProcess()
    peticion.proceso = '1'
    peticion.consulta = JSON.stringify(mail)
    
    this.accessService.sendEmail(mail, 'testLegamio')
      .subscribe(res => {},
        error => console.log(error)
      )

  }

  onTipoDocumentoChange(tipo: string) {
    this.tipoDocumento = Number(tipo);
    const documentControl = this.registerForm.get('document');

    // Verificar que documentControl exista
    if (!documentControl) {
      console.error('El control document no está definido en el formulario');
      return;
    }
   
    if (tipo === '1') {
      //C.C
      this.documentMask = "0*";
      this.sizeMin = '7';
      this.sizeMax = '12';
      this.expresionReg = null
    } else if (tipo === '4') {
      this.documentMask = "0*-0";
      this.sizeMin = '';
      this.sizeMax = '';
      this.expresionReg = new RegExp('^\\d+-\\d+$');
    } else if (tipo === '8') {
      //PASAPORTE
      this.documentMask = "A*";
      this.sizeMin = '5';
      this.sizeMax = '20';
      this.expresionReg = null
    } else if (tipo === '3') {
      //NIT
      this.documentMask = "0*-0";
      this.sizeMin = '';
      this.sizeMax = '';
      this.expresionReg = new RegExp('^\\d+-\\d+$');
    } else if (tipo === '2') {
      //CEDULA EXTRANGERIA
      this.documentMask = "E0*";
      this.sizeMin = '7';
      this.sizeMax = '12';
      this.expresionReg = null;
    } else {
      this.documentMask = "0";
      this.expresionReg = null
    }
    this.registerForm.get('document').reset();
    documentControl.updateValueAndValidity();
    if (tipo === '2') {
      this.registerForm.get('document').setValue('E');
      documentControl.updateValueAndValidity();
     
    }
  }





  iniciarCuentaRegresiva() {
    this.botonDeshabilitado = true;
    this.tiempoRestante = 31;

    // Iniciar la cuenta regresiva
    this.intervalo = setInterval(() => {
      if (this.tiempoRestante > 0) {
        this.tiempoRestante--; // Reducir un segundo
        this.mensajeSuccess = `Código incorrecto. Por favor, inténtalo de nuevo en  <span class="tiempo-azul"> ${this.tiempoRestante}  </snap> segundos.`;
      } else {
        // Cuando llegue a 0, detener el intervalo
        clearInterval(this.intervalo);
        this.intervalo = null;
        this.isOtpValid = true;
        this.mensajeSuccess = '';
        this.botonDeshabilitado = false;
      }
    }, 1000); // Ejecutar cada segundo (1000 ms)
  }


}



