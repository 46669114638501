import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-folder',
  templateUrl: './folder.component.html',
  styleUrls: ['./folder.component.css']
})
export class FolderComponent implements OnInit {

  title:string = "Mis Documentos";
  activeDocument: Boolean = true;
  activeProcesing: Boolean = false;

  constructor() { }

  ngOnInit() {
  }


  changeMyDocuments(){
    this.title="Mis Documentos";
    this.activeDocument =  true;
    this.activeProcesing = false;
  }

 
}
