import { Component, OnInit } from '@angular/core';
/* import { AngularFireAuth } from '@angular/fire/auth'; */
import { Router } from '@angular/router';
import { FacebookService, LoginStatus } from 'ngx-facebook';
import { ActivatedRoute } from '@angular/router';
import { Consult } from '../../objects/consult';
import { RequestProcess } from '../../objects/request';
import { ProcesswebService } from '../../services/processweb.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { transition, animate, style, state, trigger, } from '@angular/animations';



/* SERVICIOS */
import { AuthService } from '../../services/auth.service';
import { HeaderStyleService } from '../../services/header-style';

@Component({
  selector: 'app-header',
  animations: [trigger('translateMenu', [
    state('collapse', style({
      marginRight: '0px',
    })),
    state('offCollapse', style({
      marginRight: '25px',
    })),
    transition('collapse <=> offCollapse', [
      animate('1s')
    ])
  ])
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],

})

export class HeaderComponent implements OnInit {
  //private afsAuth: AngularFireAuth,  param to constructor 
  constructor(private authService: AuthService, private router: Router, private fb: FacebookService, private _route: ActivatedRoute, private headerStyle: HeaderStyleService,
    private accessService: ProcesswebService) { }
  public isLogged: boolean = false;
  isCollapse = true;

  userLog: boolean;
  userName: string = "";
  headerFamilia: string = "";
  nombreCategoria: string = "";
  defaultStyle: boolean = true;
  header: number = 0;
  styleHeader: number = 1;
  category: string = '';
  categoryName: string = '';
  listaProductos: any;
  dataset: any;

  /* autocomplete */
  keyword: string = '';
  data: any;

  myControl = new FormControl();
  filteredOptions: Observable<string[]>;

  ngOnInit() {
    this.getCurrentUser();
    this.listarProductos();
    this.categoryName = sessionStorage.getItem('Category');

    switch (this.categoryName) {
      case 'EMPRESA':
        this.header = 1;
        this.styleHeader = 1;
        break;
      case 'LABORAL':
        this.header = 2;
        this.styleHeader = 1;
        break;
      case 'FAMILIA':
        this.header = 3;
        this.styleHeader = 1;
        break;
      default:
        this.header = 0;
        this.styleHeader = 0;
        break;
    }
    this.setStyleHeader(this.styleHeader);
  }

  getCurrentUser() {
    let logged = this.authService.isAuth()
    if (logged) {
      this.isLogged = true;
      const usuario = JSON.parse((sessionStorage.getItem('User')));
      this.userName = usuario.data.nombre;
    } else {
      this.isLogged = false;
    /*   this.getLoginStatus(); */
    }
  }

/*   getLoginStatus() {
    this.fb.getLoginStatus()
      .then((response: LoginStatus) => {
        if (response.status === 'connected') {
          this.isLogged = true;
        } else {
          this.isLogged = false;
        }
      });
  } */

  LogOut() {
    sessionStorage.removeItem('User');
    this.fb.logout().then(() => {
      this.isLogged = false;
      this.getCurrentUser();
    });
    this.isLogged = false;
    this.router.navigate(['home']);
    this.getCurrentUser();
  }

  setStyleHeader(_styleHeader) {
    switch (_styleHeader) {
      case 0:
        this.defaultStyle = true;
        break;
      case 1:
        this.defaultStyle = false;
    }
  }

  search() {
    this.router.navigate(['/producto/' + this.keyword]);
  }

  listarProductos() {
    try {
      this.listaProductos = new Array<any>();
      let consulta: Consult =
      {
        schema: 'producto',
        tabla: 'productos_v',
        campo: 'nombre, idproducto',
        condicion: 'estadoproducto = \'\'EN DESARROLLO\'\' order by nombre'
      }
      let peticion = new RequestProcess()
      peticion.proceso = '1'
      peticion.consulta = JSON.stringify(consulta)
      this.accessService.execProcess(peticion)
        .subscribe((res: any) => {
          if (res.codigo === '00') {
            let listproduct = JSON.parse(res.dataresponse)
            this.listaProductos = listproduct;
            this.data = this.dataset = listproduct;
            this.productsList(this.dataset);
          }
          else {
          
          }
        });
    } catch (error) {
    }

  }

  objectKeys(objeto: any) {
    const keys = Object.keys(objeto);
    return keys;
  }

  productsList(objeto: any) {
    for (let i = 0; i < objeto.length; i++) {
      this.dataset[i] = objeto[i].nombre;
    }
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filterFunc(value))
      );
  }

  filterFunc(value: string): string[] {
    const filterValue = value// .toLowerCase();
    this.keyword = value// .toLowerCase();
    return this.dataset.filter(option => option.toLowerCase().includes(filterValue));
  }

  toggleState() {
    let menuNav = this.isCollapse;
    this.isCollapse = menuNav === false ? true : false;
  }

}


