import { Component, OnInit, TemplateRef, Inject, HostListener, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ProcesswebService } from 'src/app/services/processweb.service';

import { AuthService } from 'src/app/services/auth.service';
import { Consult } from 'src/app/objects/consult';
import { RequestProcess } from 'src/app/objects/request';
import { FormControl } from '@angular/forms';
import { filter, map, startWith } from 'rxjs/operators';
import { SearchService } from 'src/app/services/search.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
})

export class SearchComponent implements OnInit {
  @Output() buttonClicked = new EventEmitter<string>();

  inputValue: string = '';


  info: string = '';

  listaProductos: any;
  data = []
  filteredSearch: any = [];
  myControl = new FormControl();
  public mensageAObservable: Observable<string>;
  public mensageBObservable: Observable<string>;

  nombreCapitulo = "";
  tipsLegales: any[] = [];
  products: any[] = [];
  legaguias: any[] = [];

  filteredLegaguias = [this.legaguias];
  filteredProducts = [this.products];
  filteredTipsLegales = [this.tipsLegales];


  displayedItems = 2;
  displayedItemsProducto = 2;
  displayedItemsLegatives = 2;


  noResultsLegaguias = false;
  noResultsProducts = false;
  noResultsTipsLegales = false;


  selectedTipIndexLegatives: number | null = null;
  atributoRecibido!: string;




  constructor(private authService: AuthService, private router: Router, private accessService: ProcesswebService, public searchService: SearchService, public ngxSmartModalService: NgxSmartModalService,) { }



  ngOnInit(): void {
    this.listarProductos();
   // console.log("Esta es mi opcion "+this.accessService.getToken());

    this.filteredLegaguias = [this.legaguias];
    this.filteredProducts = [this.products];
    this.filteredTipsLegales = [this.tipsLegales];
    this.search();
    //this.mensageAObservable = this.searchService.getMessageAObserbable();
    //this.mensageBObservable = this.searchService.getMessageBObserbable();
  }
  /***
   * Evenetos para abrir y cerrar wrapper del resultado de busqueda
   */
  searchProduct(event) {
    const inputElement = event.target as HTMLInputElement;
    this.inputValue = inputElement.value;
    if (!$('.cont-result-search').is(":visible")) {
      $('.cont-result-search').slideToggle('slow');
    }
    if (event.target.value == "") {
      $('.cont-result-search').attr("style", "display:none");
    }

    const normalizeText = (text: string) => {
      return text
        .normalize('NFD') // Descompone los caracteres con acento en dos partes (base + acento)
        .replace(/[\u0300-\u036f]/g, "") // Elimina los caracteres acentuados
        .toLowerCase(); // Convierte a minúsculas
    };

    // filtered options result from products
    this.filteredSearch = this.data.filter(option => 
      normalizeText(option.nombre).includes(normalizeText(event.target.value))
    );
    console.log(this.data, this.filteredSearch)
   
    //this.filteredSearch = this.data.filter(option => option.nombre.toLowerCase().includes(event.target.value.toLowerCase()));
  }

  closeSearch() {
    if ($('.cont-result-search').is(":visible"))
      $('.cont-result-search').slideToggle('slow');
  }
  onEnter(){
    this.search();
  }

  /**
   * Consulta a DB los productos activos para input de busqueda
   */
  listarProductos() {
    try {
      this.listaProductos = new Array<any>();
      let consulta: Consult =
      {
        schema: 'producto',
        tabla: 'productos_v',
        campo: 'idproducto, nombre, descripcion, categoria, idcategoria ',
        condicion: 'estadoproducto = \'\'EN DESARROLLO\'\' order by nombre'
      }
      let peticion = new RequestProcess()
      peticion.proceso = '1'
      peticion.consulta = JSON.stringify(consulta)
      this.accessService.execProcess(peticion)
        .subscribe((res: any) => {
          if (res.codigo === '00') {
            let listproduct = JSON.parse(res.dataresponse)
            this.listaProductos = listproduct;
            this.data = listproduct;
            this.productsList(this.data);
          }
          else {
           
          }
        });
    } catch (error) {
      console.log(error)
    }

  }

  productsList(objeto: any) {
    this.filteredSearch = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filterFunc(value))
      );
  }

  filterFunc(value: string): string[] {
    return this.data.filter(option => option.toLowerCase().includes(value));
  }

  /**
   * @param nombreProducto
   */
  redirectProduct(nombreProducto) {
    this.router.navigate(['/questions/' + nombreProducto])
    this.closeSearch()
  }
  search() {
    
    this.info = this.inputValue;
    this.searchQuestion(this.info);
    this.showTipsLegales();
  }



  selectTipLegatives(index: number) {
    this.selectedTipIndexLegatives = index;
  }


  showTipsLegales() {
    if (!this.tipsLegales.length) {
      this.consultQuestions(this.nombreCapitulo);
    } else {
      this.ngxSmartModalService.getModal('modalLegatips').open();
    }
  }
  close() {
    this.ngxSmartModalService.getModal('modalLegatips').close();
  }
  isSelectedLegatives(index: number): boolean {
    return this.selectedTipIndexLegatives === index;
  }


  consultQuestions(capitulo: string) {
    // select * from producto.superbusca_v
    try {
      this.tipsLegales = new Array<any>();
      const consulta: Consult = {
        schema: 'producto',
        tabla: 'superbusca_v',
        campo: '*',
        //condicion: capitulo!= "" ? 'capitulo = \'\'' + capitulo +'\'\'' : ''
      };
      const peticion = new RequestProcess();
      peticion.proceso = '1';
      peticion.consulta = JSON.stringify(consulta);
      this.accessService.execProcess(peticion)
        .subscribe((res: any) => {
          if (res.codigo === '00') {
            const listproduct = JSON.parse(res.dataresponse);
            this.tipsLegales = listproduct.filter(elem => elem.tipo == "legatips");
            this.legaguias = listproduct.filter(elem => elem.tipo == "legaguia");
            this.products = listproduct.filter(elem => elem.tipo == "productos");

            this.filteredLegaguias = [this.legaguias];
            this.filteredProducts = [this.products];
            this.filteredTipsLegales = [this.tipsLegales];

          /*   console.log(this.tipsLegales)
            console.log(this.legaguias)
            console.log(this.products) */
            // this.dataSource = new MatTableDataSource<TipLegal>(this.tipsLegales);
            // this.dataSource.paginator = this.paginator;
          } else {
            // this.dataSource = new MatTableDataSource<TipLegal>([]);
            // this.dataSource.paginator = this.paginator;
            // console.log(res);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }



  searchQuestion(filterValue: string) {
    //this.info = filterValue;

    const searchQuery = filterValue.toLowerCase();

    // Filtrar legaguias
    this.filteredLegaguias = this.legaguias.filter(tip => tip.nombre.toLowerCase().includes(searchQuery));
    this.noResultsLegaguias = this.filteredLegaguias.length === 0;

    // Filtrar productos
    this.filteredProducts = this.products.filter(tip => tip.nombre.toLowerCase().includes(searchQuery));
    this.noResultsProducts = this.filteredProducts.length === 0;

    // Filtrar tips legales
    this.filteredTipsLegales = this.tipsLegales.filter(tip => tip.nombre.toLowerCase().includes(searchQuery));
    this.noResultsTipsLegales = this.filteredTipsLegales.length === 0;

  }

  showMoreLegaguias() {
    this.displayedItems = (this.legaguias.length + 2); // Aumenta el número de elementos mostrados
  }
  showMoreProductos() {
    this.displayedItemsProducto = (this.products.length + 2); // Aumenta el número de elementos mostrados
  }
  showMoreLagatips() {
    this.displayedItemsLegatives = (this.tipsLegales.length + 2); // Aumenta el número de elementos mostrados
  }





  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }







}
