import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GruposComponent } from './grupos.component';
import { HeaderModule } from '../header/header.module';
import { MatCardModule } from '@angular/material/card';
import { FooterModule } from '../footer/footer.module';
import { MatTableModule } from '@angular/material';
import { MatPaginatorModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { NgxSmartModalModule,  NgxSmartModalService } from 'ngx-smart-modal';

/* ng-starrting para el rankingx */
import { RatingModule } from 'ng-starrating';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [GruposComponent],
  imports: [
    CommonModule,
    HeaderModule,
    MatCardModule,
    FooterModule,
    MatTableModule,
    MatPaginatorModule,
    RouterModule,
    RatingModule,
    NgxSmartModalModule,
    SharedModule
  ],
  providers: [ NgxSmartModalService ],
  exports: [
    MatPaginatorModule
  ]
})
export class GruposModule { }
