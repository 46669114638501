import { Injectable } from '@angular/core';
import { Consult } from '../objects/consult';
import { RequestProcess } from '../objects/request';
import { ProcesswebService } from './processweb.service';
import { MongoService } from './mongo.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Document } from '../objects/document';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Constants } from '../objects/constans';
import { Router } from '@angular/router';
import { getIsParent } from '@angular/core/src/render3/state';
import { SelectorListContext } from '@angular/compiler';
import { RequiredValidator } from '@angular/forms';
import { getMatTooltipInvalidPositionError } from '@angular/material';
/* import { ConsoleReporter } from 'jasmine'; */


@Injectable({
  providedIn: 'root'
})
export class TreeService {

  productText: any;
  productImage: any;
  productVideo: any;
  productAudio: any;

  areVideo = false;
  areSound = false;
  areImage = false;
  areText = false;
  viewVideo = false;
  viewSound = false;
  viewImage = false;
  viewText = false;
  vistasContenido = [];
  public idProducto = 0;
  public seccionInicial = 0;
  public nodoInicial = 0;
  public seccionBifurca = 0;

  private infonodo$ = new BehaviorSubject<number>(0);
  private contenido$ = new BehaviorSubject<any[]>([]);
  private contenidoNodo$ = new BehaviorSubject<any[]>([]);
  private informarcontenidonuevo$ = new BehaviorSubject<boolean>(false);
  private buttonPay$ = new BehaviorSubject<any>({});
  private idrespTrans$ = new BehaviorSubject<number>(4);
  private vistaPrev$ = new BehaviorSubject<number>(2);
  private dataProduct$ = new BehaviorSubject<any[]>([]);
  private savedAnswers = new BehaviorSubject<any[]>([]);
  private dataNavg$ = new BehaviorSubject<any[]>([]);
  private helpNode$ = new BehaviorSubject<boolean>(false);

  private valorProd$ = new BehaviorSubject<any>(null);
  public btnStatusPay$ = new BehaviorSubject<boolean>(true);



  private messageErrorCreationProduct$ =new BehaviorSubject<string>('');
private activaBottonFinalizar$ = new BehaviorSubject<boolean>(false);



  public accion = 1;
  public repeticion = 0;
  public comopregunto: any;
  public quepregunto: any;
  public nombrenodo: any;
  public opciones: any;
  public comomuestro = '';
  private nodosiguiente = 0;
  private seccionanterior = 0;
  public tiponodo = 0;
  private tiponodoAnterior = 0;
  private codigomongo;
  private nombreProducto = '';
  private idUsuario = 0;
  public nodoAnterior = 0;
  private nodoConsultContent = 0;
  public variables = {};
  public indexRepeat = 0;
  public mostrarPago = false;
  public mostrarVista = false;
  public isClon = 0;
  public nodoClonado = 0;
  public tipoNodoContinuar = 0;
  public infoNodo$ = () => this.infonodo$.asObservable();
  public contenidoSeccion$ = () => this.contenido$.asObservable();
  public _contenidoNodo$ = () => this.contenidoNodo$.asObservable();
  public contenidoNuevo$ = () => this.informarcontenidonuevo$.asObservable();
  public botonPago$ = () => this.buttonPay$.asObservable();
  public vistaPrevia$ = () => this.vistaPrev$.asObservable();
  public dataNav$ = () => this.dataNavg$.asObservable();
  public infoProduct$ = () => this.dataProduct$.asObservable();
  public dataAnswers$ = () => this.savedAnswers.asObservable();
  public showHelpNodes$ = () => this.helpNode$.asObservable();
  public valorPago$ = () => this.valorProd$.asObservable();
  public botonStatusPago$ = () => this.btnStatusPay$.asObservable();
  public idrespTransObs$ = () => this.idrespTrans$.asObservable();
  public messageErrorCreationObs$ = () => this.messageErrorCreationProduct$.asObservable();
  public activarBotonFinalizarObs$ = () => this.activaBottonFinalizar$.asObservable();
  public idCapitulo: string = '';
  public valorPago: number = 0;
  public statusPay: boolean = true;
  public id_section: string = '';


  private bottonFinalizar = new BehaviorSubject<boolean>(false);
  public bottonFinalizarObs$  = () => this.bottonFinalizar.asObservable();
  



  private constantes = new Constants();

  private nombre_product$ = new BehaviorSubject<string>('');
  public nameProd$ = () => this.nombre_product$.asObservable();


  public idNode$ = new BehaviorSubject<number>(0);
  public idNodo: number = 0;

  nombre_producto: string;
  tipo_producto: number;


  // tslint:disable-next-line:max-line-length
  constructor(private accessService: ProcesswebService, private mongoService: MongoService, private http: HttpClient, private sanitizer: DomSanitizer, private router: Router) { 
  }
  /**
   * consulta informacion del producto a db
   *  @param nombreProducto
   */
  getIdProducto(nombreProducto: string) {
    return new Promise((resolve, reject) => {
      const consulta: Consult = {
        schema: 'producto',
        tabla: 'productos_v',
        campo: 'categoria, idcategoria, grupo, idgrupo, nombre, descripcion, idproducto, nombrecapitulo, idcapitulo,idestadopublicacion',
        condicion: 'nombre = \'\'' + nombreProducto + '\'\''
      };
      const peticion = new RequestProcess();
      peticion.proceso = '1';
      peticion.consulta = JSON.stringify(consulta);
      this.accessService.execProcess(peticion)
        .subscribe((res: any) => {
         
          if (res.codigo === '00') {
            if (JSON.parse(res.dataresponse)[0].idestadopublicacion === 3) {
              this.idProducto = JSON.parse(res.dataresponse)[0].idproducto;
              this.nombreProducto = nombreProducto;
              this.idCapitulo = JSON.parse(res.dataresponse)[0].idcapitulo;
              this.dataProduct$.next(JSON.parse(res.dataresponse)[0]);
              resolve(this.idProducto);
            } else {
              this.router.navigate(['/no-product']);
            }
          } else {
            console.error('error consultando idProducto, ', res.mensaje);
            reject(res.mensaje)
          }
        });
    });
  }


  getIdCapitulo(): string {
    return this.idCapitulo;
  }

  /**
   * consulta la seccion inicial del producto
   */
  public consultSection() {
    return new Promise((resolve, rej) => {
      const consulta: Consult = {
        schema: 'arbol',
        tabla: 'secciones',
        campo: 'idseccion',
        condicion: ' idproducto = ' + this.idProducto + ' and idestadoseccion = 1 order by orden '
      };
      const peticion = new RequestProcess();
      peticion.proceso = '1';
      peticion.consulta = JSON.stringify(consulta);
     
      this.accessService.execProcess(peticion)
        .subscribe((res: any) => {
          if (res.codigo === '00') {
            this.seccionInicial = JSON.parse(res.dataresponse)[0].idseccion;
            resolve(this.seccionInicial);
          } else {
            console.error('error al consultar sección inicial, ', res.mensaje);
            rej('error al consultar sección inicial, ' + res.mensaje);
          }
        });
    });
  }

  /**
   * Consulta si existe el nodo en logica DB, si no es así crea el documento en mongo
   */
  public consultWalk(idusuario: any) {
    this.idUsuario = idusuario;
    // conulta a DB la logica del  arbol (nodos recorridos) que actualmente el usuario tiene en este producto
    if (this.idProducto != 0) {
      this.consultgeneral('arbol', 'secciones', '*', 'idproducto = ' + this.idProducto).then((res: any) => {
        const data = JSON.parse(res)[0];
        this.seccionInicial = data.idseccion;

        this.consultgeneral('arbol', 'usuarioproducto_v', '*', 'idusuario = ' + idusuario + ' and idproducto = ' + this.idProducto + ' and nombre_producto = \'\'' + this.nombre_producto + '\'\'')
          .then((res2: any) => {
            this.nombre_product$.next(this.nombre_producto)
            const datosNodo = JSON.parse(res2)[0];
            this.idNodo = datosNodo.idnodo;
            this.tiponodo = datosNodo.idtiponodo;
            this.seccionInicial = datosNodo.idseccion;
            this.nodoInicial = datosNodo.idnodo;
            this.codigomongo = datosNodo.codigomongo;
            this.comomuestro = datosNodo.comomuestro ? datosNodo.comomuestro.replace(/\++\+/g, '"').replace(/\**\*/g, '\'') : '';
            this.quepregunto = datosNodo.quepregunto;
            this.nombrenodo = datosNodo.nombre;
            this.comopregunto = datosNodo.comopregunto;
            this.opciones = datosNodo.opciones;
            this.tipo_producto = datosNodo.idtipoproducto;
            //this.accion =  1;// #####  REVISAR ESTADO DE ACCION PARA NAVEGACION ATRAS
            this.repeticion = datosNodo.repeticion;
            if (datosNodo.nodoinformativo != 0) {
              this.showHelpNode(datosNodo.nodoinformativo);
            }
            else {
              this.helpNode$.next(false);
            }
            // LLenar información para tipo node seccion-Bif
            if (datosNodo.bifurca) {
              this.tiponodo = datosNodo.bifurca == 1 ? 9 : datosNodo.idtiponodo // select Bifurca seccion
              this.opciones = datosNodo.bifurca == 1 ? datosNodo.datosbifurca : datosNodo.opciones;
              this.repeticion = datosNodo.repeticion;
            }
            // Se llama la vista del nodo actual
            datosNodo.bifurca && this.infonodo$.next(10);
            if ((this.repeticion === 2 || this.repeticion === 3) && this.tiponodo === 3) {
              this.infonodo$.next(6); // formulario repetitivo
            } else if ((this.repeticion === 2 || this.repeticion === 3) && this.tiponodo === 1) {
              this.infonodo$.next(7); //pregunta cerrada repetitivo
            } else if (this.tiponodo == 5 && this.tipo_producto == 3) {
              this.showHelpNode(datosNodo.idnodo);
              this.infonodo$.next(this.tiponodo);
            } else {
              this.infonodo$.next(this.tiponodo);
            }
            this.consultPayment(this.seccionInicial);

          }).catch(
            err => {
              console.log(err, err.mensaje);
              if (err === 'Consulta sin resultados') {
                this.helpNode$.next(false);




                this.nombre_producto == "" && this.infonodo$.next(11);
              }
            }
          );
      });
    }


  }
  /**MEtodo para consulta en db
   * 
   * @param sch 
   * @param tab 
   * @param camp 
   * @param cond 
   * @returns 
   */
  private consultgeneral(sch, tab, camp, cond?) {

    return new Promise((resolve, rej) => {
      const consulta: Consult = {
        schema: sch,
        tabla: tab,
        campo: camp,
        condicion: cond
      };
      const peticion = new RequestProcess();
      peticion.proceso = '1';
      peticion.consulta = JSON.stringify(consulta);
      this.accessService.execProcess(peticion)
        .subscribe((res: any) => {
          if (res.codigo === '00') {
            /*  this.respuestageneral = res.dataresponse; */
            resolve(res.dataresponse);
          } else {
            /* this.respuestageneral = null; */
            rej(res.mensaje);
          }
        });
    });
  }

  /**
   * Se guardan varibales del producto en el servicio
   * @param variables 
   */
  public setVariables(variables) {
    //this.variables = (variables.preguntas !== undefined) ? variables.preguntas : variables[0].preguntas;
    this.variables = variables
  }

  public setCodigoMongo(codigomongo) {
    this.codigomongo = codigomongo;
  }

  /**
   *   Envia informacion a DB sobre la navegacion del producto
   * @action => 1 adelante, 2 atras
   * @idNodoSiguiente se usa si es tipo bifurcación
   * @idNodoInicial => nodo actual en el árbol
   * @idTipoNodo => 1 pregunta cerrada, 2 bifurcacion, 3 formulario, 4 informativo
   * @idSeccion => seccion actual
   * @codigoMongo => código entregado al hacer put en mongo
   */
  // tslint:disable-next-line:max-line-length
  public processFlow(action, idNodoSiguiente = this.nodosiguiente, idNodoInicial = this.nodoInicial, idTipoNodo = this.tiponodo, idSeccion = this.seccionInicial, codigoMongo = this.codigomongo) {
    return new Promise((resolve, reject) => {
      const usuario = JSON.parse((sessionStorage.getItem('User')));
      this.seccionanterior = this.seccionInicial;
      this.tiponodoAnterior = this.tiponodo;
      const consulta = {
        ip: null,
        idusuario: usuario.data.idusuario,
        idseccion: idSeccion,
        idproducto: this.idProducto,
        idnodosiguiente: idNodoSiguiente,
        idtiponodo: idTipoNodo,
        idnodoinicial: idNodoInicial,
        accion: action,
        codigomongo: codigoMongo,
        nombre_producto: this.nombre_producto
      };
      const peticion = new RequestProcess();
      peticion.proceso = '19';
      peticion.consulta = JSON.stringify(consulta);

      this.accessService.execProcess(peticion)
        .subscribe(res => {
          if (res.codigo === '00') {
            this.accion = action;
            const info = JSON.parse(res.dataresponse);

            switch(info.nodofinal){
                case 0 :
                  this.activaBottonFinalizar$.next(true);
              break;
                case 1:
                  this.activaBottonFinalizar$.next(false);
              break;
            }
            
            this.nodoConsultContent = info.idnodosiguiente;
            this.seccionBifurca = info.seccionbifurca ? info.seccionbifurca : 0;
            if (info.idnodosiguiente != null) {
              this.isClon = info.clonado;
              this.nodoClonado = info.nodoclonado;
              this.seccionInicial = info.idseccion;

              if (info.nodoinformativo != 0) {
                this.showHelpNode(info.nodoinformativo);
              }
              else {
                this.helpNode$.next(false);
              }
              if (info.bifurca) {

                this.tiponodo = info.bifurca == 1 ? 9 : info.idtiponodo // select Bifurca seccion
                this.nodoInicial = info.idnodosiguiente;
                // this.comopregunto =  info.comopregunto;
                // this.quepregunto = info.quepregunto;
                // this.nombrenodo = info.nombre;
                this.opciones = info.bifurca == 1 ? info.datosbifurca : info.opciones;
                // this.comomuestro = info.comomuestro ? info.comomuestro.replace(/\++\+/g, '"').replace(/\**\*/g, '\'') : '';
                // const contentNew = (this.seccionanterior !== this.seccionInicial) ? true : false;
                // this.informarcontenidonuevo$.next(contentNew);
                this.repeticion = info.repeticion;
                 this.consultPayment(this.seccionInicial) 
              }
              else {

                this.tiponodo = info.idtiponodo;
                this.nodoInicial = info.idnodosiguiente;
                this.comopregunto = info.comopregunto;
                this.quepregunto = info.quepregunto;
                this.nombrenodo = info.nombre;
                this.opciones = info.opciones;
                this.comomuestro = info.comomuestro ? info.comomuestro.replace(/\++\+/g, '"').replace(/\**\*/g, '\'') : '';
                //const contentNew = (this.seccionanterior !== this.seccionInicial) ? true : false;
                //this.informarcontenidonuevo$.next(contentNew);
                this.repeticion = info.repeticion;
                // !this.mostrarPago && this.consultPayment(this.seccionInicial);
                this.consultPayment(this.seccionInicial);
              }
            }
            else {
              // this.tiponodo = 0;
              // this.nodoInicial = 0;
              // this.infonodo$.next(0);
              reject("No hay más información para completar el producto")
            }
            resolve(res.dataresponse);
          } else {
           
            if (res.mensaje === 'El producto a terminado' || res.mensaje === 'Problemas en la seccion siguiente') {
              if (this.tipo_producto == 3) {
                this.helpNode$.next(false);
                this.tiponodo = 13; // tiponodo = 13 -> lista de productos para guía
                this.infonodo$.next(this.tiponodo);
                this.bottonFinalizar.next(true);
              }
              else
                this.tiponodo = 8;
              // setiar valores en 0 para prevenir reenvio a processFlow
              this.isClon = 0;
              this.nodoClonado = 0;
              resolve(res.mensaje);
            } else {
              this.tiponodo = 0;
              this.nodoInicial = 0;
              this.infonodo$.next(0);
              reject('Error al consultar lógica del árbol, ' + res.mensaje);
            }
          }
        },
          error => console.log(error)
        );

    });
  }

  /**
   * Envio de informacion sobre navegacion producto a Mongo
   * @accion
   * @opcionelegida
   */
  public setRouteMongo(accion: number, opcionelegida?: number) {
    this.accion = accion;
    this.infonodo$.next(10);
    // accion 2 elimina informacion
    if (accion === 2) {
      this.indexRepeat = 0;
      return new Promise((resp, rej) => {
        this.tiponodo = this.tiponodo == 9 ? 2 : this.tiponodo;
        this.processFlow(accion, opcionelegida, this.nodoInicial, this.tiponodo, this.seccionInicial, this.codigomongo).then(res => {
          //this.mongoService.deleteRouteSubDoc('route/' + this.codigomongo + '/' + this.nodoInicial).then(res => {
          // this.processFlow(accion, opcionelegida, this.nodoInicial, this.tiponodo, this.seccionInicial, this.codigomongo)
          if (this.seccionBifurca != 1) {
            this.mongoService.deleteRouteSubDoc('route/' + this.codigomongo + '/' + this.nodoInicial)
              .then((res) => {
                resp(res);
              })
              .catch(err => rej(err));
          }

          if (this.isClon == 1) {
            this.infonodo$.next(12); // Clonacion
          }
          else if ((this.repeticion === 2 || this.repeticion === 3) && this.tiponodo === 3) {
            this.infonodo$.next(6);
          } else if ((this.repeticion === 2 || this.repeticion === 3) && this.tiponodo === 1) {
            this.infonodo$.next(7);
          } else if (this.tiponodo == 5) {
            this.consultWalk(this.idUsuario);
          } else {
            this.infonodo$.next(this.tiponodo);
          }
          this.consultNode().then(
            (respuesta: any) => {
              if (respuesta.variables) {
                this.savedAnswers.next(respuesta.variables)
              }

            })

        });

      });
      /* this.mongoService.deleteRouteSubDoc('route/' + this.codigomongo + '/' + this.nodoInicial).then(res => {
        console.log(accion, opcionelegida, this.nodoInicial, this.seccionInicial, this.codigomongo);
        this.processFlow(accion, opcionelegida, this.nodoInicial, this.tiponodo, this.seccionInicial, this.codigomongo)
          .then(() => {
            resp(res);
            console.log(this.repeticion, this.tiponodo, res);
            if ((this.repeticion === 2 || this.repeticion === 3) && this.tiponodo === 3) {
              this.infonodo$.next(6);
            } else if ((this.repeticion === 2 || this.repeticion === 3) && this.tiponodo === 1) {
              this.infonodo$.next(7);
            } else if( this.tiponodo == 5) {
              this.consultWalk(this.idUsuario);
            } else {
              this.infonodo$.next(this.tiponodo);
            }
          })
          .catch(err=> rej(err));
      });
    }); */
    } else {
      const datos = {
        id_nodo: this.nodoInicial,
        tipo_nodo: this.tiponodo,
        html: this.comomuestro.replace(/\++\+/g, '"').replace(/\**\*/g, '\''),
        variables: this.variables,
        clon: this.isClon,
        nodoclonado: this.nodoClonado
      };
      return new Promise((resp) => {
        this.mongoService.updateDocument('route/' + this.codigomongo, datos)
          .then(res => {
            this.processFlow(accion, opcionelegida, datos.id_nodo, datos.tipo_nodo, this.seccionInicial, this.codigomongo)
              .then(() => {
                if (this.isClon == 1) {
                  this.infonodo$.next(12); // Clonacion
                }
                else if ((this.repeticion === 2 || this.repeticion === 3) && this.tiponodo === 3) { // Formulario
                  this.infonodo$.next(6); // Se informa a la vista que debe mostrar formulario  repetitivo
                } else if ((this.repeticion === 2 || this.repeticion === 3) && this.tiponodo === 1) {// PregutnaCerrada
                  this.infonodo$.next(7);
                }
                else if (this.tiponodo == 5) { // Informativo
                  // this.consultWalk(this.idUsuario);
                  this.consultContentNodeInformativo();
                }
                else {
                  if (this.tiponodo == 9) { // bifurca
                    this.infonodo$.next(this.tiponodo);
                    this.tiponodo = this.tiponodoAnterior
                  } else
                    this.infonodo$.next(this.tiponodo);
                }
                resp(res);
              })
              .catch(error => { console.log("Error al guardar información de navegación, ", error) });;
          })
          .catch(error => { console.log("Error al guardar información, ", error) });
      });
    }
  }

  public navegacionAdelante(opcionelegida?: number) {

    this.accion = 1;
    this.infonodo$.next(10);

    const datos = {
      id_nodo: this.nodoInicial,
      tipo_nodo: this.tiponodo,
      html: this.comomuestro.replace(/\++\+/g, '"').replace(/\**\*/g, '\''),
      variables: this.variables,
      clon: this.isClon,
      nodoclonado: this.nodoClonado
    };
    this.tipoNodoContinuar = 0;
    return new Promise((resp) => {
      this.mongoService.updateDocument('route/' + this.codigomongo, datos)
        .then(res => {
          this.processFlow(1, opcionelegida, datos.id_nodo, datos.tipo_nodo, this.seccionInicial, this.codigomongo)
            .then(() => {
              if (this.isClon == 1) {
                this.tipoNodoContinuar = 12; // Clonacion
              }
              else if ((this.repeticion === 2 || this.repeticion === 3) && this.tiponodo === 3) { // Formulario
                this.tipoNodoContinuar = 6; // Se informa a la vista que debe mostrar formulario  repetitivo
              } else if ((this.repeticion === 2 || this.repeticion === 3) && this.tiponodo === 1) {// PregutnaCerrada
                this.tipoNodoContinuar = 7;
              }
              else if (this.tiponodo == 5) { // Informativo
                // this.consultWalk(this.idUsuario);
                this.consultContentNodeInformativo();
              }
              else {
                if (this.tiponodo == 9) { // bifurca
                  this.tipoNodoContinuar = this.tiponodo;
                  this.tiponodo = this.tiponodoAnterior
                }
                else
                  this.tipoNodoContinuar = this.tiponodo;
              }
              resp(this.tipoNodoContinuar);
            })
            .catch(error => { console.log("Error al guardar información de navegación, ", error) });;
        })
        .catch(error => { console.log("Error al guardar información, ", error) });
    });
  }

  public navegacionRegresar(opcionelegida?: number) {
    this.indexRepeat = 0;
    this.accion = 2;
    this.infonodo$.next(10);
    return new Promise((resp, rej) => {
      this.tiponodo = this.tiponodo == 9 ? 2 : this.tiponodo;
      this.processFlow(2, opcionelegida, this.nodoInicial, this.tiponodo, this.seccionInicial, this.codigomongo)
        .then(res => {
          if (this.seccionBifurca != 1) {
            this.mongoService.deleteRouteSubDoc('route/' + this.codigomongo + '/' + this.nodoInicial)
              .then((res) => {
                resp(0);
              })
              .catch(err => rej(err));
          }

          if (this.isClon == 1) {
            this.tipoNodoContinuar = 12; // Clonacion
          }
          else if ((this.repeticion === 2 || this.repeticion === 3) && this.tiponodo === 3) {
            this.tipoNodoContinuar = 6;
          } else if ((this.repeticion === 2 || this.repeticion === 3) && this.tiponodo === 1) {
            this.tipoNodoContinuar = 7;
          } else if (this.tiponodo == 5) {
            this.consultWalk(this.idUsuario);
          } else {
            this.tipoNodoContinuar = this.tiponodo;
          }
          this.consultNode().then(
            (respuesta: any) => {
              if (respuesta.variables) {
                this.savedAnswers.next(respuesta.variables);
              }
            });
          resp(this.tipoNodoContinuar);
        });

    });
  }

  public continuarNavegacion(tipoNodo: number) {
    this.infonodo$.next(tipoNodo);
  }



  /**
   * Funcion para mandar infromación de nodo clonado a mongo.
   * @param accion retocede 2 - avanza 1
   * @returns 
   */
  public setRouteMongoClon(accion: number, opcionelegida?: number) {
    this.accion = accion;
    this.infonodo$.next(10);
    // accion 2 elimina informacion
    if (accion === 2) {
      this.indexRepeat = 0;
      return new Promise((resp, rej) => {
        this.mongoService.deleteRouteSubDoc('route/' + this.codigomongo + '/' + this.nodoInicial).then(res => {
          this.processFlow(accion, opcionelegida, this.nodoInicial, this.tiponodo, this.seccionInicial, this.codigomongo)
            .then((res) => {
              resp(res);
              if ((this.repeticion === 2 || this.repeticion === 3) && this.tiponodo === 3) {
                this.infonodo$.next(6);
              } else if ((this.repeticion === 2 || this.repeticion === 3) && this.tiponodo === 1) {
                this.infonodo$.next(7);
              } else if (this.tiponodo == 5) {
                this.consultWalk(this.idUsuario);
              } else {
                this.infonodo$.next(this.tiponodo);
              }
              this.consultNode().then(
                (respuesta: any) => {
                  if (respuesta.variables) {
                    this.savedAnswers.next(respuesta.variables)
                  }

                })
            })
            .catch(err => rej(err));
        });
      });
    } else {
      const datos = {
        id_nodo: this.nodoInicial,
        tipo_nodo: this.tiponodo,
        html: this.comomuestro.replace(/\++\+/g, '"').replace(/\**\*/g, '\''),
        variables: this.variables,
        clon: this.isClon,
        nodoclonado: this.nodoClonado,
        preguntas: this.comopregunto,
        repeticion: this.repeticion,
      };
      return new Promise((resp) => {
        this.mongoService.updateDocument('route/clon/' + this.codigomongo, datos)
          .then(res => {
            this.processFlow(accion, opcionelegida, datos.id_nodo, datos.tipo_nodo, this.seccionInicial, this.codigomongo)
              .then(() => {
                if (this.isClon == 1) {
                  this.infonodo$.next(12); // Clonacion
                }
                else if ((this.repeticion === 2 || this.repeticion === 3) && this.tiponodo === 3) { // Formulario
                  this.infonodo$.next(6); // Se informa a la vista que debe mostrar formulario  repetitivo
                } else if ((this.repeticion === 2 || this.repeticion === 3) && this.tiponodo === 1) {// PregutnaCerrada
                  this.infonodo$.next(7);
                }
                else if (this.tiponodo == 5) { // Informativo
                  //this.consultWalk(this.idUsuario);
                  this.consultContentNodeInformativo();
                }
                else {
                  if (this.tiponodo == 9) { // bifurca
                    this.infonodo$.next(this.tiponodo);
                    this.tiponodo = this.tiponodoAnterior
                  } else
                    this.infonodo$.next(this.tiponodo);
                }
                resp(res);
              })
              .catch(error => { console.log("Error al guardar información de navegación, ", error) });

          })
          .catch(error => { console.log("Error al guardar información, ", error) });
      });
    }
  }

  public setPDFMongo(tipoaccion = 'Crear', tipoDoc: string = "pdf", correo?: string, usuario?: string) {
    const datos: any = {
      codigomongo: this.codigomongo,
      tipoDoc,
      tipoaccion
    };
    if (correo !== undefined && usuario !== undefined) {
      datos.email = correo;
      datos.username = usuario;
    }
    return new Promise((resp) => {
      this.mongoService.consultPDF(datos).then(val => {
        resp(val);
      });
    });
  }

  public resetVariables() {
    return new Promise((respuesta) => {
      this.tiponodo = 0;
      this.nodoInicial = 0;
      this.comomuestro = '';
      this.comopregunto = 0;
      this.opciones = '';
      this.accion = 1;
      this.infonodo$.next(0);
      respuesta(1);
    });
  }

  consultContentSection() {
    const consultaContenido: Consult = {
      schema: 'arbol',
      tabla: 'secciones_v',
      campo: 'nombre, contenido',
      // tslint:disable-next-line:max-line-length
      condicion: 'idseccion  = ' + this.seccionInicial
    };
    const peticionContenido = new RequestProcess();
    peticionContenido.proceso = '1';
    peticionContenido.consulta = JSON.stringify(consultaContenido);
    this.accessService.execProcess(peticionContenido)
      .subscribe((resp: any) => {
        if (resp.codigo === '00') {/* 
          this.consultPayment(this.seccionInicial) ; */
          const dataContent = JSON.parse(resp.dataresponse)[0].contenido;

          if (dataContent != null) {
            /* video */
            // tslint:disable-next-line:max-line-length
            if (dataContent[0]) {
              if (dataContent[0].contenido === null || dataContent[0].contenido === undefined || dataContent[0].contenido === '') {
                { }
                this.areVideo = false;
              } else {
                this.areVideo = true;
                // tslint:disable-next-line:max-line-length
                //this.productVideo = this.sanitizer.bypassSecurityTrustResourceUrl(dataContent[0].contenido.replace('watch?v=', 'embed/'));
                this.productVideo = dataContent[0].contenido;
              }

            }

            /* Audio */
            // tslint:disable-next-line:max-line-length
            if (dataContent[1]) {
              if (dataContent[1].contenido === null || dataContent[1].contenido === undefined || dataContent[1].contenido === '') {
                this.areSound = false;
              } else {
                this.areSound = true;
                this.productAudio = dataContent[1].contenido;
                this.productAudio = this.constantes.getConectNode() + 'uploadaudio/seccion_' + this.seccionInicial + '_' + this.productAudio;
                /* this.http.get(this.constantes.getConectNode() + 'uploadaudio/seccion_' + this.seccionInicial + '_' + this.productAudio, {
                  responseType: 'blob'
                }).subscribe(
                  (val) => {
                    this.productAudio = val;
                  },
                  response => {
                    console.log('POST call in error', response);
                  },
                  () => {
                    console.log('The POST observable is now completed.');
                  }
                ); */
              }
            }

            /* imagen */
            // tslint:disable-next-line:max-line-length
            if (dataContent[2]) {
              if (dataContent[2].contenido === null || dataContent[2].contenido === undefined || dataContent[2].contenido === '') {
                this.areImage = false;
              } else {
                this.areImage = true;
                this.productImage = dataContent[2].contenido;
              }
            }

            /* Texto */
            // tslint:disable-next-line:max-line-length
            if (dataContent[3]) {
              if (dataContent[3].contenido === null || dataContent[3].contenido === undefined || dataContent[3].contenido === '') {
                this.areText = false;
                this.productText = "";
              } else {
                this.areText = true;
                this.productText = dataContent[3].contenido;
              }
            }
            this.vistasContenido[0] = this.areVideo;
            this.vistasContenido[1] = this.areSound;
            this.vistasContenido[2] = this.areImage;
            this.vistasContenido[3] = this.areText;
            this.contenido$.next(this.vistasContenido);

          }

        } else {
          console.log(resp);
        }
      });
  }

  /**
   * Consultan contenido de nodo informatico
   * @returns @contentNodeInformativo objeto con la información de contenido
   */
  consultContentNodeInformativo() {
    // conulta a DB la logica del  arbol (nodos recorridos) que actualmente el usuario tiene en este producto
    if (this.idProducto != 0) {
      this.consultgeneral('arbol', 'nodos_v', '*', 'idnodo = ' + this.nodoConsultContent + ' and estadonodo = 1 ')
        .then((response: any) => {
          const datosNodo = JSON.parse(response)[0];
          this.tiponodo = datosNodo.idtiponodo;
          this.seccionInicial = datosNodo.idseccion;
          this.nodoInicial = datosNodo.idnodo;
          //this.codigomongo = datosNodo.codigomongo;
          this.comomuestro = datosNodo.comomuestro ? datosNodo.comomuestro.replace(/\++\+/g, '"').replace(/\**\*/g, '\'') : '';
          this.quepregunto = datosNodo.quepregunto;
          this.nombrenodo = datosNodo.nombre;
          this.comopregunto = datosNodo.comopregunto;
          this.opciones = datosNodo.opciones;
          this.accion = 1;
          this.repeticion = datosNodo.repeticion;
          if (this.tiponodo == 5) {
            const contentNode = JSON.parse(response).map(element => {
              return {
                idtipocontenido: element.idtipocontenido,
                contenido: element.contenido,
                idestadocontenido: element.idestadocontenido,
                ruta: element.ruta,
                idnodo: element.idnodo
              }
            });
            this.contenidoNodo$.next(contentNode);
            this.tipoNodoContinuar = this.tiponodo;  // this.infonodo$.next(this.tiponodo);
          }
          // !this.mostrarPago && this.consultPayment(this.seccionInicial);
          this.consultPayment(this.seccionInicial);
        }).catch(
          err => {
            console.log(err, err.mensaje);
            if (err === 'Consulta sin resultados') {
              // llamar vista para dar nombre al producto
              // this.infonodo$.next(11);
            }
          }
        );

    }
  }

  /**
   *  Envío información del nodo repetitivo al mongoDb
   * @param accion
   * @param opcionelegida
   * @returns
   */
  setRepeatNode() {
    const datos = {
      id_nodo: this.nodoInicial,
      tipo_nodo: this.tiponodo,
      html: this.comomuestro.replace(/\++\+/g, '"').replace(/\**\*/g, '\''),
      variables: this.variables,
      preguntas: this.quepregunto,
      repeticion: this.repeticion,
      indexRepeticion: this.indexRepeat++
    };
    return new Promise((response, reject) => {

      //this.mongoService.updateDocument('/route/multianswer/' + this.codigomongo, datos)
      this.mongoService.updateDocument('route/repeat/' + this.codigomongo, datos)
        .then(res => {
        })
        .catch(error => console.log(error))
    })
  }

  /**
   * Envio de informacion sobre navegacion nodo repeticion a Mongo
   * @accion
   * @opcionelegida
   */
  public setRepeatNodeMongo(accion: number, opcionelegida?: number) {
    // this.accion = accion;
    this.infonodo$.next(10);
    switch (accion) {
      // Agregar contenido a mongo (nodo repeticion)
      case 1:
        {
          const datos = {
            id_nodo: this.nodoInicial,
            tipo_nodo: this.tiponodo,
            html: this.comomuestro.replace(/\++\+/g, '"').replace(/\**\*/g, '\''),
            variables: this.variables,
            preguntas: this.quepregunto,
            repeticion: this.repeticion,
            indexRepeticion: this.indexRepeat++
          };
          return new Promise((resp) => {
            this.mongoService.updateDocument('route/repeat/' + this.codigomongo, datos).then(res => {
              if ((this.repeticion === 2 || this.repeticion === 3) && this.tiponodo === 3) { // formulario
                this.infonodo$.next(6);
              } else if ((this.repeticion === 2 || this.repeticion === 3) && this.tiponodo === 1) { // cerrada
                this.infonodo$.next(7);
              } else if (this.tiponodo == 5) {
                this.consultWalk(this.idUsuario);
              } else {
                this.processFlow(accion, opcionelegida, datos.id_nodo, datos.tipo_nodo, this.seccionInicial, this.codigomongo)
                  .then(() => {
                    if (this.tiponodo == 9) { // bifurca
                      this.infonodo$.next(this.tiponodo);
                      this.tiponodo = this.tiponodoAnterior;
                    } else
                      this.infonodo$.next(this.tiponodo);
                  });
              }
              resp(res);
            });
          });
          break;
        }
      // Envio información siguiente a postgres, sin enviar info a mongo
      case 3:
        {
          const datos = {
            id_nodo: this.nodoInicial,
            tipo_nodo: this.tiponodo,
            html: this.comomuestro.replace(/\++\+/g, '"').replace(/\**\*/g, '\''),
            variables: this.variables
          };
          this.indexRepeat = 0;
          return new Promise((resp) => {
            this.processFlow(1, opcionelegida, datos.id_nodo, datos.tipo_nodo, this.seccionInicial, this.codigomongo)
              .then(() => {
                if (this.isClon == 1) {
                  this.infonodo$.next(12); // Clonacion
                }
                else if ((this.repeticion === 2 || this.repeticion === 3) && this.tiponodo === 3) {
                  this.infonodo$.next(6);
                }
                else if ((this.repeticion === 2 || this.repeticion === 3) && this.tiponodo === 1) {
                  this.infonodo$.next(7);
                }
                else if (this.tiponodo == 5) {
                  this.consultWalk(this.idUsuario);
                }
                else if (this.tiponodo == 9) { // bifurca
                  this.infonodo$.next(this.tiponodo);
                  this.tiponodo = this.tiponodoAnterior
                }
                else {
                  this.infonodo$.next(this.tiponodo);
                }
                resp(resp);
              });
          });
          break;
        }
      // Eliminar en mongo info en nodo repetitivo
      case 2:
        {
          return new Promise((resp) => {
            this.mongoService.deleteRouteSubDoc('route/' + this.codigomongo + '/' + this.nodoInicial).then(res => {
              this.indexRepeat--;
              if (this.indexRepeat <= 0) {
                this.indexRepeat = 0;
                if ((this.repeticion === 2 || this.repeticion === 3) && this.tiponodo === 3) {
                  this.infonodo$.next(6);
                  resp(1);
                } else if ((this.repeticion === 2 || this.repeticion === 3) && this.tiponodo === 1) {
                  this.infonodo$.next(7);
                  resp(1);
                } else if (this.tiponodo == 5) {
                  this.consultWalk(this.idUsuario);
                } else {
                  //this.consultWalk(this.idUsuario);
                  //this.tiponodo = this.tiponodo == 9 ? 2 : this.tiponodo
                  this.processFlow(accion, opcionelegida, this.nodoInicial, this.tiponodo, this.seccionInicial, this.codigomongo)
                    .then(() => {
                      resp(res);
                    });
                }
              }
            });
          });
          break;
        }
      case 4:
        // Retrocede en logica del árbol DB
        {
          return new Promise((resp) => {
            this.mongoService.consultNode('route/' + this.codigomongo + '/' + this.nodoInicial).then(res => {
              const respuesta = JSON.parse(JSON.stringify(res));
              if (respuesta.response !== 'No hay datos') {
                this.mongoService.deleteRouteSubDoc('route/' + this.codigomongo + '/0').then(re => {
                  this.processFlow(2, opcionelegida, this.nodoInicial, this.tiponodo, this.seccionInicial, this.codigomongo)
                    .then(() => {
                      this.mongoService.deleteRouteSubDoc('route/' + this.codigomongo + '/0').then(r => {
                        if ((this.repeticion === 2 || this.repeticion === 3) && this.tiponodo === 3) {
                          this.infonodo$.next(6);
                          resp(r);
                        } else if ((this.repeticion === 2 || this.repeticion === 3) && this.tiponodo === 1) {
                          this.infonodo$.next(7);
                          resp(r);
                        } else if (this.tiponodo == 5) {
                          this.consultWalk(this.idUsuario);
                        } else {
                          this.infonodo$.next(this.tiponodo);
                          resp(r);
                        }
                      });
                    });
                });
              } else {
                this.processFlow(2, opcionelegida, this.nodoInicial, this.tiponodo, this.seccionInicial, this.codigomongo).then(re => {
                  this.mongoService.deleteRouteSubDoc('route/' + this.codigomongo + '/' + this.nodoInicial).then(() => {
                    if ((this.repeticion === 2 || this.repeticion === 3) && this.tiponodo === 3) {
                      this.infonodo$.next(6);
                    } else if ((this.repeticion === 2 || this.repeticion === 3) && this.tiponodo === 1) {
                      this.infonodo$.next(7);
                    } else if (this.tiponodo == 5) {
                      this.consultWalk(this.idUsuario);
                    } else {
                      this.infonodo$.next(this.tiponodo);
                    }
                  });
                });
              }
            });
          });
        }

        break;
      // Envio información atras a postgres, sin enviar info a mongo
      case 5:
        {
          const datos = {
            id_nodo: this.nodoInicial,
            tipo_nodo: this.tiponodo,
            html: this.comomuestro.replace(/\++\+/g, '"').replace(/\**\*/g, '\''),
            variables: this.variables
          };
          this.indexRepeat = 0;
          return new Promise((resp) => {
            this.processFlow(2, opcionelegida, datos.id_nodo, datos.tipo_nodo, this.seccionInicial, this.codigomongo)
              .then(() => {
                if (this.isClon == 1) {
                  this.infonodo$.next(12); // Clonacion
                }
                else if ((this.repeticion === 2 || this.repeticion === 3) && this.tiponodo === 3) {
                  this.infonodo$.next(6);
                }
                else if ((this.repeticion === 2 || this.repeticion === 3) && this.tiponodo === 1) {
                  this.infonodo$.next(7);
                }
                else if (this.tiponodo == 5) {
                  this.consultWalk(this.idUsuario);
                }
                else if (this.tiponodo == 9) { // bifurca
                  this.infonodo$.next(this.tiponodo);
                  this.tiponodo = this.tiponodoAnterior
                }
                else {
                  this.infonodo$.next(this.tiponodo);
                }
                resp(resp);
              });
          });
          break;
        }
      default:
        break;
    }
  }

  public consultNode() {
    return new Promise((resolve) => {
      this.mongoService.consultNode('route/' + this.codigomongo + '/' + this.nodoInicial)
        .then(res => {
          const respuesta = JSON.parse(JSON.stringify(res));
          resolve(respuesta.response);
        });
    });
  }

  public consultPayment(idsection) {
    const usuario = JSON.parse((sessionStorage.getItem('User')));
    this.btnStatusPay$.next(true);
    return new Promise((resolve, rej) => {
      const consulta: Consult = {
        schema: 'arbol',
        tabla: 'validacionpagos',
        campo: 'idseccion, botonpago, vistaprevia, idproducto, idrespuesta, valor , transactionid',
        condicion: ' idusuario =' +usuario.data.idusuario +' AND idseccion =' + idsection + ' AND nombre_producto =\'\'' + this.nombre_producto + '\'\''
      };
      const peticion = new RequestProcess();
      peticion.proceso = '1';
      peticion.consulta = JSON.stringify(consulta);
      this.accessService.execProcess(peticion)
        .subscribe((res: any) => {
          if (res.codigo === '00') {
            let seccion = JSON.parse(res.dataresponse);
            if (seccion[0].botonpago !== 4) {
              let validPay = {
                'botonpago': seccion[0].botonpago,
                'idrespuesta': seccion[0].idrespuesta,
                'valor': seccion[0].valor,
                'transactionid' : seccion[0].transactionid
              }
             
              this.buttonPay$.next(validPay);
              if (seccion[0].botonpago == 1) {
                this.btnStatusPay$.next(false);
                this.consultValor();
              }
              else if (seccion[0].idrespuesta == 2)
                this.mostrarPago = true;
            }
            if (seccion[0].vistaprevia != 2) {
              
              this.vistaPrev$.next(seccion[0].vistaprevia);
              this.mostrarVista = true;
              /*              this.setStatusPago=true; */
            }
            else {
              this.vistaPrev$.next(seccion[0].vistaprevia);
              /*  this.setStatusPago=true; */
              this.mostrarVista = false;
            }
            //resolve(this.seccionInicial);
          } else {
            console.error('error al consultar sección inicial, ', res.mensaje);
            rej('error al consultar sección inicial, ' + res.mensaje);
          }
        });

    });

  }

  public getNameNode() {
    return this.nombrenodo
  }

  /**
   * Consultar el primer nodo de la seccion
   */
  consultFirstNode(idSeccion: number) {
    return new Promise((resolve, rej) => {
      const consulta: Consult = {
        schema: 'arbol',
        tabla: 'nodos_v',
        campo: 'idnodo, idtiponodo',
        condicion: 'idseccion = ' + idSeccion + ' and idnodoinicial = 0'
      };
      const peticion = new RequestProcess();
      peticion.proceso = '1';
      peticion.consulta = JSON.stringify(consulta);
      this.accessService.execProcess(peticion)
        .subscribe((res: any) => {
          if (res.codigo === '00') {
            const data = JSON.parse(res.dataresponse)[0];
            const nodoInicial = data.idnodo;
            const tipoNodo = data.idtiponodo;
            this.nodoInicial = nodoInicial;
            this.processFlow(1, 0, nodoInicial, tipoNodo, idSeccion, this.codigomongo)
              .then(res => resolve(res))
              .catch(err => rej(err))

            //resolve(this.seccionInicial);
          } else {
            console.error('error al consultar nodo inicial, ', res.mensaje);
            rej('error al consultar nodo inicial, ' + res.mensaje);
          }
        });
    });
  }

  /**
   * Inicio navegacion Producto
   */
  initNav(nombreProduct: string) {
    this.nombre_producto = nombreProduct
    this.nombre_product$.next(nombreProduct);

    const consulta: Consult = {
      schema: 'arbol',
      tabla: 'usuarioproductos',
      campo: '*',
      condicion: 'idproducto=' + this.idProducto + ' AND idusuario=' + this.idUsuario + 'AND nombre_producto =\'\'' + this.nombre_producto + '\'\''
    };
    const peticion = new RequestProcess();
    peticion.proceso = '1';
    peticion.consulta = JSON.stringify(consulta);
    this.accessService.execProcess(peticion).subscribe({
      next: (res: any) => {
        if (res.codigo === '90' && res.dataresponse === null) {
          this.consultSection().then(() => {
            const documento: Document = {
              nombre: this.nombreProducto,
              nombre_producto: this.nombre_producto,
              id_producto: this.idProducto + '',
              id_seccion: this.seccionInicial + '',
              id_usuario: this.idUsuario + '',
              html: ''
            };
            this.mongoService.createCollection(documento)
              .then(async (resp: string) => {
                this.codigomongo = await resp;
                this.processFlow(1, 0, 0, 0, this.seccionInicial, this.codigomongo)
                  .then(() => {
                    // Se llama la vista del nodo actual
                    this.infonodo$.next(10);
                    if ((this.repeticion === 2 || this.repeticion === 3) && this.tiponodo === 3) {
                      this.infonodo$.next(6);
                    } else if ((this.repeticion === 2 || this.repeticion === 3) && this.tiponodo === 1) {
                      this.infonodo$.next(7);
                    }
                    else if (this.tiponodo == 5) {
                      this.consultWalk(this.idUsuario);
                    }
                    else {
                      this.infonodo$.next(this.tiponodo);
                    }
                  });
              });

          }
          );
        } else {
          console.error('error al consultar sección inicial, ', res.mensaje);
          
        }
      }
    });
  }

  initNavGuia() {

    this.processFlow(1, 0, 0, 0, this.seccionInicial, 0)
      .then(() => {
        this.infonodo$.next(10);
        if (this.tiponodo == 5) {
          this.consultWalk(this.idUsuario);
        }
      });
  }

  navGuia(action: number) {
    this.processFlow(action)
      .then(() => {
        if (this.tiponodo == 5) {
          this.consultWalk(this.idUsuario);
        }
      });
  }

  /**
   * consultar si existen producto abiertos por el usuario
   * */
  consultOpenProducts(idusuario: number) {

    this.idUsuario = idusuario;
    // conulta a DB la logica del  arbol (nodos recorridos) que actualmente el usuario tiene en este producto
    if (this.idProducto != 0) {

      this.consultgeneral('arbol', 'usuarioproducto_v', 'distinct nombre_producto', 'idusuario = ' + idusuario + ' and estado = 1 and idproducto = ' + this.idProducto)
        .then((res: any) => {
          this.dataNavg$.next(JSON.parse(res));
        }).catch(
          err => {
            console.log(err);
            if (err === 'Consulta sin resultados') {
              // llamar vista para dar nombre al producto
              // this.infonodo$.next(11);
            }
          }
        );

    }
  }

  consultValor() {
    this.consultValorProducto().then((valor: number) => {
      if (valor != 0) {
        this.valorProd$.next(valor);
      } else {
      }
    })
      .catch(error => {
        console.error("Error al obtener el valor del producto: ", error);
      });

  }


  showHelpNode(idNodo: number) {
    this.consultgeneral('arbol', 'nodos_v', '*', 'idnodo = ' + idNodo + ' and estadonodo = 1 ')
      .then((respo: any) => {
        const contentNode = JSON.parse(respo).map(element => {
          return {
            idtipocontenido: element.idtipocontenido,
            contenido: element.contenido,
            idestadocontenido: element.idestadocontenido,
            ruta: element.ruta,
            idnodo: element.idnodo
          }
        });
        this.contenidoNodo$.next(contentNode);
        //this.infonodo$.next(this.tiponodo);          
        this.helpNode$.next(true);
      }).catch(
        err => {
          console.log(err, err.mensaje);
        }
      );
  }

  /**
   * consultar lista de producto en tipo producto guía
   * */

  getListProducts() {
    return new Promise((respuesta, reject) => {
      this.consultgeneral("producto", "productos_v", "*", "idproducto = " + this.idProducto)
        .then((resp: any) => {
          respuesta(JSON.parse(resp)[0].legaguia);
        }).catch(error => {
          console.log(error);
          reject(error);
        })
    });

  }



  consultValorProducto() {
    return new Promise((respuesta, reject) => {
      this.consultgeneral("producto", "productos_v", "valor", "idproducto = " + this.idProducto)
        .then((resp: any) => {
          this.valorPago = 0;
          respuesta(JSON.parse(resp)[0].valor);

        }).catch(error => {
          console.log(error);
          reject(error);
        })
    });

  }
  get getValorPago(): number {
    return this.valorPago;
  }
  get getIdSection(): any {
    return this.seccionInicial;
  }
  get getIdProd(): any {
    return this.idProducto;
  }
  get getIdNodo(): any {
    return this.idNodo;
  }
  get getNombreProducto (): string {
    return this.nombreProducto;
  }
}




