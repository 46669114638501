import { Component, OnInit } from '@angular/core';
import { RequestProcess } from '../../objects/request';
import { ProcesswebService } from '../../services/processweb.service';
import { Router } from '@angular/router';
import { Consult } from '../../objects/consult';


@Component({
    selector: 'app-feedbacks',
    templateUrl: 'feedback.component.html',
    //styleUrls: ['./feedback.component.css']
  })

export class FeedbackComponent implements OnInit {


    listFeedbacks: any;

      /* ranking productos  */
      listaProductos: any;
      isranking: boolean;
      rankin1: boolean = false;
      rankin2: boolean;
      rankin3: boolean;

    constructor(private accessService: ProcesswebService, private router: Router) {
    }

    ngOnInit() {
        this.rankinproduct()
    }

    rankinproduct(){
        try{
            this.listFeedbacks = new Array<any>();
            const consulta: Consult= {
              schema: 'producto',
              tabla: 'ranking3productos',  // mientras se crea nueva logica de feedbacks
              campo:'*',
              condicion: ' 1=1 limit 1'
            }
            const peticion = new RequestProcess();
            peticion.proceso = '1';
            peticion.consulta = JSON.stringify(consulta);
            this.accessService.execProcess(peticion)
                .subscribe((res: any) => {
                  if(res.codigo == '00') {
                      const listFeeds = JSON.parse(res.dataresponse);
                      this.listaProductos = listFeeds;
                      this.isranking = (this.listaProductos == null)? false : true;
                      if( this.listaProductos[0] != undefined ){
                        this.rankin1 = true;
                      }

                      if( this.listaProductos[1] != undefined ){
                        this.rankin2 = true;
                      }

                      if( this.listaProductos[2] != undefined ){
                        this.rankin3 = true;
                      }
                  }
                  else
                  {
                  }

                });

        }
        catch(error){
          console.log(error);
        }
    }

    redirectProduct(nombreProducto) {
      this.router.navigate(['/questions/' + nombreProducto])
    }
}
