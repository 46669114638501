import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductComponent } from './product.component';
import { HeaderModule } from '../header/header.module';
import { FooterModule } from '../footer/footer.module';
import { RouterModule, Routes } from '@angular/router';
/* ng-starrting para el rankingx */
import { RatingModule } from 'ng-starrating';

@NgModule({
  declarations: [ProductComponent],
  imports: [
    CommonModule,
    HeaderModule,
    FooterModule,
    RouterModule,
    RatingModule
  ]
})
export class ProductModule { }
