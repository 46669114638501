export class Constants {
    private cue = 'wsintegrador';
    // private endpoint='http://35.192.216.238:5454/servigamio/api/legamio/';// local-env
    //private endpoint = 'http://200.91.229.165:5454/servigamio/api/legamio/'; //pruebas
    private endpoint = 'https://legamio.com.co/servigamio/api/legamio/'; //produccion




    //private conectmongo = 'http://localhost:3333/product/';
    //private conectnode = 'http://localhost:3333/';
    //private conectnode ='http://200.91.229.165:3333/'; //Pruebas 

  /*   private conectnode ='http://200.91.229.162:3333/'; */
private conectnode = "https://legamio.com.co/node/";




    //private conectmongo = 'http://200.91.229.165:3333/product/'; //Pruebas
    /* private conectmongo = 'http://200.91.229.162:3333/product/'; */
     private conectmongo = 'https://legamio.com.co/node/product/';
    




    // private conectmongo = 'http://legamio.co:3333/product/';
    // private conectnode = 'http://legamio.co:3333/';
    // tslint:disable-next-line:max-line-length
    private pal = 'f4b3b0950f1124050e786b0b9f272db18ef842a12d21c7ba790b976761a2bb6b63fbb8d14f04fe6ccdcb14c1497b31b20c83db09d872ee06544411ce55104a8d';

    constructor() { }
    public getCue(): string {
        return this.cue;
    }

    public getPal(): string {
        return this.pal;
    }

    public getEndpoint(): string {
        return this.endpoint;
    }

    public getConectmongo() {
        return this.conectmongo;
    }

    public getConectNode() {
        return this.conectnode;
    }

}
