import { Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[aPassword]'
})

export class AppPasswordDirective {

    private _shown = false;

    constructor(private el: ElementRef) {
      this.setup();
    }

    setup() {
      const parent = this.el.nativeElement.parentNode;
      const span = document.createElement('span');
      span.classList.add('show-password');
      span.classList.add('hidden-password');
      span.addEventListener('click', (event) => {
        this.toggle(span);
      });
      parent.appendChild(span);
    }

    toggle(span: HTMLElement) {
      this._shown = !this._shown;
      if (this._shown) {
        this.el.nativeElement.setAttribute('type', 'text');
        span.classList.remove('hidden-password');
      } else {
        this.el.nativeElement.setAttribute('type', 'password');
        span.classList.add('hidden-password');
      }
    }

}
