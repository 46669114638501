import { Component, OnInit } from '@angular/core';
import { MatFormField } from '@angular/material';
import { RequestProcess } from '../../../../objects/request';
import { ProcesswebService } from '../../../../services/processweb.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Consult } from '../../../../objects/consult';;

@Component({
  selector: 'app-pqr',
  templateUrl: './pqr.component.html',
  styleUrls: ['./pqr.component.css']
})
export class PqrComponent implements OnInit {

  constructor( private accessService: ProcesswebService, public ngxSmartModalService: NgxSmartModalService) { 
    
  }

  rotation = 0;
  public pqrText: string = "";
  public userName: string= "";
  public message: string= "";
  public causales: any;
  public causalespqr: any;
  public subjectPQR: string = "";

  ngOnInit() {
   /*  this.consultCausales(); */
   
  }

pqrSend(){
  event.preventDefault();
  const usuario = JSON.parse((sessionStorage.getItem('User')));
  this.subjectPQR = "Recibimos tu PQRS";
 const mail: any = {
    user: usuario.data.nombre + ' ' + usuario.data.apellido ,
    documento: usuario.data.documento,
    email:   usuario.data.cuenta,
    subject: this.subjectPQR,
    pqrs: this.pqrText
  }
  
   let peticion = new RequestProcess()
   peticion.proceso = '1'
   peticion.consulta = JSON.stringify(mail)
     this.accessService.sendEmail(mail, 'pqrLegamio')
        .subscribe( res => {
            if (res.success) {
              // PARTE DEL MODAL 
              this.message = 'PQRS procesado con éxito'
              this.ngxSmartModalService.getModal('myModal').open();
            } else {
              this.message = 'Error durante el proceso del PQRS'
              this.ngxSmartModalService.getModal('myModal').open();
           
            }
        },
        error => {
          console.log(error)
          this.message = 'Error durante el proceso del PQRS'
          this.ngxSmartModalService.getModal('myModal').open();
          }
          
        ) 
        this.pqrText="";  
        
}
rotateIcon(event: Event) {
  event.stopPropagation(); // Evita que el evento se propague y cierre el select
  this.rotation = this.rotation === 0 ? 180 : 0; // Cambia la rotación del ícono
}

}