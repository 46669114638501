import { EventEmitter, Injectable, Injector, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { BehaviorSubject, Subject } from 'rxjs';
import { TipLegal } from '../views/users/perfil/tips-legales/tips-legales.component';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Consult } from '../objects/consult';
import { RequestProcess } from '../objects/request';
import { ProcesswebService } from './processweb.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  @ViewChild(MatPaginator) paginator: MatPaginator;



  @Output() openFavoritos = new EventEmitter<void>();

  private _helpSearch$ = new BehaviorSubject<string>('');

  tipsLegales: any[] = [];
  products: any[] = [];
  legaguias: any[] = [];
  //dataSource = new MatTableDataSource<TipLegal>(this.tipsLegales);
  nombreCapitulo = "";

  private _search: string = '';
  public helpSearch$ = () => this._helpSearch$.asObservable();


  constructor(public ngxSmartModalService: NgxSmartModalService,private accessService: ProcesswebService,) {
  }


  showTipsLegales() {
    this._helpSearch$.next(this.getSearch);
   

   
  }
  close(){
    this.ngxSmartModalService.getModal('modalLegatips').close();
  }


  set setSearch(name: string) {
    this._search = name;
  }

  // Getter
  get getSearch(): string {
    return this._search;
  }
}
