import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import  * as $ from 'jquery' ;/* 

import *  as material from 'assets/js/materialKit/material-kit.js'; */
import { NgxSmartModalService } from 'ngx-smart-modal';
import { TipLegal } from './views/users/perfil/tips-legales/tips-legales.component';
import { Consult } from './objects/consult';
import { RequestProcess } from './objects/request';
import { ProcesswebService } from './services/processweb.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { SearchComponent } from './views/shared/search/search.component';
import { SearchService } from './services/search.service';
import { NequiService } from './services/nequi.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{

  title = 'Legamio-cliente';
 
  displayedColumns: string[] = [ 'tip_solucion'];
  
  esPolitica = false;

  isPanelOpen = false;



  




  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  constructor(
    private router: Router,
    public ngxSmartModalService: NgxSmartModalService,
    private accessService: ProcesswebService,
    private _route: ActivatedRoute,
    public search: SearchService,
    
  ) { 
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.esPolitica = this.router.url.includes('/politica'); // Comprueba si la URL contiene 'politica'
      }
    });
  }
  ngOnInit() {   
   
  }
  close(){
    this.ngxSmartModalService.getModal('modalLegatips').close();
  }
  esComponenteLogin():boolean {
    return this.router.url === '/login';
  }



}

