import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { NgxSmartModalModule,  NgxSmartModalService } from 'ngx-smart-modal';
import { OneModalComponent } from '../../modals/one-modal/one-modal.component';

@NgModule({
  declarations: [LoginComponent, OneModalComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    FormsModule, 
    NgxSmartModalModule
  ],
  
  exports: [LoginComponent, MatCardModule, MatButtonModule, NgxSmartModalModule],
 
})
export class LoginModule { }
