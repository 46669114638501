import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[sticky-below-view]'
})
export class StickyBelowViewDirective {
    private initialOffsetFromTop =  0;

    constructor(private element: ElementRef) { }
  
    private getInitialOffset(){
      let initialViewportOffset = this.element.nativeElement.getBoundingClientRect().top;
      let currentScroll = window.scrollY;
      this.initialOffsetFromTop = initialViewportOffset + currentScroll;  
   }
}