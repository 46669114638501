import { Component, OnInit, ViewChild } from '@angular/core';
import { Consult } from '../../../objects/consult';
import { RequestProcess } from '../../../objects/request';
import { ProcesswebService } from '../../../services/processweb.service';
import { MatPaginator, } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material';
import { Router } from '@angular/router';
import { TreeService } from '../../../services/tree.service';


@Component({
  selector: 'app-my-folder',
  templateUrl: './my-folder.component.html',
  styleUrls: ['./my-folder.component.css']
})
export class MyFolderComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private accessService: ProcesswebService, private router: Router, private treeService: TreeService) { }

  listaProductos: any;
  dataUser: any;
  idUsuario: any;
  dataSource: any;
  pageEvent: PageEvent;
  displayedColumns: string[] = ['productName', 'productCategorie', 'estate', 'actions'];
  lowValue = 0;
  highValue = 10;
  public array: any;
  public pageSize = 10;
  public currentPage = 0;
  public totalSize = 0;


  ngOnInit() {
    if (sessionStorage.getItem('User') !== null) {
      this.dataUser = JSON.parse(sessionStorage.getItem('User'));
      this.idUsuario = this.dataUser.data.idusuario;
    }

    this.listarGrupos();
  }

  public handlePage(e: any) {
    this.iterator();
  }

  listarGrupos() {
    try {
      this.listaProductos = new Array<any>();
      const consulta: Consult = {
        schema: 'producto',
        tabla: 'reportproductosusuarios',
        campo: 'idusuario, producto, categoria, estado, idnodoactual, codigomongo',
        condicion: 'idusuario = ' + this.idUsuario + 'order by producto'
      };
      const peticion = new RequestProcess();
      peticion.proceso = '1';
      peticion.consulta = JSON.stringify(consulta);
      this.accessService.execProcess(peticion)
        .subscribe((res: any) => {
          if (res.codigo === '00') {
            const listproduct = JSON.parse(res.dataresponse);
            this.listaProductos = listproduct;
            this.dataSource = new MatTableDataSource<Products>(this.listaProductos);
            this.dataSource.paginator = this.paginator;

            this.totalSize = this.listaProductos.length;
            this.iterator();

          } else {
          }
        });
    } catch (error) {

    }
  }
  private iterator() {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    const part = this.listaProductos.slice(start, end);
    this.dataSource = part;
  }

  public getPaginatorData(event: PageEvent): PageEvent {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    return event;
  }

  download(estado, codigomongo) {
    const accion = (estado === 'TERMINADO') ? 'Crear' : 'VistaPrevia';
    this.treeService.setCodigoMongo(codigomongo);
    console.log(codigomongo);
    this.treeService.setPDFMongo(accion);
  }

  editProduct(idnodoactual, producto) {
    this.treeService.getIdProducto(producto).then(() => {
      this.router.navigate(['/questions/' + producto]).then(() => {
        this.treeService.consultWalk(JSON.parse(sessionStorage.getItem('User')).data.idusuario);
      });
    });
  }
}

export interface Products {
  name: string;
  description: string;
  estate: string;
  actions: any;
}
