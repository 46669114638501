import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { HeaderModule } from '../header/header.module';
import { MatCardModule, } from '@angular/material/card';
import { MatMenuModule, MatIconModule} from '@angular/material'
import { SlideshowModule } from 'ng-simple-slideshow';
import { FooterModule } from '../footer/footer.module';
import { NgFallimgModule } from  'ng-fallimg';
import { FeedbacksModule } from '../feedbakcs/feedbacks.module';
import { NgxSmartModalModule,  NgxSmartModalService } from 'ngx-smart-modal';

import { SharedModule } from '../shared/shared.module';

/* ng-starrting para el rankingx */
import { RatingModule } from 'ng-starrating';
import { from } from 'rxjs';


@NgModule({
  declarations: [HomeComponent],
  imports: [
    CommonModule,
    HeaderModule,
    MatCardModule,
    SlideshowModule,
    FooterModule,
    NgFallimgModule,
    MatMenuModule,
    MatIconModule,
    RatingModule,
    SharedModule,
    FeedbacksModule,
    NgxSmartModalModule,
  ],
  providers: [ NgxSmartModalService ],
})
export class HomeModule { }
