import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Consult } from '../../objects/consult';
import { RequestProcess } from '../../objects/request';
import { ProcesswebService } from '../../services/processweb.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Constants } from '../../objects/constans';

@Component({
  selector: 'app-grupos',
  templateUrl: './grupos.component.html',
  styleUrls: ['./grupos.component.css']
})

export class GruposComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constantes = new Constants();

  nombreCapitulo: string = "";
  nombreCategoria: string = "";

  // tslint:disable-next-line:max-line-length
  constructor(
    private _route: ActivatedRoute,
    private accessService: ProcesswebService,
    private sanitizer: DomSanitizer,
    public ngxSmartModalService: NgxSmartModalService,
    private http: HttpClient,
    private router: Router,
    private _location: Location) {
  }


  public _datagrupo: any;
  nombreGrupo = '';
  listaProductos: any;
  logoImage: any;
  logoUrl: any = './assets/images/grupo-1.png';
  dataSource: any;
  displayedColumns: string[] = ['productName', 'productDescription'];
  descripcion: string = "";
  groupImage: any

  /* to ranking products*/
  listaRankingProductos: any
  isranking: boolean;
  rankin1: boolean = false;
  rankin2: boolean = false;
  rankin3: boolean = false;

  /* to content Group */
  _dataProdut: any;
  _dataGroup: any;
  productText: any;
  productImage: any;
  productVideo: any;
  productAudio: any;
  areVideo = false;
  areSound = false;
  areImage = false;
  areText = false;
  viewVideo = false;
  viewSound = false;
  viewImage = false;
  viewText = false;
  message = '';
  openHelp = false;
  openVideo = false;
  openSound = false;
  products = false

  ngOnInit() {
    this.groupImage = this.logoUrl;
    this.nombreGrupo = this._route.snapshot.paramMap.get('group')//.toUpperCase();
    this.nombreCapitulo = sessionStorage.getItem('Chapter');
    this.nombreCategoria = sessionStorage.getItem('Category');
    sessionStorage.setItem('Group', this.nombreGrupo);
    this.listarProductos();
    //this.rankinproduct()
    this.dataSource = new MatTableDataSource<Products>(this.listaProductos);
    this.dataSource.paginator = this.paginator;
    this._datagrupo = [{ contenido: '' }];
    window.scrollTo(0, 0);
    this.readDescritionGroup();

    // Proceso para cargar el gif
    var getGif = function () {
      var gif = [];
      $('img').each(function () {
        var data = $(this).data('alt');
        gif.push(data);
      });
      return gif;
    }
    var gif = getGif();

    // Preload all the GIF.
    var image = [];

    $.each(gif, function (index) {
      image[index] = new Image();
      image[index].src = gif[index];
    });
  }

  listarProductos() {
    try {
      this.listaProductos = new Array<any>();
      const consulta: Consult = {
        schema: 'producto',
        tabla: 'productos_v',
        campo: 'nombre, descripcion, grupo, estadoproducto, idproducto',
        condicion: 'estadoproducto = \'\'EN DESARROLLO\'\'and grupo =\'\'' + this.nombreGrupo + '\'\' order by nombre'
      };
      const peticion = new RequestProcess();
      peticion.proceso = '1';
      peticion.consulta = JSON.stringify(consulta);
      this.accessService.execProcess(peticion)
        .subscribe((res: any) => {
          if (res.codigo === '00') {
            const listproduct = JSON.parse(res.dataresponse);
            this.listaProductos = listproduct;

            this.products = true;
            this.dataSource = new MatTableDataSource<Products>(this.listaProductos);
            this.dataSource.paginator = this.paginator;
          } else {
            this.products = false;
            if(res.mensaje == "Consulta sin resultados") {
              this.router.navigate(['/404']);
            }

          }
        });
    } catch (error) {
      console.log(error);
    }

  }

  /* rankinproduct() {

    try {
      this.listaRankingProductos = new Array<any>();
      const consulta: Consult = {
        schema: 'producto',
        tabla: 'ranking3productos',
        campo: '*',
        condicion: 'grupo=\'\'' + this.nombreGrupo + '\'\' limit 3'
      }
      const peticion = new RequestProcess();
      peticion.proceso = '1';
      peticion.consulta = JSON.stringify(consulta);
      this.accessService.execProcess(peticion)
        .subscribe((res: any) => {
          const listproduct = JSON.parse(res.dataresponse);
          this.listaRankingProductos = listproduct;
          console.log(res, ' --- Rankign List')
          if (res.codigo == '00') {
            this.isranking = (this.listaRankingProductos == null) ? false : true;
            // if(this.listaRankingProductos[0] != null  || this.listaRankingProductos[0] != '' || this.listaRankingProductos[0] != undefined){
            if (this.listaRankingProductos[0] != undefined) {
              this.rankin1 = true;
              console.log(this.listaRankingProductos)
            }

            if (this.listaRankingProductos[1] != undefined) {
              this.rankin2 = true;
            }

            if (this.listaRankingProductos[2] != undefined) {
              this.rankin3 = true;
            } else {
              this.rankin3 = false;
            }
            console.log("rankin3", this.listaRankingProductos[2])

          } else {
            console.log(res.mensaje);
          }

        });
    }
    catch (error) {
      console.log(error);
    }
  } */

  readDescritionGroup() {

    try {
      const consult: Consult = {
        schema: 'producto',
        tabla: 'grupos',
        campo: 'nombre, descripcion, idgrupo',
        condicion: 'nombre= \'\'' + this.nombreGrupo + '\'\' '
      };
      const peticion = new RequestProcess();
      peticion.proceso = '1';
      peticion.consulta = JSON.stringify(consult);
      this.accessService.execProcess(peticion)
        .subscribe((res: any) => {
          if (res.codigo === '00') {
            const dataGroup = JSON.parse(res.dataresponse);
            this._dataGroup = dataGroup;
            this.descripcion = this._dataGroup[0].descripcion

          }
        });

    } catch (error) {
      console.log(error)
    }
  }

  public getSantizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  goBack() {
    this._location.back();
  }

  animatedGif() {
    // código JS para activar gif del pollo
    var $this = $(this),
      $index = $this.index(),

      $img = $('#imagenGif'),
      $imgSrc = "./assets/images/Bird.gif",
      $imgAlt = "./assets/images/Bird.gif",
      $imgExt = $imgAlt.split('.');

    $img.attr('src', $imgAlt).attr('data-alt', $imgSrc);

    setTimeout(() => {
     // $img.attr('src', $imgSrc).attr('data-alt', $imgAlt);
    }, 5500);
    /////////end cod. JS
  }
}


export interface Products {
  name: string;
  description: string;
}
