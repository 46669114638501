import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {ComponentesComponent} from './componentes/componentes.component';
import {LoginComponent} from './views/users/login/login.component';
import { RegisterComponent } from './views/users/register/register.component';
import { HomeComponent } from './views/home/home.component';
import { FolderComponent } from './views/folder/folder.component' ;
import { PerfilComponent} from './views/users/perfil/perfil.component';
import { ConfiguracionComponent } from './views/users/perfil/configuracion/configuracion.component'
/* import { DerechoEmpresaComponent } from './views/derecho-empresa/derecho-empresa.component'; */
import { GruposComponent  } from './views/grupos/grupos.component';
import { ProductComponent } from './views/product/product.component';
import { CapituloComponent } from './views/capitulos/capitulo.component'
import { CategoriesComponent } from './views/categories/categories.component';
import { HomeTempComponent } from './views/home-temp/home-temp.component';
import { DocumentsUserComponent } from './views/users/perfil/documents/documentsUser.Component';
import { PayComponent } from './views/pay/pay.component';
import { PoliticaComponent } from './views/users/register/politca-datos/politica.component';
import { NoproductComponent } from './views/noproduct/noproduct.component';
import { Page404Component } from './views/404/page404.component';
import { ActualizacionComponent } from './views/users/perfil/actualizacion/actualizacion.component';

const routes: Routes = [
  {
    path: 'homebuild',
    component: HomeTempComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
    children: [{path: 'login', component: LoginComponent}]
  },
  {
    path: 'register',
    component: RegisterComponent,
    children: [{path: 'register', component: RegisterComponent}]
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'myFolder',
    component: FolderComponent
  },
  {
    path: 'perfil/:useroption',
    component: PerfilComponent
  },
  {
    path: 'componentes',
    component: ComponentesComponent,
  },
  /* {
    path: 'categories',
    component: CategoriesComponent,
  }, */
  {
    path: 'capitulo/:capitulo',
    component: CapituloComponent,
  },
  {
    path: 'categories/:category',
    component: CategoriesComponent,
  },
  {
    path: 'grupos/:group',
    component: GruposComponent,
  },
  {
    path: 'product',
    component: ProductComponent,
  },
  {
    path: 'producto/:productonombre',
    component: ProductComponent,
  },
  {
    path: 'questions/:productonombre',
    loadChildren: './views/sequencetree/sequencetree.module#SequenceTreeModule'
  },
  /* {
    path: 'questions/:productonombre',
    component: QuestionProductComponent,
  }, */
  {
    path: '',
    component: HomeComponent,
    children: [{path: 'home', component: HomeComponent}]
  },
  {
    path: 'changepassword',
    component: ConfiguracionComponent
  },
  {
    path: 'data-update',
    component: ActualizacionComponent
  },
  {
    path: 'documents',
    component: DocumentsUserComponent
  },
  {
    path: 'pago-pin',
    component: PayComponent
  },
  {
    path: 'politica-datos',
    component: PoliticaComponent,
  },
  {
    path: 'no-product',
    component: NoproductComponent,
  },
  {
    path: '404',
    component: Page404Component,
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true}) ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
