import { Injectable } from '@angular/core';
import { RequestProcess } from '../objects/request';
import { ProcesswebService } from './processweb.service';



//FIREBASE
/* import { AngularFireAuth } from '@angular/fire/auth'; */
import { map } from 'rxjs/operators';
/* import { auth }  from 'firebase/app'; */
import { promise } from 'protractor';
import { resolve } from 'path';
import { EmailValidator } from '@angular/forms';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
/* import { FirebaseZoneScheduler } from '@angular/fire'; */


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public logged: boolean = false;
  public name: string ="";

  private opcionMenu$ = new BehaviorSubject<string>('');
  public _opcionMenu$ = () => this.opcionMenu$.asObservable();

  constructor(private accessService: ProcesswebService ) { }

 /*  registerUser(email:string, pass: string){
    return new Promise((resolve, reject) => {
        this.afsAuth.auth.createUserWithEmailAndPassword(email, pass)
        .then(userData => resolve(userData)),
        err => reject(err);
    })
  } */

/*   loginEmailUser(email:string, pass: string){
    return new Promise((resolve, reject) =>{
      this.afsAuth.auth.signInWithEmailAndPassword(email, pass)
        .then(userData => resolve(userData),
         err => reject(err));
    })
  } */

  loginFacebookUser(){

  }

  loginGoogleUser(){
    /* return this.afsAuth.auth.signInWithPopup( new auth.GoogleAuthProvider()); */

   
  }
  logOutUser(){
    /* return this.afsAuth.auth.signOut(); */
  }

  isAuth(){
    // return this.afsAuth.authState.pipe(map(auth => auth))

    if (sessionStorage.getItem('User') !== null){
      this.logged = true;
    } else if(sessionStorage.getItem('User') === null) {
        this.logged = false;
    }
    return (this.logged);
  }
  /**
   * Manejo del menu de usuario con observable
   */
  changedOptionUserMenu(option: string) {
    this.opcionMenu$.next(option);
  }

}

