import { Injectable } from '@angular/core';
import { RequestProcess } from '../objects/request';
import { ProcesswebService } from './processweb.service';
import { BehaviorSubject } from 'rxjs';
import { Document } from '../objects/document';
import { Product } from '../objects/product';
import { Route } from '../objects/route';
import { resolve } from 'dns';
import { saveAs } from 'file-saver/src/FileSaver';

@Injectable({
  providedIn: 'root'
})
export class MongoService {

  public respuestageneral: any;
  public idDocument = '';
  public idProduct = '';
  public idUser = '';
  private idRoute = '';
  constructor( private accessService: ProcesswebService) { }

  /**
   * Crear coleccion en mongo de document, producto y ruta
   * este proceso se ejecuta cuando se inicia la navegación de un producto sin datos previos de usuario
   * en el registro
   */
  public createCollection(data: Document) {
    return new Promise( (resp, reject) => {
      this.accessService.addCollection('document', data)
      .subscribe(res => {
        const producto: Product = {
          id_documento: res.response._id,
          id_usuario: res.response.id_usuario
        };
        this.accessService.addCollection('product', producto)
        .subscribe(
          re => {
            this.idDocument = re.response.id_documento;
            this.idUser = re.response.id_usuario;
            this.idProduct = re.response._id;
            const ruta: Route = {
              id_usuario: this.idUser,
              id_documento: this.idDocument,
              id_product: this.idProduct
            };
            this.accessService.addCollection('route', ruta)
            .subscribe(
              response => {
                this.idRoute = response.response._id;
                resp(this.idRoute);
              },
              err => {
              }
            );
          },
          error => console.log(error)
        );
      }, err => console.log(err));
    });
  }

  public consultCollection(document: string) {
    return new Promise( (resp, reject) => {
      this.accessService.getDocumentInfo(document)
        .subscribe(
          async res => {
            this.idRoute = await res.response[0]._id;
            this.idDocument = await res.response[0].id_documento;
            this.idProduct = await res.response[0].id_producto;
           
            resp(this.idRoute);
          },
          err => {
           
          }
        );
    });
  }
  /**
   * se actualiza o se agrega información a una coleccion-documento en mongo
   * se ejecuta cada vez que se navega el arbol hacia adelante (boton siguiente)
   */
  public updateDocument(document: string, data: any) {
    return new Promise( (res, rej) => {
      this.accessService.updateDocument(document, data)
      .subscribe(resp => {
        res(resp);
      }, err => console.log(err));
    });
  }

  public updateNodeRepeat(document: string, data: any) {
    return new Promise( (res, rej) => {
      this.accessService.updateNodeRepeat(document, data)
      .subscribe(resp => {
        res(resp);
      }, err => console.log(err));
    });
  }

  public deleteRouteSubDoc(document: string) {
    return new Promise( (res, rej) => {
      this.accessService.deleteDoc(document)
      .subscribe(resp => {
        res(resp);
      }, err => console.log(err));
    });
  }

  public consultPDF(datos) { 
    const ext = datos.tipoDoc;
    return new Promise( (res, rej) => {
      this.accessService.consultPDF(datos).subscribe(resp => { //  data.response).subscribe(resp => {
        saveAs(new Blob([resp]), 'archivo.' + ext );
       });
    });
  }

  public consultNode(datos) { // data: any) {
    return new Promise( (resp, reject) => {
      this.accessService.getDocumentInfo(datos)
        .subscribe(
          async res => {
            resp(res);
          },
          err => {
          }
        );
    });
  }


}
