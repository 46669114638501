import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Constantes
import {Constants} from '../objects/constans';

// Objetos de consumo
import {ResponseProcess} from '../objects/response';
import {RequestProcess} from '../objects/request';
import {Document} from '../objects/document';
import {Product} from '../objects/product';
import { HttpClient, HttpHandler, HttpHeaders , HttpParams, HttpResponse} from '@angular/common/http';
import { saveAs } from 'file-saver/src/FileSaver';

@Injectable()
export class ProcesswebService {
  
  

  constantes = new Constants();

  constructor(private http: HttpClient) {
    
   }

  execProcess(peticion: RequestProcess): Observable<any> {
    // Alistamos la peticion

    peticion.clave = this.constantes.getPal();
    peticion.cuenta = this.constantes.getCue();
    const jsonPeticion = JSON.stringify(peticion);

    // Establecemos cabeceras
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json');
    headers.set('Access-Control-Allow-Origin', '*');


    return   this.http.post<any>(this.constantes.getEndpoint() + 'process', JSON.parse(jsonPeticion), {headers: headers}).pipe(
      map(  result => result
    
    ));
  }

  sendEmail(peticion: RequestProcess, template: string): Observable<any> {
    // Alistamos la peticion
    const jsonPeticion = JSON.stringify(peticion);
    // Establecemos cabeceras
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json');
    headers.set('Access-Control-Allow-Origin', '*');
    // Ejecutamos llamado
    return this.http.post<any>(this.constantes.getConectNode() + 'mail/' + template, JSON.parse(jsonPeticion), {headers}).pipe(
      map(  result => result )
    );
  }

  tokenSMS(template: string): Observable<any> {
    // Alistamos la peticion
    // Establecemos cabeceras
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json');
    headers.set('Access-Control-Allow-Origin', '*');
    // Ejecutamos llamado
    return this.http.get<any>(this.constantes.getConectNode() + 'sms/' + template, {headers}).pipe(
      map(  result => result )
    );
  }



  sendSms(peticion: RequestProcess, template: string): Observable<any> {
    // Alistamos la peticion
    const jsonPeticion = JSON.stringify(peticion);
    // Establecemos cabeceras
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json');
    headers.set('Access-Control-Allow-Origin', '*');
    // Ejecutamos llamado
    return this.http.post<any>(this.constantes.getConectNode() + 'sms/' + template, JSON.parse(jsonPeticion), {headers}).pipe(
      map(  result => result )
    );
  }
 



  sendNotifiPush(peticion: RequestProcess, template: string): Observable<any> {
    // Alistamos la peticion
    const jsonPeticion = JSON.stringify(peticion);
    // Establecemos cabeceras
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json');
    headers.set('Content-Type', 'application/json');
    headers.set('Access-Control-Allow-Origin', '*');
    return this.http.post<any>(this.constantes.getConectNode() + 'nequi/' + template, JSON.parse(jsonPeticion), {headers}).pipe(
      map(  result => result )
    );
  }


  tokenNequi(template: string): Observable<any> {
    // Alistamos la peticion
    // Establecemos cabeceras
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json');
    headers.set('Access-Control-Allow-Origin', '*');
    // Ejecutamos llamado
    return this.http.get<any>(this.constantes.getConectNode() + 'nequi/' + template, {headers}).pipe(
      map(  result => result )
    );
  }





  // GET obtiene informacion requerida en mongo
  getDocumentInfo(document: string): Observable<any> {
    const constantes = new Constants();
    return this.http.get(this.constantes.getConectmongo() + document);
  }

  // Agrega documento 'document' en Mongo
  addDocument(peticion: Document): Observable<any>  {
    // Alistamos la peticion
    const constantes = new Constants();
    const jsonPeticion = JSON.stringify(peticion);

    // Establecemos cabeceras
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json');
    headers.set('Access-Control-Allow-Origin', '*');
    return this.http.post<any>(this.constantes.getConectmongo() + 'document', JSON.parse(jsonPeticion), {headers});
  }

  /***
   * agrega un nuevo registro al documento (docuemnt, product, route) en mongo
   */
  addCollection(document: string, peticion: any): Observable<any>  {
    // Alistamos la peticion
    
    const constantes = new Constants();
    const jsonPeticion = JSON.stringify(peticion);
    // Establecemos cabeceras
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json');
    headers.set('Access-Control-Allow-Origin', '*');
    return   this.http.post<any>(this.constantes.getConectmongo() + document, JSON.parse(jsonPeticion), {headers});
  }

  // actualiza un documento en la coleccion mongo
  updateDocument(document: string, peticion: any): Observable<any>  {
    // Alistamos la peticion
    const constantes = new Constants();
    const jsonPeticion = JSON.stringify(peticion);
    // Establecemos cabeceras
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json');
    headers.set('Access-Control-Allow-Origin', '*');
    return   this.http.put<any>(this.constantes.getConectmongo() + document, JSON.parse(jsonPeticion), {headers, });
  }

  updateNodeRepeat(document: string, peticion: any): Observable<any>  {
    // Alistamos la peticion
    const constantes = new Constants();
    const jsonPeticion = JSON.stringify(peticion);
    // Establecemos cabeceras
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json');
    headers.set('Access-Control-Allow-Origin', '*');
    return   this.http.put<any>(this.constantes.getConectmongo() + document, JSON.parse(jsonPeticion), {headers, });
  }

  deleteDoc(document: string) {
    const constantes = new Constants();
    return this.http.delete<any>(this.constantes.getConectmongo() + document);
  }

  consultPDF( datos ) { // peticion: any) {
    // Alistamos la peticion
    const constantes = new Constants();
    const jsonPeticion = JSON.stringify(datos);
    // Establecemos cabeceras
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json');
    headers.set('Access-Control-Allow-Origin', '*');
    //  let body = new HttpParams();
    //  body = body.set('html', peticion);
    //  body = body.set('paper', 'letter');
    //  body = body.set('orientation', 'portrait');
    //  tslint:disable-next-line:max-line-length
    return this.http.post(this.constantes.getConectmongo() + 'route/pdf/', JSON.parse(jsonPeticion), {responseType: 'arraybuffer'});    // return this.http.post('http://legamio.co:5492/pdf.php', body, { responseType: 'arraybuffer' });
  }

}
