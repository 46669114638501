import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PerfilComponent } from './perfil.component';
import { MiCuentaComponent } from './mi-cuenta/mi-cuenta.component';
import { HeaderModule } from '../../header/header.module';
import { ConfiguracionComponent } from './configuracion/configuracion.component';
import { PaymentHistoryComponent } from './payment-history/payment-history.component';
import { PqrComponent } from './pqr/pqr.component';
import { DocumentsUserComponent } from './documents/documentsUser.Component';
import { TipsLegalesComponent } from './tips-legales/tips-legales.component'
import { FooterModule } from '../../footer/footer.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSmartModalModule,  NgxSmartModalService } from 'ngx-smart-modal';
import { MatFormFieldModule, MatIconModule, MatInputModule, MatDatepickerModule, MatNativeDateModule, MatSelect, MatSelectModule, MatOptionModule, } from '@angular/material';
import { SharedModule } from '../../shared/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import { Page404Module } from '../../404/page404.module';
import { NgxMaskModule } from 'ngx-mask';
import { ActualizacionComponent } from './actualizacion/actualizacion.component';

@NgModule({
  declarations: [PerfilComponent, MiCuentaComponent, ConfiguracionComponent, PaymentHistoryComponent, PqrComponent, DocumentsUserComponent,  TipsLegalesComponent, ActualizacionComponent],
  imports: [
    CommonModule,
    HeaderModule,
    FooterModule,
    FormsModule,
    NgxSmartModalModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatNativeDateModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    SharedModule,
    NgxPaginationModule,
    MatTableModule,
    MatPaginatorModule,
    Page404Module,
    NgxMaskModule.forRoot(),
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule
  ],
  providers: [NgxSmartModalService]
})
export class PerfilModule { }
