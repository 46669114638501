import { Component, OnInit, TemplateRef, Inject, ViewChild, ElementRef, NgZone, OnDestroy } from '@angular/core';
import { RequestProcess } from '../../../objects/request';
import { ProcesswebService } from '../../../services/processweb.service';
import { sha512 } from 'js-sha512';
import { FacebookService, InitParams, LoginResponse, LoginOptions } from 'ngx-facebook';
import { Router } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { jwtDecode } from "jwt-decode";

// SERVICIOS
import { AuthService } from '../../../services/auth.service';
import * as materialKit from '../../../../assets/js/material-kit.js';
import { Register } from 'src/app/objects/register';
import { Subscription } from 'rxjs';
import { NequiService } from 'src/app/services/nequi.service';
import { Register_aux } from 'src/app/objects/register_aux';


declare var google: any;
declare var handleCredentialResponse: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit/*  , OnDestroy */{


  token: string | undefined='';
  private tokenSubscription: Subscription | undefined;
  private intervalId: any;

  ipAddress:string;  

  // private user: SocialUser;
  // private loggedIn: boolean;
  // public userGoogle: any = SocialUser;
  public ismobile: boolean = false;
  showModal: boolean = true;
  screenWidth: number;
  activeComponent: boolean = false;
  dataUser:any;
  ruta:string = 'home';

  constructor(
    private authService: AuthService,
    private router: Router,
    private accessService: ProcesswebService,
    private fb: FacebookService,
    //private authServiceGoogle: AuthServiceGoogle,
    public ngxSmartModalService: NgxSmartModalService,
    private zone: NgZone,
    public nequi:NequiService,
  ) {
    let initParams: InitParams = {

      //appId: '1868516723254243',
      appId: "914221625630107",
      cookie: true,
      xfbml: true,
      version: 'v3.1'
    };

    // let initParamsGoogle: any = {
    //   client_id: "625161182114-gu032ndtnrks2bpcbs0stn6j841r09kn.apps.googleusercontent.com"
    // }

    fb.init(initParams);
    // fb.api(
    //   '/me',
    //   'get',
    //   { "fields": "id,name,email" }
    // );

  }

  public email: string = '';
  public pass: string = '';
  message?: string = '';
  informacion: string = '';
  document: string = '';
  public userName: string = "";
  eye: boolean = false;

  auth2: any;

  @ViewChild('loginRef') loginElement: ElementRef;

  //public FB: any;

  ngOnInit() {
    if(sessionStorage.getItem('Section')){
       this.ruta=   `questions/${sessionStorage.getItem('Section')}`
    }
    this.getIP();

    //init Google
    /* AppGlobals.GOOGLE_CLIENT_ID = 'SECRET_CLIENT_ID'; */
    // this.authServiceGoogle.authState.subscribe((user) => {
    //   this.userGoogle = user;
    // });

    //this.googleAuthSDK();
    (window as any).handleCredentialResponse = (response: any) => {
      const decoded = jwtDecode(response.credential);
      // console.log("Decode ", decoded);
      this.onLoginSocial(decoded);
    };
    this.loadScript();
    this.verifyIsMobile();
   /*  this.getToken(); // Llamar inmediatamente cuando se cargue el componente
    this.intervalId = setInterval(() => {
      this.getToken(); // Llamar cada dos horas
    }, 7200000); // 2 horas en milisegundos */
  }

  loadScript() {
    window.onload = function () {
      google.accounts.id.initialize({
        client_id: "125481888337-om22f8vhecbe1k9qbtf404tutcejvhsd.apps.googleusercontent.com",
        callback: handleCredentialResponse
      });
      //google.accounts.id.prompt();
      //google.accounts.id.renderButton(
      //   document.getElementById("buttonDiv"),
      //   { theme: 'outline', size: 'large' }
      //);
    }
  }


  getIP()  
  {  
    this.nequi.getIpAddress().subscribe((res:any)=>{  
      this.ipAddress=res.ip;  
    });  
  }  

 
  onLoggin(): void {
    /* this.nequi.generar(this.ipAddress).then((resp: string) => {
      console.log('Token recibido:', resp);
    }).catch(error => {
      console.error('Error:', error);
    }); */
    if (this.email !== '' && this.pass !== '') {
      let consulta: any =
      {
        ip: null,
        cuenta: this.email,
        clave: sha512(this.pass),
        semilla: null
      }

      let peticion = new RequestProcess()
      peticion.proceso = '16'
      peticion.consulta = JSON.stringify(consulta)
      //console.log(peticion.consulta)
      this.accessService.execProcess(peticion)
        .subscribe(res => {
          if (res.mensaje === 'OK' && res.dataresponse) {
            let data = JSON.parse(res.dataresponse)
            // console.log(data)
            sessionStorage.setItem('User', JSON.stringify({ data }));
            this.userName = data.nombre;
            
            if (data.idestadousuario === 1) {
              this.onLoginRedirect();
            } else
              this.router.navigate(['changepassword'])

          } else if (res.mensaje !== 'Consulta sin resultados') {
            this.message = res.mensaje;
            this.ngxSmartModalService.getModal('myModal').open();
            this.pass = "";
          }
        })
    }
    else {
      this.message = 'complete todos los campos';
      this.ngxSmartModalService.getModal('myModal').open();
    }
  }

  passwordRecovery(event: any) {
    if(this.email!=''){
    event.preventDefault();
    let consulta: any =
    {
      ip: '192.168.0.10',
      cuenta: this.email
    }
    let peticion = new RequestProcess()
    peticion.proceso = '10'
    peticion.consulta = JSON.stringify(consulta)
    this.accessService.execProcess(peticion)
      .subscribe(res => {
        if (res.mensaje === 'OK') {
          let data = JSON.parse(res.dataresponse);
          const mail: any = {
            user: data.nombre,
            documento: this.email,
            email: this.email,
            clave: data.clave,
            url: 'http://legamio.co:8080/#/login'
          };
          let peticion = new RequestProcess()
          peticion.proceso = '1'
          peticion.consulta = JSON.stringify(mail)
          this.accessService.sendEmail(mail, 'recoveryPasswordLegamio')
            .subscribe(res => {
              if (res.success) {
                // PARTE DEL MODAL
                this.message = 'Se ha enviado un enlace al correo registrado para continuar con el proceso de recuperación de contraseña. Por favor, revisa tu bandeja de entrada o la carpeta de SPAM.'
                this.ngxSmartModalService.getModal('myModal').open();
              } else {
                this.message = 'Error durante el proceso de recuperación de clave, por favor intente de nuevo más tarde'
                this.ngxSmartModalService.getModal('myModal').open();
              }
            },
              error => console.log(error)
            )
          //
        } else if (res.mensaje === 'Consulta sin resultados') {
          this.informacion = 'El usuario no existe';
          this.message = 'El usuario no existe';
          this.ngxSmartModalService.getModal('myModal').open();
        } else {
          this.message = res.mensaje
          this.ngxSmartModalService.getModal('myModal').open();
        }
      }
      )
    }else{
      this.message = 'Error , Por favor ingresa tu correo electrónico';
      this.ngxSmartModalService.getModal('myModal').open();
    }
  }

  //----LOGGIN CON FACEBOOK
  onLogginFacebook() {
    // login with options
    const options: LoginOptions = {
      scope: 'public_profile, email',
      return_scopes: true,
      enable_profile_selector: true
    };

    this.fb.login(options)
      .then((response: LoginResponse) => {
        if(response.authResponse) {
          this.fb.api('/me', "post", {fields: 'name, email'})
          .then( (response) => {
            const completeName = response.name.split(" ");
            const given_name = completeName.length > 2 ? completeName[0] + " " + completeName[1] : completeName[0];
            const family_name = completeName.length > 3 ? completeName[2] + " " + completeName[3] : completeName[1];
            this.onLoginSocial({
              email: response.email,
              sub: response.id,
              given_name: given_name,
              family_name: family_name
            })
          });  
        }
        
      })
      .catch((error: any) => console.error(error));

     
  }

  LogOut() {
    this.fb.logout()
  }

  //----LOGIN CON GOOGLE
  onLoginSocial(dataUser: any) {

    if (dataUser.email !== '' && dataUser.sub !== '') {
      let consulta: any =
      {
        ip: null,
        cuenta: dataUser.email,
        clave: null,
        semilla: dataUser.sub
      }

      let peticion = new RequestProcess()
      peticion.proceso = '16'
      peticion.consulta = JSON.stringify(consulta)
      // console.log(peticion.consulta)
      this.accessService.execProcess(peticion)
        .subscribe(res => {
          console.log(res)
          // res.mensaje = "Consulta sin resultados";

          if (res.mensaje === 'OK' && res.dataresponse) {
            let data = JSON.parse(res.dataresponse)
            // console.log(data)
            sessionStorage.setItem('User', JSON.stringify({ data }));
            this.userName = data.nombre;
            
            if (data.idestadousuario === 1) {
              this.onLoginRedirect();
            } else {
              this.zone.run(() => {
                this.router.navigate(['home']);
              });
            }

          }
          else if (res.mensaje == 'Consulta sin resultados') {
            let consulta: Register =
            {
              idusuario: 10001,
              ip: null,
              id: 0,
              nombre: dataUser.given_name,
              apellido: dataUser.family_name,
              documento: "",
              correo: dataUser.email,
              telefono: 0,
              direccion: "",
              estado: 1,
              idestadousuario: 2,
              semilla: dataUser.sub,
            }

            // let peticion = new RequestProcess()
            // peticion.proceso = '15'
            // peticion.consulta = JSON.stringify(consulta)
            // this.accessService.execProcess(peticion)
            //   .subscribe(res => {
            //     //console.log(res, res.dataresponse)
            //     if (res.mensaje === 'OK' && res.dataresponse) {

            //       let data = JSON.parse(res.dataresponse)
            //       // console.log(data)
            //       sessionStorage.setItem('User', JSON.stringify({ data }));
            //       this.router.navigate(['home'])
            //       this.authService.isAuth()


            //     } else if (res.mensaje !== 'Consulta sin resultados') {
            //       this.message = res.mensaje
            //       this.ngxSmartModalService.getModal('myModal').open();
            //     }
            //   })
          }
          else if (res.mensaje !== 'Consulta sin resultados') {
            this.message = res.mensaje;
            this.ngxSmartModalService.getModal('myModal').open();
            this.pass = "";
          }
        })
    }


  }

  signOutGoogle(): void {
    //this.authServiceGoogle.signOut();
  }

  // callLoginButton() {

  //   this.auth2.attachClickHandler(this.loginElement.nativeElement, {},
  //     (googleAuthUser) => {

  //       let profile = googleAuthUser.getBasicProfile();
  //       console.log('Token || ' + googleAuthUser.getAuthResponse().id_token);
  //       console.log('ID: ' + profile.getId());
  //       console.log('Name: ' + profile.getName());
  //       console.log('Image URL: ' + profile.getImageUrl());
  //       console.log('Email: ' + profile.getEmail());

  //      /* Write Your Code Here */

  //     }, (error) => {
  //       console.log(error)
  //       //alert(JSON.stringify(error, undefined, 2));
  //     });

  // }

  // googleAuthSDK() {

  //   window['googleSDKLoaded'] = () => {
  //     window['gapi'].load('auth2', () => {
  //       // this.auth2 = window['gapi'].auth2.getAuthInstance({
  //       this.auth2 = window['gapi'].auth2.init({  
  //         client_id: '125481888337-om22f8vhecbe1k9qbtf404tutcejvhsd.apps.googleusercontent.com',
  //         cookiepolicy: 'single_host_origin',
  //         scope: 'profile email'
  //       });
  //       this.callLoginButton();
  //     });
  //   }

  //   (function(d, s, id){
  //     var js, fjs = d.getElementsByTagName(s)[0];
  //     if (d.getElementById(id)) {return;}
  //     js = d.createElement(s); js.id = id;
  //     js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
  //     fjs.parentNode.insertBefore(js, fjs);
  //   }(document, 'script', 'google-jssdk'));

  // }

  onLoginRedirect(): void {
    this.router.navigate([this.ruta]);
  }

  public submit() {
  }

  showPassword() {
    let pass: any = document.getElementById("password");

    if (pass.type == "text") {
      pass.type = "password";
      this.eye = false;
    } else {
      pass.type = "text";
      this.eye = true;
    }

  }

  verifyIsMobile() {
    // const userAgent = window.navigator.userAgent.toLowerCase();
    // this.ismobile = userAgent.includes('mobile');
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768)
      this.ismobile = true
  }

 /*  getToken(): void {
    this.tokenSubscription = this.nequi.getToken().subscribe(
      (response: any) => {
        this.token = response.access_token;
        console.log('Token:', this.token);
        this.nequi.setToken(this.token);
      },
      (error) => {
        console.error('Error fetching token:', error);
      }
    );
  }
  
  ngOnDestroy(): void {
    if (this.tokenSubscription) {
      this.tokenSubscription.unsubscribe(); // Cancelar la suscripción al destruir el componente
    }
    if (this.intervalId) {
      clearInterval(this.intervalId); // Limpiar el intervalo al destruir el componente
    }
  } */
}
