import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { Consult } from '../../objects/consult';
import { RequestProcess } from '../../objects/request';
import { ProcesswebService } from '../../services/processweb.service';
import { getPluralCategory } from '@angular/common/src/i18n/localization';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { appendFile } from 'fs';
import { Router } from '@angular/router';
import { Constants } from '../../objects/constans';

@Component({
  selector: 'app-categories',
  templateUrl: './capitulo.component.html',
  //styleUrls: ['./capitulo.component.css']
})
export class CapituloComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private _route: ActivatedRoute,
    private accessService: ProcesswebService,
    public ngxSmartModalService: NgxSmartModalService,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private router: Router ) { }

  public isLogged = false;
  public _dataCategoria: any;
  categorieImage: any;
  nombreCapitulo = '';
  listaCategorias: any;
  listaGrupos: any;
  logoImage: any;
  logoUrl: any = 'assets/img/logolegamio.png';
  message = '';
  idcapitulo: number = 0;
  dataCap: any;

  /*to modal content */
  _dataContent: any;
  productText: any;
  productImage: any;
  productVideo: any;
  productAudio: any;
  areVideo = false;
  areSound = false;
  areImage = false;
  areText = false;
  viewVideo = false;
  viewSound = false;
  viewImage = false;
  viewText = false;
  audio: any = document.getElementById('mp3Source');

  openHelp = false;

  /* to ranking products */
  listaProductos: any;
  public idcat: any;
  isranking: boolean;
  rankin1: boolean;
  rankin2: boolean;
  rankin3: boolean;

  constantes = new Constants();

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getCurrentUser();
    this.nombreCapitulo = this._route.snapshot.paramMap.get('capitulo');
    sessionStorage.setItem('Chapter', this.nombreCapitulo);

    this._dataCategoria = [{ contenido: '' }];
    this.dataCapitulos();

  }


  getCurrentUser() {
    const userLog = this.authService.isAuth();
    if (userLog) {
      this.isLogged = true;
    } else {
      this.isLogged = false;
    }
  }

  dataCapitulos(){
    try{
        const consulta: Consult ={
          schema: 'producto',
          tabla: 'capitulos',
          campo: '*',
          condicion: 'nombre =  \'\'' + this.nombreCapitulo + '\'\''
        }
      const peticion = new RequestProcess();
      peticion.proceso ='1';
      peticion.consulta = JSON.stringify(consulta);
      this.accessService.execProcess(peticion)
        .subscribe((res:any) => {
          if(res.codigo === '00'){
            const dataCapitulo = JSON.parse(res.dataresponse);
            this.dataCap = dataCapitulo;
            this.idcapitulo = this.dataCap[0].idcapitulo
            this.listarCategorias();
            // tslint:disable-next-line:prefer-for-of
          }else {
            if(res.mensaje == "Consulta sin resultados") {
              this.router.navigate(['/404']);
            }
          }
        })

    } catch(error){
      console.log(error);
    }
  }

  listarCategorias() {
   try {
      this.listaCategorias = new Array<any>();
      const consulta: Consult = {
        schema: 'producto',
        tabla: 'categorias',
        campo: 'distinct nombre',
        condicion: 'idestadocategoria = 1  and idcapitulo =  ' + this.idcapitulo
      };
      const peticion = new RequestProcess();
      peticion.proceso = '1';
      peticion.consulta = JSON.stringify(consulta);
      this.accessService.execProcess(peticion)
      .subscribe((res: any) => {
          if (res.codigo === '00') {
            const listicatego = JSON.parse(res.dataresponse);
            this.listaCategorias = listicatego;
            // tslint:disable-next-line:prefer-for-of
          } else {
          }
        });
      } catch (error) {
        console.log(error);
    }
  }

  showHelp(){
    this.openHelp = !this.openHelp;
  }

  navigateToProduto(producto){
    this.router.navigate(['/producto/'+producto]);
  }
}
