import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentesModule } from './componentes/componentes.module';
import { LoginModule } from './views/users/login/login.module';
import { RegisterModule } from './views/users/register/register.module';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule, MatButtonModule, MatFormFieldModule,  MatMenuModule, MatIconModule, MatSelectModule, MatProgressSpinnerModule } from '@angular/material';
// import { MatInputModule, MatButtonModule } from '@angular/material';
import { HeaderModule } from './views/header/header.module';
import { HomeModule } from './views/home/home.module';
import { FolderModule } from './views/folder/folder.module';
import { PerfilModule } from './views/users/perfil/perfil.module';
import { CapituloModule } from './views/capitulos/capitulo.module';
import { CategoriesModule } from './views/categories/categories.module';
/* import { DerechoEmpresaModule } from './views/derecho-empresa/derecho-empresa.module'; */
import { FooterModule } from './views/footer/footer.module';
import { GruposModule } from './views/grupos/grupos.module';
import { ProductModule } from './views/product/product.module';
import { HttpClientModule } from '@angular/common/http';
import { ProcesswebService } from './services/processweb.service';
import { NgxSmartModalModule,  NgxSmartModalService } from 'ngx-smart-modal';
import { NgFallimgModule } from 'ng-fallimg';
import { RouterModule } from '@angular/router';
import { HomeTempModule } from './views/home-temp/home-temp.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { PayModule } from './views/pay/pay.module';

/* ng-starrting para el rankingx */
import { RatingModule } from 'ng-starrating';
import { SharedModule } from './views/shared/shared.module';
/* font awesome icons */
/* import { FontAwesomeModule } from './@fortawesome/angular-fontawesome'; */
/* import { FontAwesomeModule } from '@fortawesome/fontawesome-free'; */


//import { SequenceTreeModule } from './views/sequencetree/sequencetree.module';
/* import { OneModalComponent } from './views/modals/one-modal/one-modal.component'; */
// import { MaterialModule } from './material/MaterialModule';
// ngx facebook
import { FacebookModule } from 'ngx-facebook';

import { StickyBelowViewDirective } from './directives/sticky-below-view.directive';
import { NgxPaginationModule } from 'ngx-pagination';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import { NoproductModule } from './views/noproduct/noproduct.module';
import { Page404Module } from './views/404/page404.module';

/* let config = new AuthServiceConfig([
  { */
    /* id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider("625161182114-gu032ndtnrks2bpcbs0stn6j841r09kn.apps.googleusercontent.com") */

/*     id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider("Google-OAuth-Client-Id")
  }
  ]);
 */

@NgModule({
  declarations: [
    AppComponent,
    StickyBelowViewDirective
    /* OneModalComponent */
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ComponentesModule,
    LoginModule,
    RegisterModule,
    HeaderModule,
    HomeModule,
    FolderModule,
    PerfilModule,
    CapituloModule,
    CategoriesModule,
    /* DerechoEmpresaModule, */
    FooterModule,
    GruposModule,
    ProductModule,
    NoopAnimationsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatFormFieldModule,
    SharedModule,
    PayModule,
    /* QuestionProductModule, */
/*     AngularFireModule, */
    /* AngularFireDatabaseModule, */
    HttpClientModule,
    FacebookModule.forRoot(),
    NgxSmartModalModule.forRoot(),
    NgFallimgModule.forRoot({
      default: 'assets/img/logolegamio.png',
    }),

    HomeTempModule,
    MatMenuModule,
    MatIconModule,
    FormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    RatingModule,
    //SequenceTreeModule
 /*    FontAwesomeModule */
    RouterModule,
    NgxPaginationModule,
    MatTableModule,
    MatPaginatorModule,
    NoproductModule,
    Page404Module,
    MatIconModule,
    
  ],
  exports: [
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    AppRoutingModule,
    
    MatSelectModule,
  ],
  providers: [
    /* AngularFireAuth, */
    ProcesswebService,
    NgxSmartModalService,
  ],
   bootstrap: [AppComponent]
})
export class AppModule { }
