import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, Inject, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { TreeService } from 'src/app/services/tree.service';
import { RequestProcess } from 'src/app/objects/request';
import { Consult } from 'src/app/objects/consult';
import { ProcesswebService } from 'src/app/services/processweb.service';
import {MatPaginator, MatTableDataSource} from '@angular/material';

export interface TipLegal {
  tip: string;
  solucion: string;
}

let ELEMENT_DATA: TipLegal[] = [];


@Component({
  selector: 'app-tips-legales',
  templateUrl: './tips-legales.component.html',
  styleUrls: ['../perfil.component.css']
})

export class TipsLegalesComponent implements OnInit {

  displayedColumns: string[] = ['tip_solucion'];
  tipsLegales: any[] = [];
  dataSource = new MatTableDataSource<TipLegal>(this.tipsLegales);

  @ViewChild(MatPaginator) paginator: MatPaginator;

  

  constructor(
    private route: ActivatedRoute,
    private treeService: TreeService,
    private accessService: ProcesswebService,
    public ngxSmartModalService: NgxSmartModalService,
    private sanitizer: DomSanitizer,
    private http: HttpClient, ) { }

  ngOnInit() {
    this.paginator._intl.itemsPerPageLabel = 'Tips por página';
    this.consultQuestions();
    this.dataSource.paginator = this.paginator;
  }

  consultQuestions() {
    try {
      this.tipsLegales = new Array<any>();
      const consulta: Consult = {
        schema: 'admin',
        tabla: 'legatips',
        campo: '*',
        //condicion: 'faq_estado = 2'
      };
      const peticion = new RequestProcess();
      peticion.proceso = '1';
      peticion.consulta = JSON.stringify(consulta);
      this.accessService.execProcess(peticion)
        .subscribe((res: any) => {
          if (res.codigo === '00') {
            const listproduct = JSON.parse(res.dataresponse);
            this.tipsLegales = listproduct;
            this.dataSource = new MatTableDataSource<TipLegal>(this.tipsLegales);
            this.dataSource.paginator = this.paginator;
          } else {
            console.log(res);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  filterChapter(filtro:  string){

  }

  searchQuestion(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
